import React, { createContext, useState } from "react";

// Create CartContext
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const addToCart = (item, selectedSize) => {
    const existingItemIndex = cartItems.findIndex(
      (cartItem) =>
        cartItem.id === item.id && cartItem.selectedSize === selectedSize
    );
  
    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += 1;
      updatedCartItems[existingItemIndex].totalPrice =
        updatedCartItems[existingItemIndex].myPrice *
        updatedCartItems[existingItemIndex].quantity;
  
      // Update the state with the modified cart items
      setCartItems(updatedCartItems);
    } else {
      // Check if the item was previously added to the cart with a quantity of 1
      const existingSingleItemIndex = cartItems.findIndex(
        (cartItem) => cartItem.id === item.id
      );
  
      if (existingSingleItemIndex !== -1) {
        const updatedCartItems = [...cartItems];
        updatedCartItems[existingSingleItemIndex].quantity += 1;
        updatedCartItems[existingSingleItemIndex].totalPrice =
          updatedCartItems[existingSingleItemIndex].myPrice *
          updatedCartItems[existingSingleItemIndex].quantity;
  
        // Update the state with the modified cart items
        setCartItems(updatedCartItems);
      } else {
        // If the item doesn't exist in the cart, add it as a new item with quantity 1
        setCartItems((prevItems) => [
          ...prevItems,
          { ...item, quantity: 1, totalPrice: item.myPrice, selectedSize },
        ]);
      }
    }
  };
  
  const removeFromCart = (productToRemove) => {
    const existingItemIndex = cartItems.findIndex(
      (item) =>
        item.id === productToRemove.id &&
        item.selectedSize === productToRemove.selectedSize
    );

    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity -= 1;
      updatedCartItems[existingItemIndex].totalPrice =
        updatedCartItems[existingItemIndex].price *
        updatedCartItems[existingItemIndex].quantity;

      // Remove the product from the cart if the quantity becomes 0
      if (updatedCartItems[existingItemIndex].quantity === 0) {
        updatedCartItems.splice(existingItemIndex, 1);
      }

      setCartItems(updatedCartItems);
    }
  };

  // Clear the entire cart
  const clearCart = () => {
    setCartItems([]);
  };

  // The value to be shared with other components via CartContext.Provider
  const cartContextValue = {
    cartItems,
    addToCart,
    removeFromCart,
    clearCart,
    selectedSize,
    setSelectedSize,
  };

  return (
    <CartContext.Provider value={cartContextValue}>
      {children}
    </CartContext.Provider>
  );
};
