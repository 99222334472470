import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactRatingStarsComponent from "react-rating-stars-component";
import twitter from "../../assets/social/twitter.png";
import mastodon from "../../assets/social/mastodon.png";
import reddit from "../../assets/social/reddit.png";
import trash from "../../assets/social/trash.png";
import replay from "../../assets/social/replay.png";
import cal from "../../assets/social/cal.png";
import download from "../../assets/social/download.png";
import link from "../../assets/social/link.png";
import star from "../../assets/social/star.png";
import user from "../../assets/social/user.png";
import come from "../../assets/social/comments.png";
import distro from "../../assets/social/distro.png";
import desktop from "../../assets/social/desktop.png";
import forward from "../../assets/social/forward.png";

import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { format } from "date-fns";

import fb from "../../components/script/firebase";
const db = fb.firestore();
const Review = db.collection("reviews");
const Comments = db.collection("comments");

const ReviewView = () => {
  const { id } = useParams();

  const [review, setReview] = useState({});
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [commentName, setCommentName] = useState("");
  const [commentEmail, setCommentEmail] = useState("");
  const [commentImg, setCommentImg] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [replyCommentId, setReplyCommentId] = useState("");
  const [replyCommentName, setReplyCommentName] = useState("");
  const [replyCommentEmail, setReplyCommentEmail] = useState("");
  const [replyCommentText, setReplyCommentText] = useState("");
  const [replyCommentImg, setReplayCommentImg] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [relatedReviws, setRelatedReviws] = useState([]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = fb.auth().currentUser;
      if (user) {
        const userDoc = await db.collection("users").doc(user.uid).get();
        const userData = userDoc.data();
        if (userData && userData.role === "Admin") {
          user.isAdmin = true;
        }
        user.authorName = userData && userData.authorName;
        user.FileUpload = userData && userData.FileUpload;
      }
      setCurrentUser(user);
    };

    fetchCurrentUser();
    // ...
  }, [id]);

  useEffect(() => {
    Review.doc(id)
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        setReview(data);
        document.title = data.Title;
        if (data.DesktopCategory && data.DesktopCategory.length > 0) {
          getRelatedReviws(data.DesktopCategory);
        } else {
          console.log("No categories found for this blog.");
        }
      });

    getCommentsForPost(id);
  }, [id]);
  const getRelatedReviws = (categories) => {
    Review.where("DesktopCategory", "array-contains-any", categories)
      .limit(3)
      .get()
      .then((querySnapshot) => {
        const relatedReviw = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return { id: doc.id, ...data };
        });
        setRelatedReviws(relatedReviw);
      })
      .catch((error) => {
        console.error("Error getting related reviews: ", error);
      });
  };
  const getCommentsForPost = (reviewId) => {
    Comments.where("postId", "==", reviewId)
      .get()
      .then((querySnapshot) => {
        const comments = querySnapshot.docs.map(async (doc) => {
          const commentData = doc.data();
          const authorUid = commentData.authorUid;
          const authorSnapshot = await db
            .collection("users")
            .doc(authorUid)
            .get();
          const authorData = authorSnapshot.data();
          const commentWithAuthor = {
            id: doc.id,
            ...commentData,
            authorProfileImageUrl: authorData ? authorData.profileImageUrl : "",
          };
          return commentWithAuthor;
        });
        Promise.all(comments).then((resolvedComments) => {
          const sortedComments = resolvedComments.sort((a, b) => {
            const dateA = a.createdAt ? a.createdAt.toDate() : new Date(0);
            const dateB = b.createdAt ? b.createdAt.toDate() : new Date(0);
            return dateB - dateA;
          });
          setComments(sortedComments);
        });
      })
      .catch((error) => {
        console.error("Error resolving comments: ", error);
      });
  };
  const handleShareMastodon = ({ title, blogId }) => {
    const shareText = encodeURIComponent(review.Title);
    const desText = encodeURIComponent(review.Description);
    const shareUrl = encodeURIComponent(window.location.href);

    const shareMastodonUrl = `https://mastodon.social/share?text=$${shareText}%0A${desText}&url=${shareUrl}`;
    window.open(shareMastodonUrl, "_blank");
  };
  const handleShareReddit = () => {
    const shareText = encodeURIComponent(review.Title);
    const desText = encodeURIComponent(review.Description);
    const shareUrl = encodeURIComponent(window.location.href);

    const shareRedditUrl = `https://www.reddit.com/r/share?text=$${shareText}%0A${desText}&url=${shareUrl}`;
    window.open(shareRedditUrl, "_blank");
  };
  const handleShareTwitter = () => {
    const shareText = encodeURIComponent(review.Title);
    const desText = encodeURIComponent(review.Description);
    const shareUrl = encodeURIComponent(window.location.href);
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareText}%0A${desText}&url=${shareUrl}`;
    window.open(twitterShareUrl, "_blank");
  };

  const handlePostComment = () => {
    if (isCaptchaVerified) {
      if (currentUser) {
        const commentData = {
          postId: id,
          comment: commentText,
          commentName: currentUser.authorName,
          commentEmail: currentUser.email,
          commentImg: currentUser.FileUpload || "",
          createdAt: fb.firestore.Timestamp.fromDate(new Date()),
          authorUid: currentUser.uid,
        };

        Comments.add(commentData)
          .then(() => {
            setCommentText("");
            getCommentsForPost(id);
          })
          .catch((error) => {
            console.error("Error posting comment: ", error);
          });
      } else {
        toast.info("Du måste logga in för att kunna kommentera.");
      }
    } else {
      toast.info("Vänligen verifiera dig själv med reCAPTCHA.");
    }
  };

  const handleCaptchaVerification = (response) => {
    setIsCaptchaVerified(true);
  };
  const handleDeleteComment = (commentId, authorUid) => {
    if (currentUser && (currentUser.uid === authorUid || currentUser.isAdmin)) {
      // Delete the main comment
      Comments.doc(commentId)
        .delete()
        .then(() => {
          // Delete the reply comments
          const replyCommentsQuery = Comments.where(
            "parentId",
            "==",
            commentId
          );
          replyCommentsQuery
            .get()
            .then((querySnapshot) => {
              const batch = db.batch();
              querySnapshot.forEach((doc) => {
                batch.delete(doc.ref);
              });
              return batch.commit();
            })
            .then(() => {
              // Delete the underlying comment
              const underlyingCommentQuery = Comments.where(
                "underlyingCommentId",
                "==",
                commentId
              );
              underlyingCommentQuery
                .get()
                .then((querySnapshot) => {
                  const batch = db.batch();
                  querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                  });
                  return batch.commit();
                })
                .then(() => {
                  // Refresh the comments for the post
                  getCommentsForPost(id);
                  toast.info("Kommentar är bortagen!");
                })
                .catch((error) => {
                  console.error("Error deleting underlying comment: ", error);
                });
            })
            .catch((error) => {
              console.error("Error deleting reply comments: ", error);
            });
        })
        .catch((error) => {
          console.error("Error deleting main comment: ", error);
        });
    } else {
      console.log("User is not authorized to delete this comment.");
    }
  };

  const handleReplyComment = (commentId, commentAuthorUid) => {
    setReplyCommentId(commentId);
    setReplyCommentName("");
    setReplyCommentEmail("");
    setReplyCommentText("");
    setReplayCommentImg("");
  };

  const handlePostReply = () => {
    if (isCaptchaVerified) {
      const parentComment = comments.find(
        (comment) => comment.id === replyCommentId
      );
      const parentCommentAuthorUid = parentComment
        ? parentComment.authorUid
        : "";

      const replyData = {
        postId: id,
        comment: replyCommentText,
        commentName: currentUser.authorName,
        commentEmail: currentUser.email,
        authorUid: currentUser.uid,
        commentImg: currentUser.FileUpload,
        createdAt: fb.firestore.Timestamp.fromDate(new Date()),
        parentCommentId: replyCommentId,
        parentCommentAuthorUid: parentCommentAuthorUid,
      };

      Comments.add(replyData)
        .then(() => {
          setReplyCommentId("");
          setReplyCommentName("");
          setReplyCommentEmail("");
          setReplyCommentText("");
          setReplayCommentImg("");
          getCommentsForPost(id);
        })
        .catch((error) => {
          console.error("Error posting reply: ", error);
        });
    } else {
      toast.info("Du måste verifiera dig med reCAPTCHA.");
    }
  };
  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };
  return (
    <>
      <Helmet>
        <title>{review.Title}</title>
        <meta name="description" content={review.Description} />
        <meta property="og:title" content={review.Title} />
        <meta property="og:description" content={review.Description} />
        <meta name="keywords" content={review.meta} />
        <meta property="og:keywords" content={review.meta} />
      </Helmet>
      <div className="box">
        <div>
          <Link to="/review" className="subtitle is-5">
            <img src={forward} alt="back" style={{ width: "30px" }} />
          </Link>
        </div>
        <div
          className="title"
          style={{ textAlign: "center", fontSize: "40px" }}
        >
          {review.Title}
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="img-front-full-products">
              <img
                src={review.File}
                alt="front"
                style={{
                  width: "80%",
                  borderRadius: "10px",
                  borderStyle: "solid",
                  border: "1px solid #ccc",

                  padding: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10%",
                }}
              />
            </div>
            <div>
              <div
                className=""
                style={{
                  marginTop: "10px",
                }}
              >
                <div className="subtitle is-5">
                  <div className="cat-category">
                    <div style={{ marginTop: "5px" }}>
                      <img src={distro} alt="distro" />
                    </div>
                    <div className="category">
                      {review.Category && Array.isArray(review.Category) ? (
                        review.Category.map((category, index) => (
                          <div
                            key={index}
                            className="label"
                            style={{
                              marginBottom: "10px",
                              border: "1px solid #ccc",
                              textAlign: "center",
                              borderRadius: "10px",
                              padding: "5px",
                            }}
                          >
                            {category}
                          </div>
                        ))
                      ) : (
                        <div className="tag is-alert is-normal">
                          Inga kategorier tillagda
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="subtitle is-6">
                  <div className="cat-category">
                    <div>
                      <img src={desktop} alt="desktop" />
                    </div>
                    <div className="category">
                      <div className="tag-container-front">
                        {review.DesktopCategory &&
                        Array.isArray(review.DesktopCategory) ? (
                          review.DesktopCategory.map(
                            (desktopCategory, index) => (
                              <div
                                key={index}
                                style={{
                                  marginRight: "5px",
                                  marginBottom: "5px",
                                  border: "1px solid #ccc",
                                  backgroundColor: "white",

                                  textAlign: "center",
                                  borderRadius: "10px",
                                  padding: "5px",
                                }}
                              >
                                {desktopCategory}
                              </div>
                            )
                          )
                        ) : (
                          <div className="tag is-alert is-medium">
                            Inga Skrivbord är tillagda
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="links-cat">
                  <div className="cat-link">
                    {review.Link && (
                      <div className="subtitle is-5">
                        <Link to={review.Link}>
                          <img
                            src={link}
                            alt="link"
                            style={{ width: "45px" }}
                          />
                        </Link>
                      </div>
                    )}

                    {review.Download && (
                      <div className="subtitle is-5">
                        <Link to={review.Download}>
                          <img
                            src={download}
                            alt="download"
                            style={{ width: "45px" }}
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <strong> Betyg</strong>
              </div>
              <div
                className="subtitle is-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {Array.from({ length: review.Rating }, (_, index) => (
                  <span key={index}>
                    <img
                      src={star}
                      alt="star"
                      style={{
                        width: "30px",
                      }}
                    />
                  </span>
                ))}
              </div>
              <div className="social" style={{ marginTop: "40px" }}>
                <div onClick={handleShareTwitter}>
                  <img src={twitter} alt="twitter" className="socialIcons" />
                </div>
                <div onClick={handleShareMastodon}>
                  <img src={mastodon} alt="mastodon" className="socialIcons" />
                </div>
                <div onClick={handleShareReddit}>
                  <img src={reddit} alt="reddit" className="socialIcons" />
                </div>
              </div>
            </div>
          </div>

          <div className="column is-half">
            <div
              className="subtitle is-5"
              style={{ marginLeft: "15px" }}
              dangerouslySetInnerHTML={{
                __html: review.Body,
              }}
            />
          </div>
        </div>
        <div className="body-post">
          <div className="post-top-all">
            <div className="subtitle is-5">
              <span class="icon-text">
                <span class="icon">
                  <img src={user} alt="user" width={25} />
                </span>
                <span>
                  {" "}
                  <strong>{review.authorName}</strong>
                </span>
              </span>
            </div>

            <div className="subtitle is-5">
              <span class="icon-text">
                <span class="icon">
                  <img src={cal} alt="cal" width={25} />
                </span>
                <span>
                  <strong>
                    {review.createdAt &&
                      format(review.createdAt.toDate(), "yyyy-MM-dd")}
                  </strong>
                </span>
              </span>
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="subtitle is-5">
          <Link to="/review" className="subtitle is-5">
            <img src={forward} alt="back" style={{ width: "30px" }} />
          </Link>
        </div>
        <div className="related-blogs">
          <h3 className="related-blogs-title">Relaterade recensioner</h3>
          {relatedReviws.map((relatedReviw) => (
            <div key={relatedReviw.id} className="related-blog">
              <Link
                to={`/reviews/show/${relatedReviw.id}`}
                className="related-blog-link"
              >
                <img
                  src={relatedReviw.File}
                  alt={relatedReviw.Title}
                  className="related-blog-image"
                />
                <div className="related-blog-info">
                  <div className="related-blog-title">{relatedReviw.Title}</div>
                  <div className="related-blog-category">
                    Skrivbord: {relatedReviw.DesktopCategory.join(", ")}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="box">
          <div
            className="headComment"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div>
              <img src={come} alt="comments" width={35} />
            </div>
            <div className="title">
              {comments.length === 1
                ? "1 kommentar"
                : `${comments.length} kommentarer`}
            </div>
          </div>
          {currentUser && (
            <div>
              {showForm && (
                <>
                  <div className="field">
                    <div>
                      {currentUser ? (
                        <input
                          className="input"
                          type="hidden"
                          placeholder="Ditt namn"
                          value={replyCommentName || currentUser.authorName}
                          onChange={(e) => setReplyCommentName(e.target.value)}
                          disabled
                        />
                      ) : (
                        <input
                          className="input"
                          type="hidden"
                          placeholder="Ditt namn"
                          value={replyCommentName}
                          onChange={(e) => setReplyCommentName(e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      {currentUser ? (
                        <input
                          className="input"
                          type="hidden"
                          placeholder="Epost"
                          value={replyCommentEmail || currentUser.email}
                          onChange={(e) => setReplyCommentName(e.target.value)}
                          disabled
                        />
                      ) : (
                        <input
                          className="input"
                          type="email"
                          placeholder="E-post"
                          value={replyCommentEmail}
                          onChange={(e) => setReplyCommentEmail(e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <div className="subtitle is-5">Kommentar</div>
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Kommentar"
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <ReCAPTCHA
                        sitekey="6Leb4ZgmAAAAADdOBp0UzHFsOENE77LboGfYkyG1"
                        onChange={handleCaptchaVerification}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button
                        className="button is-primary"
                        onClick={handlePostComment}
                      >
                        Posta Kommentar
                      </button>
                    </div>
                  </div>
                </>
              )}
              <div className="field">
                <div className="control">
                  <button className="button" onClick={toggleFormVisibility}>
                    {showForm ? "Dölj formulär" : "Kommentera"}
                  </button>
                </div>
              </div>
            </div>
          )}
          {!currentUser && (
            <div className="box" style={{ marginTop: "10px" }}>
              <div className="subtitle is-5" style={{ margin: "20px" }}>
                För att kommentera måste du vara inloggad. Klicka nedan för att
                logga in:
              </div>
              <div className="field">
                <div className="login-button">
                  <Link to="/login" className="button is-primary">
                    Logga in
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="columns">
          <div className="column is-full">
            {comments
              .filter((comment) => !comment.parentCommentId)
              .sort((a, b) => {
                const dateA = a.createdAt ? a.createdAt.toDate() : new Date(0);
                const dateB = b.createdAt ? b.createdAt.toDate() : new Date(0);
                return dateB - dateA;
              })
              .map((comment) => (
                <div
                  key={comment.id}
                  className="box"
                  style={{ backgroundColor: "#f4f4f4", marginBottom: "10px" }}
                >
                  <div className="comment-mail-and-name">
                    <div>
                      <img src={comment.commentImg} alt="user" />
                    </div>
                    <div className="subtitle is-5">
                      <a href={`mailto:${comment.commentEmail}`}>
                        {comment.commentName}
                      </a>
                    </div>
                  </div>
                  <div className="comment-text">
                    <div className="comment-comment">
                      <div
                        className="subtitle is-5"
                        style={{
                          backgroundColor: "white",

                          borderRadius: "10px",
                          border: "1px solid #ccc",
                          padding: "5px 20px 5px 20px",
                        }}
                      >
                        {comment.comment}
                      </div>
                    </div>
                  </div>
                  <div className="subtitle is-5">
                    <span class="icon-text">
                      <span class="icon">
                        <img src={cal} alt="cal" width={30} />
                      </span>
                      <span>
                        {comment.createdAt &&
                          format(comment.createdAt.toDate(), "yyyy-MM-dd")}
                      </span>
                    </span>
                  </div>
                  <hr style={{ backgroundColor: "#1684ac" }} />{" "}
                  <div className="replay">
                    {currentUser && currentUser.isAdmin && (
                      <Link
                        className="delete-comment-button"
                        onClick={() =>
                          handleDeleteComment(comment.id, comment.authorUid)
                        }
                      >
                        <img src={trash} alt="trash" width={30} />
                      </Link>
                    )}

                    {currentUser && (
                      <Link
                        className="reply-comment-button"
                        onClick={() =>
                          handleReplyComment(comment.id, comment.authorUid)
                        }
                      >
                        <img src={replay} alt="replay" width={30} />
                      </Link>
                    )}
                  </div>
                  {replyCommentId === comment.id && (
                    <div
                      className="reply-box"
                      style={{
                        backgroundColor: "#fff",

                        marginTop: "10px",
                        border: "1px solid #ccc",
                        padding: "5px 20px 5px 20px",
                      }}
                    >
                      <div className="field">
                        <div className="control">
                          {currentUser ? (
                            <input
                              className="input"
                              type="hidden"
                              placeholder="Ditt namn"
                              value={replyCommentName || currentUser.authorName}
                              onChange={(e) =>
                                setReplyCommentName(e.target.value)
                              }
                              disabled
                            />
                          ) : (
                            <input
                              className="input"
                              type="hidden"
                              placeholder="Ditt namn"
                              value={replyCommentName}
                              onChange={(e) =>
                                setReplyCommentName(e.target.value)
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          {currentUser ? (
                            <input
                              className="input"
                              type="hidden"
                              placeholder="Epost"
                              value={replyCommentEmail || currentUser.email}
                              onChange={(e) =>
                                setReplyCommentName(e.target.value)
                              }
                              disabled
                            />
                          ) : (
                            <input
                              className="input"
                              type="hidden"
                              placeholder="E-post"
                              value={replyCommentEmail}
                              onChange={(e) =>
                                setReplyCommentEmail(e.target.value)
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Kommentar</label>
                        <div className="control">
                          <textarea
                            className="textarea"
                            placeholder="Kommentar"
                            value={replyCommentText}
                            onChange={(e) =>
                              setReplyCommentText(e.target.value)
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div className="field">
                        <div className="g-recaptcha">
                          <ReCAPTCHA
                            sitekey="6Leb4ZgmAAAAADdOBp0UzHFsOENE77LboGfYkyG1"
                            onChange={handleCaptchaVerification}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <button
                            className="button is-primary"
                            onClick={handlePostReply}
                          >
                            Svara
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {comments
                    .filter((reply) => reply.parentCommentId === comment.id)
                    .map((reply) => (
                      <div
                        key={reply.id}
                        className="box"
                        style={{
                          backgroundColor: "#fff",
                          marginBottom: "10px",
                          marginLeft: "40px",
                        }}
                      >
                        <div className="comment-mail-and-name">
                          <div className="subtitle is-5">
                            <img src={reply.commentImg} alt="user" />
                          </div>
                          <div className="subtitle is-5">
                            <a href={`mailto:${reply.commentEmail}`}>
                              {reply.commentName}
                            </a>
                          </div>
                        </div>
                        <div className="comment-text">
                          <div className="comment-comment">
                            <div
                              className="subtitle is-5"
                              style={{
                                backgroundColor: "#f4f4f4",

                                borderRadius: "10px",
                                border: "1px solid #ccc",
                                padding: "5px 20px 5px 20px",
                              }}
                            >
                              {reply.comment}
                            </div>
                          </div>
                        </div>
                        <div className="subtitle is-5">
                          <span class="icon-text">
                            <span class="icon">
                              <img src={cal} alt="user" width={30} />
                            </span>
                            <span>
                              {reply.createdAt &&
                                format(reply.createdAt.toDate(), "yyyy-MM-dd")}
                            </span>
                          </span>
                        </div>
                        <hr style={{ backgroundColor: "#1684ac" }} />{" "}
                        <div className="replay">
                          {currentUser &&
                            (currentUser.isAdmin ||
                              currentUser.uid === reply.authorUid) && (
                              <Link
                                className="delete-comment-button"
                                onClick={() => handleDeleteComment(reply.id)}
                              >
                                <img src={trash} alt="trash" width={30} />
                              </Link>
                            )}
                        </div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewView;
