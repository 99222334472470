import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { AuthProvider, AuthContext } from "./components/script/AuthContext";
import CreateBlog from "./pages/blogs/create";
import { CartProvider } from "./pages/products/CartContext";
import CreateProducts from "./pages/products/create";
import IndexProducts from "./pages/products/index";
import BlogslistView from "./list";
import BlogView from "./pages/blogs/show";
import AllOrder from "./pages/products/allOrdes";
import CreateReviews from "./pages/reviews/create";
import Checkout from "./pages/products/checkout";
import ShowReviews from "./pages/reviews/show";
import ReviewEdit from "./pages/reviews/edit";
import OrderOverview from "./pages/products/OrderOverview";
import IndexReview from "./pages/reviews/";
import ProductView from "./pages/products/show";
import BlogEdit from "./pages/blogs/edit";
import ProductEdit from "./pages/products/edit";
import Login from "./pages/login";
import Home from "./pages/home";
import UserProfile from "./pages/userProfil";
import UserProfiles from "./pages/userProfil";
import Signup from "./pages/signup";
import CookiePolicy from "./pages/cookie/cookie-policy";
import Navbar from "./pages/header/navbar";
import Footer from "./pages/footer/footer";
import Profile from "./pages/profile";
import EditProfile from "./pages/userEdit";
import MissingPage from "./pages/missingpage/missingpage";
import Popup from "./pages/cookie/Cookie";
import AllUser from "./pages/users";
import AllProducts from "./pages/landing/allProducts";
import AllUsers from "./pages/landing/allUsers";
import fb, { auth } from "./components/script/firebase";
import Reviews from "./pages/landing/reviews";
import IndexBlogs from "./pages/blogs";
import Message from "./pages/messenge";
const db = fb.firestore();
const Users = db.collection("users");

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
        Users.doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const userData = doc.data();
              setUserRole(userData.role);
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        setIsLoggedIn(false);
        setUserRole(null);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <>

      <div className="container">

        <CartProvider>
          <AuthProvider>
            <AuthContext.Provider
              value={{
                isLoggedIn,
                userRole,
              }}
              // Flytta upp dessa attribut utanför value-objektet
            >
              <Message />
              <Navbar isLoggedIn={isLoggedIn} />
              <Routes>
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route path="/" element={<BlogslistView />} />
                <Route path="/order-overview" element={<OrderOverview />} />
                <Route path="/review" element={<IndexReview />} />
                <Route path="/products" element={<IndexProducts />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/article" element={<IndexBlogs />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/products/show/:id" element={<ProductView />} />
                <Route path="/show/:id" element={<BlogView />} />
                <Route path="/reviews/show/:id" element={<ShowReviews />} />

                {isLoggedIn && (
                  <>
                    {userRole === "Admin" && (
                      <>
                        <Route path="/dashboard" element={<Home />} />
                        <Route path="/reviews" element={<Reviews />} />
                        <Route path="/allproducts" element={<AllProducts />} />
                        <Route path="/profil" element={<Profile />} />

                        <Route path="/create" element={<CreateBlog />} />
                        <Route
                          path="/products/create"
                          element={<CreateProducts />}
                        />
                        <Route path="/orders" element={<AllOrder />} />
                        <Route
                          path="/products/edit/:id"
                          element={<ProductEdit />}
                        />
                        <Route
                          path="/reviews/create"
                          element={<CreateReviews />}
                        />

                        <Route path="/users" element={<AllUser />} />
                        <Route
                          path="/reviews/edit/:id"
                          element={<ReviewEdit />}
                        />
                        <Route path="/edit/:id" element={<BlogEdit />} />
                        <Route path="/edit-profil" element={<EditProfile />} />
                        <Route path="/user/:userId" element={<UserProfile />} />
                      </>
                    )}

                    {userRole === "Drone" && (
                      <>
                        <Route path="/profil" element={<Profile />} />
                        <Route path="/allUsers" element={<AllUsers />} />
                        <Route
                          path="/userProfiles"
                          element={<UserProfiles />}
                        />
                        <Route path="/edit-profil" element={<EditProfile />} />
                        <Route path="/user/:userId" element={<UserProfile />} />
                      </>
                    )}
                  </>
                )}

                <Route path="*" element={<MissingPage />} />
              </Routes>
              <Footer />
            </AuthContext.Provider>
          </AuthProvider>
        </CartProvider>

      </div>
      <Popup />

    </>
  );
}

export default App;
