import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, fb } from "../components/script/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

const Signup = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [error, setError] = useState(null);

  const role = "Drone"; // Set the role to "Drone"

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      // Check if the email or username already exists
      const emailExists = await checkEmailExists(email);
      const usernameExists = await checkUsernameExists(authorName);

      if (emailExists) {
        setError("E-postadressen används redan.");
        return;
      }

      if (usernameExists) {
        setError("Användarnamnet används redan.");
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Store additional user data in Firestore
      const userRef = doc(fb, "users", user.uid);
      await setDoc(userRef, {
        authorName: authorName,
        email: email,
        role: role, // Include the role in the user data
      });

      console.log(user);
      navigate("/edit-profil");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    }
  };

  // Function to check if the email exists
  const checkEmailExists = async (email) => {
    const querySnapshot = await fb
      .collection("users")
      .where("email", "==", email)
      .get();

    return !querySnapshot.empty;
  };

  // Function to check if the username exists
  const checkUsernameExists = async (username) => {
    const querySnapshot = await fb
      .collection("users")
      .where("authorName", "==", username)
      .get();

    return !querySnapshot.empty;
  };

  return (
    <div className="box">
      <div className="container has-text-centered mt-4">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="card">
              <div className="card-content">
                <div className="title">Bli Medlem</div>
                <form>
                  <div>
                    <div className="field">
                      <label className="label" htmlFor="användarnamn">
                        Användarnamn
                      </label>
                      <input
                        type="text"
                        className="input"
                        style={{ width: "300px" }}
                        id="author-Name"
                        value={authorName}
                        onChange={(e) => setAuthorName(e.target.value)}
                        required
                        placeholder="Användarnamn"
                      />
                      {error && <p className="help is-danger">{error}</p>}
                    </div>
                  </div>
                  <div>
                    <div className="field">
                      <label className="label" htmlFor="email-address">
                        E-Post
                      </label>
                      <input
                        type="email"
                        id="email-address"
                        style={{ width: "300px" }}
                        className="input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="E-post adress"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="field">
                      <label className="label" htmlFor="losenord">
                        Lösenord
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="input"
                        style={{ width: "300px" }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="button-center">
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="button is-primary"
                    >
                      Bli medlem
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
