import React from "react";
import "../css/share.css";

import twitter from "../../assets/social/twitter.png";
import mastodon from "../../assets/social/mastodon.png";
import reddit from "../../assets/social/reddit.png";

const ShareButtons = ({ title, blogId }) => {
  const encodedUrl = encodeURIComponent(`https://devblog.se/show/${blogId}`);
  const encodedTitle = encodeURIComponent(title);

  const handleShareMastodon = () => {
    const shareUrl = `https://mastodon.social/share?text=${encodedTitle}&url=${encodedUrl}`;
    window.open(shareUrl, "_blank");
  };

  const handleShareTwitter = () => {
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`;
    window.open(shareUrl, "_blank");
  };
  const handleShareReddit = () => {
    const shareUrl = `https://www.reddit.com/r/share?url=${encodedUrl}&title=${encodedTitle}`;
    window.open(shareUrl, "_blank");
  };
  return (
    <div className="share-buttons">
      <div className="share-icon" onClick={handleShareTwitter}>
        <img src={twitter} alt="twitter" />
        <div className="share-tooltip">Dela på Twitter</div>
      </div>
      <div className="share-icon" onClick={handleShareMastodon}>
        <img src={mastodon} alt="mastodon" />
        <div className="share-tooltip">Dela på Mastodon</div>
      </div>
      <div className="share-icon" onClick={handleShareReddit}>
        <img src={reddit} alt="reddit" />
        <div className="share-tooltip">Dela på Reddit</div>
      </div>
    </div>
  );
};

export default ShareButtons;
