import React, { useState, useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../script/firebase";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../css/progressbar.css";
import watermarkImage from "../../assets/watermark.png";

const FileUploadProfile = ({ handleFileUpload }) => {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 1,
    x: 25,
    y: 25,
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null); // Lägg till en ny stat för förhandsgranskning av bilden
  const imageRef = useRef(null);

  const onFileUpload = () => {
    if (!file) return;
    setIsLoading(true);
    const storageRef = ref(storage, `/profile/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            handleFileUpload(downloadURL); // Pass the download URL to the parent component
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    );
  };

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreviewImageUrl(URL.createObjectURL(selectedFile)); // Uppdatera förhandsgranskning av bilden
    }
  };

  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        "cropped_image.png"
      );

      if (croppedImageUrl) {
        addWatermarkToImage(croppedImageUrl);
      }
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    // Implementera denna funktion för att beskära bilden
  };

  const addWatermarkToImage = async (imageUrl) => {
    const watermarkImg = new Image();
    watermarkImg.src = watermarkImage;

    watermarkImg.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const width = imageRef.current.width;
      const height = imageRef.current.height;

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(imageRef.current, 0, 0, width, height);

      ctx.drawImage(watermarkImg, 10, 10);

      canvas.toBlob(async (blob) => {
        if (!blob) {
          setErrorMessage(
            "Misslyckades med att lägga till vattenmärke på bilden"
          );
          return;
        }

        blob.name = "watermarked_image.png";
        handleFileUpload(URL.createObjectURL(blob));
        setErrorMessage(null);
      }, "image/png");
    };
  };

  const onDeleteImage = () => {
    if (file) {
      setFile(null);
      setCrop({
        unit: "%",
        width: 50,
        aspect: 1,
        x: 25,
        y: 25,
      });
      setErrorMessage(null);
      setPreviewImageUrl(null); // Återställ förhandsgranskning av bilden
    }
  };

  return (
    <>
      <input type="file" onChange={onFileChange} className="button" />
      {file && (
        <button onClick={onDeleteImage} className="button is-warning">
          Ångra bild
        </button>
      )}
      {file && (
        <>
          <div>
            <ReactCrop
              src={URL.createObjectURL(file)}
              crop={crop}
              onChange={setCrop}
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
            />
          </div>
        </>
      )}
      {previewImageUrl && (
        <div>
          <p>Förhandsgranskning:</p>
          <img src={previewImageUrl} alt="Förhandsgranskning" />
        </div>
      )}
      {errorMessage && <p>{errorMessage}</p>}
      <button
        onClick={onFileUpload}
        disabled={!file || errorMessage}
        className="button is-primary"
      >
        Ladda upp bilden
      </button>

      <div className="space"></div>

      {isLoading && (
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}>
            {progress}%
          </div>
        </div>
      )}
    </>
  );
};

export default FileUploadProfile;
