import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../components/upload/upload";
import ReactQuill from "react-quill";
import money from "../../assets/social/money.png";
import seo from "../../assets/social/seo.png";
import forward from "../../assets/social/forward.png";
import { Link } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import fb from "../../components/script/firebase";
import categoriesData from "../../components/script/categoryProduct.json";

const db = fb.firestore();
const Blogslist = db.collection("products");
const usersCollection = db.collection("users");

const ProductEdit = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [artNummer, setArtNummer] = useState("");
  const [myPrice, setMyPrice] = useState("");
  const [momsats, setMomsats] = useState(0.25);
  const [body, setBody] = useState("");
  const [category, setCategory] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedMomsats, setSelectedMomsats] = useState(0.25);
  const [authorName, setAuthorName] = useState("");
  const [meta, setMeta] = useState("");

  const [stock, setStock] = useState({});
  const [gadgetsStock, setGadgetsStock] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = fb.auth().currentUser;
    if (currentUser) {
      usersCollection
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setAuthorName(doc.data().authorName);
          }
        })
        .catch((error) => {
          console.error("Error fetching author name:", error);
        });
    }
  }, []);
  const handleSizeChange = (e) => {
    const selectedSize = e.target.value;
    if (selectedSizes.includes(selectedSize)) {
      setSelectedSizes(selectedSizes.filter((size) => size !== selectedSize));
    } else {
      setSelectedSizes([...selectedSizes, selectedSize]);
    }
  };
  const handleMetaChange = (e) => {
    setMeta(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handlePrisChange = (e) => {
    setMyPrice(e.target.value);
  };
  const handleArtNummerChange = (e) => {
    setArtNummer(e.target.value);
  };
  const handleCategoryChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setCategory(selectedOptions);
  };
  const handleSelectedCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };
  useEffect(() => {
    Blogslist.doc(id)
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        setTitle(data.Title);
        setMyPrice(data.myPrice);
        setArtNummer(data.ArtNummer);
        setBody(data.Body);
        setCategory(data.Type);
        setSelectedSizes(data.Sizes);
        setGadgetsStock(data.gadgetsStock);
        setStock(data.stock);
        setSelectedMomsats(data.selectedMomsats);
        setCategory(data.Category);
        setFileUrl(data.File);
        setDescription(data.Description);
        setMeta(data.meta);
      });
  }, [id]);
  const [sizeStocks, setSizeStocks] = useState([]);

  const submit = (e) => {
    e.preventDefault();
    const totalPriceWithMoms = (
      parseFloat(myPrice) *
      (1 + selectedMomsats)
    ).toFixed(2);
    const sizeStocksData = sizeStocks.map((item) => ({
      size: item.size,
      stock: parseInt(item.stock),
    }));
    const updatedData = {
      Title: title,
      myPrice: parseFloat(myPrice),
      PrisMedMoms: totalPriceWithMoms,
      Category: category,
      Sizes: selectedSizes,
      Stock: sizeStocksData, // Lagersaldo för olika storlekar
      GadgetsStock: selectedCategory === "gadgets" ? gadgetsStock : "",
      ArtNummer: artNummer,
      Momsats: selectedMomsats,
      Type: selectedCategory,
      ...(selectedCategory === "clothes" && { Sizes: selectedSizes }), // Inkludera Sizes bara om det är kläder
      Body: body,
      File: fileUrl,
      Description: description,
      meta: {
        value: meta,
      },
    };

    Blogslist.doc(id)
      .update(updatedData)
      .then(() => {
        toast.success("Produkten är nu sparad!");
        navigate("/allproducts");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleStockChange = (index, size, stock) => {
    const updatedSizeStocks = [...sizeStocks];
    updatedSizeStocks[index] = { size, stock };
    setSizeStocks(updatedSizeStocks);
  };
  const addSizeStock = () => {
    setSizeStocks([...sizeStocks, { size: "", stock: "" }]);
  };
  const handleFileUpload = (uploadedFileUrl) => {
    setFileUrl(uploadedFileUrl);
  };

  const handleEditorChange = (value) => {
    setBody(value);
  };

  const deleteImage = () => {
    setFileUrl("");
  };
  const removeSizeStock = (index) => {
    const updatedSizeStocks = [...sizeStocks];
    updatedSizeStocks.splice(index, 1);
    setSizeStocks(updatedSizeStocks);
  };
  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half">
          <Link to="/allproducts" className="subtitle is-5">
            <img src={forward} alt="back" style={{ width: "30px" }} />
          </Link>
          <label className="label">Ladda upp en bild</label>
          <p />

          {fileUrl && (
            <div>
              <img
                src={fileUrl}
                alt="Current"
                style={{ marginBottom: "10px" }}
              />
              <button
                className="button is-danger is-small"
                onClick={deleteImage}
              >
                Ta bort bild
              </button>
            </div>
          )}
          <FileUpload handleFileUpload={handleFileUpload} />
        </div>
        <div className="column is-half">
          <label className="label">Välj Typ av Produkt</label>
          <select
            value={selectedCategory}
            onChange={handleSelectedCategoryChange}
            className="input"
          >
            <option value="">Välj en kategori</option>
            <option value="clothes">Kläder</option>
            <option value="gadgets">Prylar</option>
            <option value="webdesign">Webdesign</option>
          </select>
          {selectedCategory === "clothes" && (
            <div className="container-post">
              <label className="label">Storlekar</label>
              <div>
                {sizeStocks.map((item, index) => (
                  <div key={index}>
                    <label className="label">Storlek</label>
                    <input
                      type="text"
                      className="input"
                      placeholder="Storlek"
                      value={item.size}
                      onChange={(e) =>
                        handleStockChange(index, e.target.value, item.stock)
                      }
                    />
                    <label className="label">Lagersaldo</label>
                    <input
                      type="number"
                      className="input"
                      placeholder={`Lagersaldo för storlek ${item.size}`}
                      value={item.stock}
                      onChange={(e) =>
                        handleStockChange(index, item.size, e.target.value)
                      }
                    />
                    {sizeStocks.length > 1 && (
                      <button onClick={() => removeSizeStock(index)}>
                        Ta bort storlek
                      </button>
                    )}
                  </div>
                ))}
                <button onClick={addSizeStock}>Lägg till storlek</button>
              </div>
            </div>
          )}
          {selectedCategory === "gadgets" && (
            <div className="field">
              <div className="control">
                <label htmlFor="gadgetsStockInput" className="label">
                  Lagersaldo för prylar
                </label>
                <input
                  type="number"
                  className="input"
                  id="gadgetsStockInput"
                  placeholder="Ange lagersaldo för prylar"
                  value={gadgetsStock}
                  onChange={(e) => setGadgetsStock(e.target.value)}
                />
              </div>
            </div>
          )}
          <label className="label">Produkt</label>
          <input
            type="text"
            value={title}
            className="input"
            placeholder="Title"
            style={{ width: "650px" }}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <label className="label">Artikelnummer</label>
          <input
            type="text"
            value={artNummer}
            className="input"
            placeholder="Artikelnummer"
            style={{ width: "650px" }}
            onChange={handleArtNummerChange}
            required
          />
          <label className="label">Inlägg</label>
          <ReactQuill
            value={body}
            onChange={handleEditorChange}
            placeholder="Skriv din text här"
          />
          <div className="container-post">
            <label className="label">Välj Kategorier</label>
            <div className="select is-multiple">
              <select multiple value={category} onChange={handleCategoryChange}>
                {categoriesData.category.map((cat) => (
                  <optgroup key={cat.name} label={cat.name}>
                    {cat.subcategories.map((subcat) => (
                      <option key={subcat} value={subcat}>
                        {subcat}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </div>
          </div>
          <div className="container-post">
            <label className="label ">
              <img src={money} alt="Money" />
            </label>
            <div>
              <label className="label"> Pris i kr(ex moms)</label>
              <input
                type="text"
                className="input"
                value={myPrice}
                placeholder="Pris"
                onChange={handlePrisChange}
                required
              />
            </div>

            <div>
              <label className="label">Momsats</label>
              <select
                className="input"
                placeholder="Momsats"
                value={selectedMomsats}
                onChange={(e) => setSelectedMomsats(parseFloat(e.target.value))}
                required
              >
                <option value={0.25}>25% moms</option>
                <option value={0.12}>12% moms</option>
                <option value={0.06}>6% moms</option>
              </select>
            </div>
          </div>
          <div className="container-post">
            <label className="label ">
              {" "}
              <img src={seo} alt="SEO" />
            </label>
            <div>
              <label className="label">Meta tags</label>
              <input
                type="text"
                className="input"
                value={meta.value}
                placeholder="Meta Value"
                onChange={handleMetaChange}
                required
              />
            </div>
            <div>
              <label className="label">Kort beskrivning</label>
              <input
                type="text"
                className="input"
                value={description}
                placeholder=" Kort beskriving"
                onChange={handleDescriptionChange}
                required
              />
            </div>
          </div>
          <form onSubmit={submit}>
            <div style={{ marginTop: "10px", textAlign: "left" }}>
              <button className="button is-primary" type="submit">
                Lägg till produkt
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductEdit;
