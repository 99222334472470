import React, { useState, useEffect } from "react";
import fb from "./components/script/firebase";
import { Helmet } from "react-helmet";
import ShareButtons from "./components/script/ShareButton";
import user from "./assets/social/user.png";
import cal from "./assets/social/cal.png";
import searchIcon from "./assets/social/sok.png";
import close from "./assets/social/close.png";
import back from "./assets/social/back.png";
import Loader from "./components/Loader/loader";
import forward from "./assets/social/forward.png";
import firebase from "firebase/compat/app";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import RatingStars from "react-rating-stars-component";
import defaultImage from "./assets/noimage.png";

import { format } from "date-fns";

const db = fb.firestore();
const Blogs = db.collection("blogs");

const Comments = db.collection("comments");
const Users = db.collection("users");

const List = () => {
  const [blogslist, setBlogsList] = useState([]);

  const [totalComments, setTotalComments] = useState({});
  const [totalCommentsReview, setTotalCommentsReview] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [latestReviews, setLatestReviews] = useState([]);
  const [latestProducts, setLatestProducts] = useState([]);

  useEffect(() => {
    const fetchLatestReviews = async () => {
      try {
        const reviewsRef = fb.firestore().collection("reviews");

        const snapshot = await reviewsRef
          .orderBy("createdAt", "desc")
          .limit(6)
          .get();

        const reviewData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setLatestReviews(reviewData);
      } catch (error) {
        console.error("Error fetching latest reviews:", error);
      }
    };

    fetchLatestReviews();
  }, []);
  useEffect(() => {
    const fetchLatestProduct = async () => {
      try {
        const productsRef = fb.firestore().collection("products");

        const snapshot = await productsRef
          .orderBy("createdAt", "desc")
          .limit(6)
          .get();

        const productData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setLatestProducts(productData);
      } catch (error) {
        console.error("Error fetching latest reviews:", error);
      }
    };

    fetchLatestProduct();
  }, []);
  useEffect(() => {
    let query = Blogs;
    if (searchQuery) {
      query = query
        .orderBy("Title")
        .startAt(searchQuery)
        .endAt(searchQuery + "\uf8ff");
    } else {
      query = query.orderBy("createdAt", "desc");
    }
    const unsubscribe = query.onSnapshot((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      // Fetch user data for each blog post
      const fetchUserPromises = data.map((blog) =>
        getUserData(blog.createdBy).then((userData) => {
          blog.authorName = userData ? userData.authorName : "Unknown";
          return blog;
        })
      );

      Promise.all(fetchUserPromises)
        .then((blogsWithData) => {
          setBlogsList(blogsWithData);

          setIsLoading(false); // Set loading state to false
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
          setIsLoading(false); // Set loading state to false (in case of error)
        });
    });

    return unsubscribe;
  }, [searchQuery]);
  useEffect(() => {
    // Fetch blogs from Firebase Firestore
    const fetchBlogs = async () => {
      setIsLoading(true);

      try {
        const blogsRef = firebase.firestore().collection("blogs");
        const snapshot = await blogsRef.orderBy("createdAt", "desc").get();
        const blogData = snapshot.docs.map((doc) => doc.data());

        setBlogsList(blogData);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);
  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  const handleSearchClose = () => {
    setShowSearch(false);
    setSearchQuery("");
  };
  useEffect(() => {
    const fetchComments = async () => {
      const commentCounts = {};

      for (const blog of blogslist) {
        if (blog.id) {
          try {
            const querySnapshot = await Comments.where(
              "postId",
              "==",
              blog.id
            ).get();
            const count = querySnapshot.size;
            commentCounts[blog.id] = count;
          } catch (error) {
            console.error("Error getting comments: ", error);
          }
        }
      }

      setTotalComments(commentCounts);
    };

    fetchComments();
  }, [blogslist]);

  useEffect(() => {
    const fetchCommentsReview = async () => {
      const commentCountsReview = {};

      for (const review of latestReviews) {
        if (review.id) {
          try {
            const querySnapshot = await Comments.where(
              "postId",
              "==",
              review.id
            ).get();
            const count = querySnapshot.size;
            commentCountsReview[review.id] = count;
          } catch (error) {
            console.error("Error getting comments: ", error);
          }
        }
      }

      setTotalCommentsReview(commentCountsReview);
    };

    fetchCommentsReview();
  }, [latestReviews]);

  const getUserData = (userId) => {
    return Users.doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error("Error fetching user data: ", error);
        return null;
      });
  };

  const blogsPerPage = 10;
  const totalPages = Math.ceil(blogslist.length / blogsPerPage);
  const startIndex = (currentPage - 1) * blogsPerPage;
  const endIndex = startIndex + blogsPerPage;
  const paginatedblogs = blogslist.slice(startIndex, endIndex);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <div className="box">
      <Helmet>
        <title>DevBlog - En guide för utvecklaren </title>
        <meta
          name="description"
          content="Vi guide dig till att bli en bättre utvecklare"
        />
        <meta
          name="keywords"
          content="Front-end, back-end, React.js, TypScript, JavaScript, Firebase, Java, Mysql, Node.js"
        />

        <meta
          property="og:title"
          content="DevBlog - En guide för utvecklaren"
        />
      </Helmet>

      <style>
        {`
          .no-blogs {
          text-align: center;
          margin-top: 20px;
          font-size: 18px;
        
      `}
      </style>
      <div>
        <div>
          <div className="columns">
            <div className="column is-full">
              <div className="search-container">
                {showSearch ? (
                  <>
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="input is-small "
                      style={{ width: "690px" }}
                      placeholder="Sök efter artikel eller recension"
                    />
                    <img
                      src={close}
                      alt="Close"
                      className="close-icon"
                      onClick={handleSearchClose}
                      width={25}
                    />
                  </>
                ) : (
                  <img
                    onClick={handleSearchIconClick}
                    src={searchIcon}
                    alt="Search"
                    width={30}
                  />
                )}
              </div>
              <div className="title" style={{ fontSize: "30px" }}>
                Senaste Recensionerna
              </div>

              {latestReviews.length > 0 && (
                <div className="latest-reviews">
                  {latestReviews.map((review) => (
                    <div key={review.id}>
                      <div className="review-img">
                        <Link to={"/reviews/show/" + review.id}>
                          <img
                            src={review.File}
                            alt={review.Title}
                            width="250px"
                            style={{
                              borderStyle: "solid",
                              borderRadius: "10px",
                              border: "1px solid #ccc",
                              padding: "5px",
                            }}
                          />
                          <h2 className="image-title">{review.Title}</h2>
                        </Link>
                        <div className="rating-stars">
                          <RatingStars
                            count={5}
                            value={review.Rating} // Use the review's rating value
                            size={20}
                            edit={false}
                            color1="#f0f0f0"
                            color2="#ffd700"
                          />
                        </div>
                        <div className="counteToReview">
                          <div className="reviewCounter">
                            {totalCommentsReview[review.id]}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <Link
                  style={{
                    backgroundColor: "#f4f4f4",
                    color: "black",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    padding: "5px",
                  }}
                  to="/review"
                >
                  <strong>Läs fler recensioner</strong>
                </Link>
              </div>
              <div className="title" style={{ fontSize: "30px" }}>
                Nytt i butiken
              </div>

              {latestProducts.length > 0 && (
                <div className="latest-reviews">
                  {latestProducts.map((product) => (
                    <div key={product.id}>
                      <div className="rec-img">
                        <Link to={"/products/show/" + product.id}>
                          <img
                            src={product.File}
                            alt={product.Title}
                            width="250px"
                            style={{
                              borderStyle: "solid",
                              borderRadius: "10px",

                              border: "1px solid #ccc",
                              padding: "5px",
                            }}
                          />
                          <h2 className="image-title">{product.Title}</h2>
                        </Link>
                      </div>
                      <h2 className="subtitle is-5">
                        <div style={{ textAlign: "center", marginTop: "5px" }}>
                          {" "}
                          <strong>{product.PrisMedMoms} Kr</strong>
                        </div>
                      </h2>
                    </div>
                  ))}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <Link
                  style={{
                    backgroundColor: "#f4f4f4",
                    color: "black",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    padding: "5px 20px 5px 20px",
                  }}
                  to="/products"
                >
                  <strong> Kolla In fler Produkter</strong>
                </Link>
              </div>
            </div>
          </div>

          <div className="column is-half"></div>
        </div>
      </div>
      <div
        className="title"
        style={{
          fontSize: "30px",
        }}
      >
        Alla Artiklar
      </div>

      {isLoading ? ( // Display loading state
        <Loader />
      ) : paginatedblogs.length === 0 ? (
        <div className="no-blogs">Inga artiklar tillgängliga</div>
      ) : (
        paginatedblogs.map((blog) => (
          <div key={blog.id}>
            <div className="columns">
              <div className="column is-half">
                {blog.File ? (
                  <img
                    src={blog.File}
                    alt="front"
                    className="img-front"
                    style={{
                      border: "1px solid #ccc",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                ) : (
                  <img
                    src={defaultImage}
                    alt="default"
                    className="img-front"
                    style={{
                      border: "1px solid #ccc",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                )}
                <div className="social" style={{ marginTop: "40px" }}>
                  <ShareButtons
                    title={blog.Title}
                    description={blog.Description}
                    blogId={blog.id}
                    file={blog.File}
                  />

                  {totalComments[blog.id] > 0 && (
                    <div className="comment-count">
                      <div className="comment-number">
                        {totalComments[blog.id]}
                      </div>
                    </div>
                  )}
                </div>
                <div className="post-top">
                  <div className="subtitle is-5">
                    <span class="icon-text">
                      <span class="icon">
                        <img src={user} alt="user" width={30} />
                      </span>
                      <span>
                        <strong>{blog.authorName}</strong>
                      </span>
                    </span>
                  </div>

                  <div className="subtitle is-5">
                    <span class="icon-text">
                      <span class="icon">
                        <img src={cal} alt="cal" width={30} />
                      </span>
                      <span>
                        <strong>
                          {format(blog.createdAt.toDate(), "yyyy-MM-dd")}
                        </strong>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="">
                  <div>
                    <Link to={"/show/" + blog.id}>
                      {" "}
                      <div className="title">{blog.Title}</div>
                    </Link>
                    <div className="content-1">
                      <div
                        className="subtitle is-5"
                        dangerouslySetInnerHTML={{
                          __html: blog.Body.substring(
                            0,
                            blog.Body.substring(0, 800).lastIndexOf(".") + 1
                          ),
                        }}
                      />

                      <div className="subtitle is-6">
                        <div className="tag-container">
                          {blog.Category && Array.isArray(blog.Category) ? (
                            blog.Category.map((category, index) => (
                              <div
                                key={index}
                                style={{
                                  marginBottom: "10px",
                                  border: "1px solid #ccc",
                                  backgroundColor: "white",
                                  marginRight: "10px",
                                  textAlign: "center",
                                  borderRadius: "10px",
                                  padding: "5px",
                                }}
                              >
                                {category}
                              </div>
                            ))
                          ) : (
                            <div className="tag is-alert is-normal">
                              Inga kategorier tillagda
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}

      <div className="pagination">
        <button
          className="button is-white"
          onClick={previousPage}
          style={{ backgroundColor: "#f4f4f4" }}
          disabled={currentPage === 1}
        >
          <img src={forward} alt="next" style={{ width: "30px" }} />
        </button>
        {Array.from({ length: totalPages }, (_, index) => index + 1).map(
          (page) => (
            <button
              key={page}
              style={{
                backgroundColor: "#f4f4f4",
                color: "black",
                borderRadius: "10px",
                border: "1px solid #ccc",
                width: "25px",
                height: "25px",
              }}
              className={`button is-small ${
                page === currentPage ? "button is-info is-small" : ""
              }`}
              onClick={() => goToPage(page)}
              disabled={page === currentPage}
            >
              {page}
            </button>
          )
        )}
        <button
          className="button is-white"
          style={{ backgroundColor: "#f4f4f4" }}
          onClick={nextPage}
          disabled={currentPage === totalPages}
        >
          <img src={back} alt="next" style={{ width: "30px" }} />
        </button>
      </div>
    </div>
  );
};

export default List;
