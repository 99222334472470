import React, { useState, useEffect } from "react";
import { auth } from "../components/script/firebase";
import { toast } from "react-toastify";
import FileUploadProfile from "../components/upload/uploadProfil";
import { useNavigate } from "react-router-dom";
import { updatePassword } from "firebase/auth";

import fb from "../components/script/firebase";

const db = fb.firestore();
const Users = db.collection("users");

const EditProfile = () => {
  const [user, setUser] = useState(null);
  const [authorName, setAuthorName] = useState("");
  const [info, setInfo] = useState("");
  const [email, setEmail] = useState("");
  const [link, setLink] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [git, setGit] = useState("");
  const [gitTitle, setGitTitle] = useState("");
  const [lab, setLab] = useState("");
  const [labTitle, setLabTitle] = useState("");
  const [bucket, setBucket] = useState("");
  const [bucketTitle, setBucketTitle] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [userRole, setUserRole] = useState("");
  const [password, setPassword] = useState(""); // Added password state
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setFileUrl(user.photoURL);
        setInfo(user.info);
        setEmail(user.email);
        setLink(user.link);
        setLinkTitle(user.linkTitle);
        setGit(user.git);
        setGitTitle(user.gitTitle);
        setLab(user.lab);
        setLabTitle(user.labTitle);
        setBucket(user.bucket);
        setBucketTitle(user.bucketTitle);
        setUserRole(user.userRole);
        Users.doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setAuthorName(doc.data().authorName);
              setFileUrl(doc.data().FileUpload);
              setInfo(doc.data().info);
              setLink(doc.data().link);
              setLinkTitle(doc.data().linkTitle);
              setGit(doc.data().git);
              setGitTitle(doc.data().gitTitle);
              setLab(doc.data().lab);
              setLabTitle(doc.data().labTitle);
              setBucket(doc.data().bucket);
              setBucketTitle(doc.data().bucketTitle);
              setEmail(doc.data().email);
              setUserRole(doc.data().role);
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        setUser(null);
      }
    });

    // Detach listener
    return unsubscribe;
  }, []);

  const handleNameChange = (e) => {
    setAuthorName(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleInfoChange = (e) => {
    setInfo(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const handleLinkTitleChange = (e) => {
    setLinkTitle(e.target.value);
  };
  const handleGitChange = (e) => {
    setGit(e.target.value);
  };
  const handleGitTitleChange = (e) => {
    setGitTitle(e.target.value);
  };
  const handleLabChange = (e) => {
    setLab(e.target.value);
  };
  const handleLabTitleChange = (e) => {
    setLabTitle(e.target.value);
  };
  const handleBucketChange = (e) => {
    setBucket(e.target.value);
  };
  const handleBucketTitleChange = (e) => {
    setBucketTitle(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAccountChange = (e) => {
    setUserRole(e.target.value);
  };

  const handleUpdate = async () => {
    if (info) {
      if (!fileUrl) {
        toast.error("Du måste ladda upp en profilbild");
        return;
      }

      try {
        // Uppdatera lösenordet om lösenordsfältet inte är tomt
        if (password !== "") {
          await updatePassword(auth.currentUser, password);
          toast.success("Ditt lösenord har uppdaterats");
        }

        // Uppdatera användarprofilen
        const userRef = Users.doc(user.uid);
        await userRef.update({
          authorName: authorName,
          info: info,
          link: link,
          linkTitle: linkTitle,
          git: git,
          gitTitle: gitTitle,
          lab: lab,
          labTitle: labTitle,
          bucket: bucket,
          bucketTitle: bucketTitle,
          userRole: userRole,
        });

        console.log("Profilen uppdaterades framgångsrikt");
        toast.success("Din profil är uppdaterad");
        navigate("/profil");
      } catch (error) {
        console.error("Fel vid uppdatering av profil: ", error);
        toast.error("Ett fel uppstod vid uppdatering av din profil");
      }
    } else {
      console.log("Info-värdet är odefinierat");
    }
  };
  const handleFileUpload = (uploadedFileUrl) => {
    Users.doc(user.uid)
      .update({ FileUpload: uploadedFileUrl })
      .then(() => {
        console.log("Profile image updated successfully");
        setFileUrl(uploadedFileUrl);
      })
      .catch((error) => {
        console.error("Error updating profile image: ", error);
      });
  };

  const isDrone = userRole === "Drone";

  return (
    <>
      <div className="box">
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-half-tablet is-half-desktop">
            <h2 className="title" style={{ textAlign: "center" }}>
              Ändra din profil
            </h2>

            <div className="profil-img">
              {fileUrl && (
                <img
                  className="profil-fulpage"
                  src={fileUrl}
                  alt="Thumbnail"
                  width="200"
                  height="200"
                />
              )}
              <p />
              <div className="space-img">
                <FileUploadProfile handleFileUpload={handleFileUpload} />
              </div>
            </div>
          </div>
          <div className="column is-full-mobile is-half-tablet is-half-desktop">
            <div
              className="card custom-card"
              style={{
                backgroundColor: "#f4f4f4",
                color: "black",
                padding: "4%",
              }}
            >
              <div className="column" backgroundColor="#f4f4f4">
                <div className="space">
                  <div className="subtitle is-5">Namn:</div>
                </div>

                <div className="space ">
                  <input
                    type="text"
                    className="input"
                    value={authorName}
                    onChange={handleNameChange}
                    style={{ width: "560px" }}
                  />
                </div>
                <div className="space">
                  <div className="subtitle is-5">Lösenord:</div>
                </div>
                <div className="space">
                  <input
                    type="password"
                    className="input"
                    value={password}
                    onChange={handlePasswordChange}
                    style={{ width: "560px" }}
                  />
                </div>
                <div className="space">
                  <div className="subtitle is-5">Hemsida:</div>
                </div>

                <div className="space ">
                  <input
                    type="text"
                    className="input"
                    value={link}
                    onChange={handleLinkChange}
                    style={{ width: "560px" }}
                  />
                </div>

                <div className="space">
                  <div className="subtitle is-5">Sidnamn:</div>
                </div>
                <div className="space ">
                  <input
                    type="text"
                    className="input"
                    value={linkTitle}
                    onChange={handleLinkTitleChange}
                    style={{ width: "560px" }}
                  />
                </div>
                <div className="space">
                  <div className="subtitle is-5">GitHub adress:</div>
                </div>
                <div className="space ">
                  <input
                    type="text"
                    className="input is-normal"
                    placeholder="https://"
                    value={git}
                    onChange={handleGitChange}
                    style={{ width: "560px" }}
                  />
                </div>
                <div className="space">
                  <div className="subtitle is-5">GitHub namn:</div>
                </div>

                <div className="space ">
                  <input
                    type="text"
                    className="input"
                    value={gitTitle}
                    onChange={handleGitTitleChange}
                    style={{ width: "560px" }}
                  />
                </div>
                <div className="space">
                  <div className="subtitle is-5">GitLab adress:</div>
                </div>
                <div className="space ">
                  <input
                    type="text"
                    className="input is-normal"
                    placeholder="https://"
                    value={lab}
                    onChange={handleLabChange}
                    style={{ width: "560px" }}
                  />
                </div>
                <div className="space">
                  <div className="subtitle is-5">GitLab namn:</div>
                </div>

                <div className="space ">
                  <input
                    type="text"
                    className="input"
                    value={labTitle}
                    onChange={handleLabTitleChange}
                    style={{ width: "560px" }}
                  />
                </div>
                <div className="space">
                  <div className="subtitle is-5">Bitbucket adress:</div>
                </div>
                <div className="space ">
                  <input
                    type="text"
                    className="input is-normal"
                    placeholder="https://"
                    value={bucket}
                    onChange={handleBucketChange}
                    style={{ width: "560px" }}
                  />
                </div>
                <div className="space">
                  <div className="subtitle is-5">Bitbucket namn:</div>
                </div>

                <div className="space ">
                  <input
                    type="text"
                    className="input"
                    value={bucketTitle}
                    onChange={handleBucketTitleChange}
                    style={{ width: "560px" }}
                  />
                </div>
                <div className="space">
                  <div className="subtitle is-5">E-Post:</div>
                </div>

                <div className="space">
                  <input
                    type="email"
                    className="input"
                    value={email}
                    style={{ width: "560px" }}
                    onChange={handleEmailChange}
                  />
                </div>

                <div className="space">
                  <div className="subtitle is-5">Info:</div>
                </div>

                <div className="space">
                  <textarea
                    value={info}
                    className="textarea"
                    onChange={handleInfoChange}
                  />
                </div>

                <div className="space">
                  <div className="subtitle is-5">Konto:</div>
                </div>

                <div className="space">
                  <input
                    type="text"
                    className="input"
                    size={10}
                    value={userRole}
                    onChange={handleAccountChange}
                    disabled
                    style={{ width: "300px" }}
                  />
                </div>

                <div className="space">
                  <button className="button is-primary" onClick={handleUpdate}>
                    Uppdatera din profil
                  </button>
                </div>

                <div className="space-drone">
                  {isDrone && (
                    <p>
                      Som <strong>Drone</strong> kan du bara editera vissa
                      saker!
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
