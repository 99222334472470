import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import trash from "../assets/social/trash.png";
import arrowUp from "../assets/social/sortupp.png";
import arrowDown from "../assets/social/sortner.png";
import searchIcon from "../assets/social/sok.png";
import close from "../assets/social/close.png";

import Modal from "react-modal";
import fb from "../components/script/firebase";
import "../components/css/order.css";
const db = fb.firestore();
const Users = db.collection("users");

const AllUser = () => {
  const [usersList, setUsersList] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const deleteUser = (id) => {
    Users.doc(id)
      .delete()
      .then(() => {
        toast.info("Du har tagit bort användaren!");
      })
      .catch((error) => {
        console.error("Error removing user: ", error);
      });
  };

  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  const handleSearchClose = () => {
    setShowSearch(false);
    setSearchQuery("");
  };

  const handleUserRoleChange = (userId, newRole) => {
    setSelectedUserId(userId);
    setSelectedUserRole(newRole);
    setIsConfirmationModalOpen(true);
  };

  const confirmUserRoleChange = () => {
    Users.doc(selectedUserId)
      .update({ role: selectedUserRole })
      .then(() => {
        toast.success("Användarnivån har uppdaterats");
        setIsConfirmationModalOpen(false);
      })
      .catch((error) => {
        console.error("Error updating user role: ", error);
      });
  };

  useEffect(() => {
    const unsubscribe = Users.orderBy("authorName", sortOrder)
      .where("authorName", ">=", searchQuery)
      .where("authorName", "<=", searchQuery + "\uf8ff")
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setUsersList(data);
      });

    // Detach listener
    return unsubscribe;
  }, [sortOrder, searchQuery]);

  const isAdmin = (user) => {
    return user.role === "Admin";
  };

  return (
    <>
      <div className="box">
        <h2 className="title">Alla Användare</h2>
        <div className="search-container">
          <img
            src={sortOrder === "asc" ? arrowUp : arrowDown}
            alt="Sort Order"
            width={30}
            onClick={toggleSortOrder}
          />
          {showSearch ? (
            <>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                className="input"
                placeholder="Sök användare..."
              />
              <img
                src={close}
                alt="Close"
                className="close-icon"
                onClick={handleSearchClose}
                width={30}
              />
            </>
          ) : (
            <img
              onClick={handleSearchIconClick}
              src={searchIcon}
              alt="Search"
              width={30}
            />
          )}
        </div>
        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th className="subtitle is-5">
                  <strong>Namn</strong>
                </th>

                <th className="subtitle is-5">
                  <strong>Roll</strong>
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {usersList.map((user) => (
                <tr key={user.id}>
                  <td className="subtitle is-5">
                    <Link to={`/user/${user.id}`}>{user.authorName} </Link>
                  </td>

                  <td className="subtitle is-5">
                    {isAdmin(user) ? (
                      user.role
                    ) : (
                      <select
                        value={user.role}
                        className="subtitle is-5"
                        onChange={(e) =>
                          handleUserRoleChange(user.id, e.target.value)
                        }
                      >
                        <option className="subtitle is-5" value="Admin">
                          Admin
                        </option>
                        <option className="subtitle is-5" value="Drone">
                          Drone
                        </option>
                        <option className="subtitle is-5" value="User">
                          Användare
                        </option>
                      </select>
                    )}
                  </td>
                  {!isAdmin(user) && (
                    <>
                      <td></td>
                      <td>
                        <Link
                          onClick={() => {
                            deleteUser(user.id);
                          }}
                        >
                          <img src={trash} alt="trash" width={30} />
                        </Link>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isConfirmationModalOpen}
        onRequestClose={() => setIsConfirmationModalOpen(false)}
        contentLabel="Bekräfta ändring av användarnivå"
        className="confirmation-modal"
      >
        <h3>Ändra användarnivå</h3>
        <p>Är du säker på att du vill ändra användarnivån?</p>
        <div className="modal-buttons">
          <button onClick={confirmUserRoleChange}>Ja</button>
          <button onClick={() => setIsConfirmationModalOpen(false)}>Nej</button>
        </div>
      </Modal>
    </>
  );
};

export default AllUser;
