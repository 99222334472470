import React, { useContext, useState, useEffect } from "react";
import up from "../../assets/social/up.png";
import down from "../../assets/social/down.png";
import money from "../../assets/social/money.png";
import vat from "../../assets/social/vat.png";
import { CartContext } from "./CartContext";
import { useNavigate } from "react-router-dom";
import "../../components/css/modal.css";
import { fb } from "../../components/script/firebase";
import firebase from "../../components/script/firebase"; // Update the path to match your actual file structure
import { getDatabase, ref, remove, set } from "firebase/database";

const CartView = ({ cartItems, onClose }) => {
  const cartContext = useContext(CartContext);
  const { removeFromCart, addToCart } = cartContext;
  const history = useNavigate();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [selectedSize, setSelectedSize] = useState("");
  const database = getDatabase();
  // Konfigurera din Firebase här (byt ut "YOUR_FIREBASE_CONFIG" mot rätt sökväg)
  const firebaseConfig = require("../../components/script/firebase");
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const calculateTotalPrice = () => {
    let total = 0;
    let totalVATAmount = 0;

    cartItems.forEach((product) => {
      total += product.totalPriceWithMoms * product.quantity;
      totalVATAmount +=
        (product.totalPriceWithMoms - product.price) * product.quantity;
    });

    setTotalVAT(totalVATAmount);
    return total;
  };

  const handleRemoveFromCart = (productToRemove) => {
    removeFromCart(productToRemove);

    // Remove the product from Firebase Realtime Database
    remove(ref(database, "cart/" + productToRemove.id))
      .then(() => {
        console.log("Product removed from Firebase");
      })
      .catch((error) => {
        console.error("Error removing product from Firebase:", error);
      });
  };

  const handleGoToCheckout = () => {
    const order = {
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      products: cartItems.map((product) => ({
        title: product.title,
        quantity: product.quantity,
        totalPriceWithMoms: product.totalPriceWithMoms,
        selectedSize: product.selectedSize,
        Type: product.type,
      })),
    };
    order.products = order.products.map((product) => {
      if (!product.selectedSize) {
        delete product.selectedSize;
      }
      return product;
    });
    // Lägg till ordern i "checkout" collection i Firebase-databasen
    fb.collection("checkout")
      .add(order)
      .then((docRef) => {
        console.log("Order added to Checkout in Firebase with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding order to Checkout in Firebase:", error);
      });

    onClose();
    history("/checkout"); // Navigera till kassan
  };

  useEffect(() => {
    // Uppdatera det totala priset varje gång cartItems ändras
    const total = calculateTotalPrice();
    setTotalPrice(total);
  }, [cartItems]);

  return (
    <div>
      <h2 className="title is-3" style={{ textAlign: "center" }}>
        Varukorg
      </h2>
      {cartItems.length === 0 ? (
        <p className="subtitle is-6">Varukorgen är tom.</p>
      ) : (
        <ul>
          {cartItems.map((product) => {
            // Skip rendering items with total price of 0
            if (product.totalPrice === 0) {
              return null;
            }

            return (
              <li key={`${product.id}-${product.selectedSize}`}>
                <table>
                  <tr>
                    <td>
                      <img
                        width="100px"
                        height="100px"
                        style={{
                          marginBottom: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "10px",

                          textAlign: "center",
                          backgroundColor: "#f4f4f4",
                          padding: "2px",
                        }}
                        src={product.file}
                        alt="Thumbnail"
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="subtitle is-6">
                        <strong>{product.title}</strong>
                      </div>
                    </td>
                    <td>
                      <div
                        className="subtitle is-6"
                        style={{
                          marginBottom: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "10px",
                          width: "40px",
                          textAlign: "center",
                          backgroundColor: "#f4f4f4",
                          padding: "2px",
                          display: product.selectedSize ? "block" : "none",
                        }}
                      >
                        <strong> {product.selectedSize}</strong>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="subtitle is-6">
                        <strong> Antal: {product.quantity}</strong>
                      </div>
                    </td>

                    <td>
                      <img
                        src={up}
                        alt="up"
                        className="updown"
                        onClick={() => addToCart(product)}
                      />
                    </td>
                    <td>
                      <img
                        className="updown"
                        src={down}
                        alt="down"
                        onClick={() => handleRemoveFromCart(product)}
                      />
                    </td>

                    <td>
                      <div
                        className="subtitle is-6"
                        style={{ textAlign: "end" }}
                      >
                        <strong>{product.totalPriceWithMoms} kr</strong>
                      </div>
                      <div
                        className="subtitle is-6"
                        style={{ textAlign: "end" }}
                      >
                        <strong>Moms: </strong>
                        {Math.max(
                          product.totalPriceWithMoms - product.price,
                          0
                        ).toFixed(2)}{" "}
                        kr
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </table>
              </li>
            );
          })}
        </ul>
      )}

      {totalPrice > 0 && (
        <strong>
          <div className="subtitle is-5">
            {" "}
            <strong>Totalt: {totalPrice} kr </strong>
          </div>
        </strong>
      )}

      {totalVAT > 0 && (
        <div className="subtitle is-6">
          Varav moms: {totalVAT.toFixed(2)} kr
        </div>
      )}
      <div style={{ display: "flex", gap: "60px" }}>
        <div className="subtitle is-4" style={{ textAlign: "left" }}>
          <button
            className="button is-secondary"
            onClick={() => onClose()} // Close the cart view, you can customize this logic
          >
            Fortsätt handla
          </button>
        </div>
        <div className="subtitle is-4" style={{ textAlign: "right" }}>
          <button
            className="button is-primary"
            onClick={() => handleGoToCheckout()}
          >
            Gå till Kassan
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartView;
