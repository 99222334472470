import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import arrowUp from "../../assets/social/sortupp.png";
import arrowDown from "../../assets/social/sortner.png";
import searchIcon from "../../assets/social/sok.png";
import close from "../../assets/social/close.png";

import fb from "../../components/script/firebase";
const db = fb.firestore();
const Users = db.collection("users");

const AllUsers = () => {
  const [usersList, setUsersList] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  const handleSearchClose = () => {
    setShowSearch(false);
    setSearchQuery("");
  };

  useEffect(() => {
    const unsubscribe = Users.orderBy("authorName", sortOrder)
      .where("authorName", ">=", searchQuery)
      .where("authorName", "<=", searchQuery + "\uf8ff")
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setUsersList(data);
      });

    // Detach listener
    return unsubscribe;
  }, [sortOrder, searchQuery]);

  return (
    <>
      <div className="box">
        <h2 className="title">Alla Användare</h2>
        <div className="search-container">
          <img
            src={sortOrder === "asc" ? arrowUp : arrowDown}
            alt="Sort Order"
            width={30}
            onClick={toggleSortOrder}
          />
          {showSearch ? (
            <>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                className="input"
                placeholder="Sök användare..."
              />
              <img
                src={close}
                alt="Close"
                className="close-icon"
                onClick={handleSearchClose}
                width={30}
              />
            </>
          ) : (
            <img
              onClick={handleSearchIconClick}
              src={searchIcon}
              alt="Search"
              width={30}
            />
          )}
        </div>
        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th className="subtitle is-5">
                  <strong>Namn</strong>
                </th>
                <th className="subtitle is-5">
                  <strong>Roll</strong>
                </th>

                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {usersList.map((user) => (
                <tr key={user.id}>
                  <td className="subtitle is-5">
                    <Link to={`/userProfile/${user.id}`}>
                      {user.authorName}{" "}
                    </Link>
                  </td>
                  <td className="subtitle is-5">{user.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AllUsers;
