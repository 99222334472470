import React, { useContext, useState, useEffect } from "react";
import db from "../../assets/DBs.png";
import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import { CartContext } from "../products/CartContext";
import { Sling as Hamburger } from "hamburger-react";
import cartIcon from "../../assets/social/cart.png";
import { AuthContext } from "../../components/script/AuthContext";
import { useNavigate } from "react-router-dom";
import { auth } from "../../components/script/firebase";

import CartModal from "../products/CartModal";
import "../../components/css/navbar1.css";
import { fb } from "../../components/script/firebase";

const Navbar = () => {
  const { cartItems } = useContext(CartContext);
  const [isActive, setisActive] = useState(false);
  const { isLoggedIn, userRole, logout } = useContext(AuthContext);
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [logoutConfirmed, setLogoutConfirmed] = useState(false);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const [hasNewComments, setHasNewComments] = useState(false);
  const [orderCount, setOrderCount] = useState(0);
  const [adminMenuOpen, setAdminMenuOpen] = useState(false);
  const fetchOrderCount = () => {
    fb.collection("orders")
      .get()
      .then((querySnapshot) => {
        setOrderCount(querySnapshot.size);
      })
      .catch((error) => {
        console.error("Error fetching order count:", error);
      });
  };
  useEffect(() => {
    fetchOrderCount();
  }, []);

  const handleClick = () => {
    setisActive(false);
  };
  const [isCartModalOpen, setCartModalOpen] = useState(false);

  const handleCartClick = () => {
    setCartModalOpen(true);
  };

  const handleCloseModal = () => {
    setCartModalOpen(false);
  };
  const navigate = useNavigate();

  const handleLogout = () => {
    if (logoutConfirmed) {
      signOut(auth)
        .then(() => {
          logout();
          navigate("/");
          window.location.reload();
        })
        .catch((error) => {
          console.error("An error occurred during logout:", error);
        })
        .finally(() => {
          setLogoutModalOpen(false);
        });
    } else {
      setLogoutModalOpen(false);
    }
  };
  const calculateTotalQuantity = () => {
    let totalQuantity = 0;
    cartItems.forEach((item) => {
      totalQuantity += item.quantity;
    });
    return totalQuantity;
  };

  const toggleLogoutModal = () => {
    setLogoutConfirmed(false);
    setLogoutModalOpen(!isLogoutModalOpen);
  };

  const handleUserActivity = () => {
    setLastActivityTime(Date.now());
  };

  const checkForNewComments = () => {
    fb.collection("kommentarer")
      .where("timestamp", ">", lastActivityTime)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          setHasNewComments(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching new comments:", error);
      });
  };

  useEffect(() => {
    let logoutTimeout;

    const resetLogoutTimeout = () => {
      clearTimeout(logoutTimeout);
      logoutTimeout = setTimeout(() => {
        handleLogout();
      }, 10 * 60 * 1000); // 10 minutes
    };

    const checkUserInactivity = () => {
      const currentTime = Date.now();
      const inactivityDuration = currentTime - lastActivityTime;
      if (inactivityDuration >= 10 * 60 * 1000) {
        handleLogout();
      } else {
        resetLogoutTimeout();
        checkForNewComments();
      }
    };

    const resetLogoutTimeoutOnActivity = () => {
      handleUserActivity();
      resetLogoutTimeout();
    };

    window.addEventListener("mousemove", resetLogoutTimeoutOnActivity);
    window.addEventListener("keydown", resetLogoutTimeoutOnActivity);

    resetLogoutTimeout();

    return () => {
      clearTimeout(logoutTimeout);
      window.removeEventListener("mousemove", resetLogoutTimeoutOnActivity);
      window.removeEventListener("keydown", resetLogoutTimeoutOnActivity);
    };
  }, [lastActivityTime]);
  const totalCartQuantity = calculateTotalQuantity();

  const toggleAdminMenu = () => {
    setAdminMenuOpen(!adminMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setisActive(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <div className="logo-my">
          <Link to="/">
            <img
                src={db}
                alt="Logo"

            />
          </Link>
        </div>

        <div className={`navbar-burger ${isActive ? "is-active" : ""}`}>
          <Hamburger
            size={40}
            color="black"
            toggled={isActive}
            easing="ease-in"
            toggle={setisActive}
          />
        </div>
      </div>
      <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
        <div className="navbar-end">
          {isLoggedIn ? (
            <>
              {userRole === "Admin" && (
                <div className="navbar-item">
                  <Link to="/products" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Produkter
                    </div>
                  </Link>
                  <Link to="/review" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Recensioner
                    </div>
                  </Link>
                  <Link to="/article" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Artiklar
                    </div>
                  </Link>

                  <div className="navbar-item has-dropdown is-hoverable">
                    <div
                      className={`navbar-link ${
                        adminMenuOpen ? "is-active" : ""
                      }`}
                      onClick={toggleAdminMenu}
                    >
                      <div className="panel-text">Admin</div>
                    </div>
                    <div
                      className={`navbar-dropdown ${
                        adminMenuOpen ? "is-active" : ""
                      }`}
                    >
                      <Link
                        to="/profil"
                        className="navbar-item mobile-highlight"
                      >
                        <div className="panel-text" onClick={handleClick}>
                          Profil
                        </div>
                      </Link>
                      <Link
                        to="/edit-profil/"
                        className="navbar-item mobile-highlight"
                      >
                        <div className="panel-text" onClick={handleClick}>
                          Ändra profil
                        </div>
                      </Link>
                      <Link
                        to="/dashboard"
                        className="navbar-item mobile-highlight"
                      >
                        <div className="panel-text" onClick={handleClick}>
                          Artiklar
                        </div>
                      </Link>
                      <Link
                        to="/reviews"
                        className="navbar-item mobile-highlight"
                      >
                        <div className="panel-text" onClick={handleClick}>
                          Recensioner
                        </div>
                      </Link>
                      <Link
                        to="/orders"
                        className="navbar-item mobile-highlight"
                      >
                        <div className="panel-text" onClick={handleClick}>
                          Ordrar ({orderCount})
                        </div>
                      </Link>
                      <Link
                        to="/allproducts"
                        className="navbar-item mobile-highlight"
                      >
                        <div className="panel-text" onClick={handleClick}>
                          Produkter
                        </div>
                      </Link>
                      <Link
                        to="/users"
                        className="navbar-item mobile-highlight"
                      >
                        <div className="panel-text" onClick={handleClick}>
                          Användare
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {userRole === "Drone" && (
                <div className="navbar-item">
                  <Link to="/products" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Produkter
                    </div>
                  </Link>
                  <Link to="/review" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Recensioner
                    </div>
                  </Link>
                  <Link to="/article" className="navbar-item">
                    <div className="panel-text" onClick={handleClick}>
                      Artiklar
                    </div>
                  </Link>
                  <div className="navbar-item has-dropdown">
                    <div className="navbar-link">
                      <div className="panel-text">Profil meny</div>
                    </div>
                    <div
                      className={`navbar-dropdown ${
                        adminMenuOpen ? "is-active" : ""
                      }`}
                    >
                      <Link
                        to="/profil"
                        className="navbar-item mobile-highlight"
                      >
                        <div className="panel-text" onClick={handleClick}>
                          Profil
                        </div>
                      </Link>
                      <Link
                        to="/edit-profil/"
                        className="navbar-item mobile-highlight"
                      >
                        <div className="panel-text" onClick={handleClick}>
                          Ändra profil
                        </div>
                      </Link>
                      <Link
                        to="/allUsers"
                        className="navbar-item mobile-highlight"
                      >
                        <div className="panel-text" onClick={handleClick}>
                          Användare
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              <div className="navbar-item has-text-centered-mobile">
                <button
                  onClick={toggleLogoutModal}
                  className="button is-warning is.normal"
                >
                  <div>Logga ut</div>
                </button>
              </div>
            </>
          ) : (
            <div className="navbar-start">
              <Link to="/products" className="navbar-item">
                <div className="panel-text" onClick={handleClick}>
                  Produkter
                </div>
              </Link>
              <Link to="/review" className="navbar-item">
                <div className="panel-text" onClick={handleClick}>
                  Recensioner
                </div>
              </Link>
              <Link to="/article" className="navbar-item">
                <div className="panel-text" onClick={handleClick}>
                  Artiklar
                </div>
              </Link>
              <div className="navbar-item has-text-centered-mobile">
                <Link to="/signup" className="button is-info is-normal">
                  <div className="panel-text-button" onClick={handleClick}>
                    Bli Medlem
                  </div>
                </Link>
              </div>
              <div className="navbar-item has-text-centered-mobile">
                <Link to="/login" className="button is-link is-normal">
                  <div className="panel-text-button" onClick={handleClick}>
                    Logga In
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      {isLogoutModalOpen && (
        <div className="logout-modal">
          <div className="logout-content">
            <div
              className="title"
              style={{ color: "white", textAlign: "center" }}
            >
              Bekräfta
            </div>
            <div className="suptitle is-5" style={{ textAlign: "center" }}>
              Är du säker på att du vill logga ut?
            </div>
            <div className="modal-buttons">
              {!logoutConfirmed ? (
                <button
                  className="button"
                  style={{ color: "black", fontWeight: "bold" }}
                  onClick={() => setLogoutConfirmed(true)}
                >
                  Logga ut
                </button>
              ) : (
                <>
                  <button
                    className="button"
                    style={{ color: "black", fontWeight: "bold" }}
                    onClick={handleLogout}
                  >
                    Bekräfta logga ut
                  </button>
                  <button
                    className="button"
                    style={{ color: "black", fontWeight: "bold" }}
                    onClick={() => setLogoutConfirmed(false)}
                  >
                    Ångra
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="cart-icon-container">
        <img src={cartIcon} alt="Varukorg" onClick={handleCartClick} />
        {totalCartQuantity > 0 && (
          <div className="cart-badge">{totalCartQuantity}</div>
        )}
        {isCartModalOpen && <CartModal onClose={handleCloseModal} />}
      </div>
    </nav>
  );
};

export default Navbar;
