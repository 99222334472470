import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../components/css/modal.css";
import discord from "../../assets/social/discord.png";
import facebook from "../../assets/social/facebook.png";
import cookie from "../../assets/social/cookie.png";
import gitlab from "../../assets/social/gitlab.png";
import update from "../../assets/social/update.png";
import holmdev from "../../assets/social/holmdevbutton.png";
import instagram from "../../assets/social/instagram.png";
import close from "../../assets/social/close.png";
import { appVersion } from "../../components/script/version";
import jsonData from "../../components/script/update.json";
import Weather from "../Weather/Wheater";
function Modal({ closeModal, updateData }) {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={closeModal}>
          <img
            src={close}
            alt="close"
            style={{ width: "20px", cursor: "pointer" }}
          />
        </span>
        <h2 className="subtitle is-5" style={{ textAlign: "center" }}>
          Uppdateringar
        </h2>
        <ul>
          {updateData.map((update, index) => (
            <li key={index}>
              <strong>{update.date}</strong>
              <p />
              <strong>{update.version}</strong>: {update.message}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function Footer() {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState([]);

  useEffect(() => {
    // Hämta data från JSON-filen
    setUpdateData(jsonData.updates);
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="box" style={{ backgroundColor: "#f4f4f4" }}>
      <div className="content has-text-centered">
        <p>
          © {new Date().getFullYear()} DevBlog & Holm Developer. Alla
          rättigheter förbehållna.
        </p>
        <p>Version: {appVersion}</p>
        <div className="footer-links">
          <div>
            <Link to="/cookie-policy">
              {" "}
              <img alt="cookie" src={cookie} />
            </Link>
          </div>
          <div>
            <a href="https://www.holmdeveloper.se">
              <img alt="holmdev" src={holmdev} />
            </a>
          </div>
          <div>
            <a href="https://www.facebook.com/holmdev">
              <img alt="facebook" src={facebook} />
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/holmdev">
              {" "}
              <img alt="instagram" src={instagram} />
            </a>
          </div>
          <div>
            <a href="https://gitlab.com/holmdeveloper">
              {" "}
              <img alt="gitlab" src={gitlab} />
            </a>
          </div>
          <div>
            <a href="https://discord.gg/p6fKbSfwVj">
              <img alt="discord" src={discord} />
            </a>
          </div>

          <div>
            <img
              alt="update"
              src={update}
              onClick={openModal}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="weather-container">
            <Weather />
          </div>
        </div>
      </div>
      {showModal && <Modal closeModal={closeModal} updateData={updateData} />}
    </div>
  );
}

export default Footer;
