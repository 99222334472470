import React, { useEffect, useState } from "react";
import { fb } from "../../components/script/firebase";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import company from "../../assets/social/company.png";
import user from "../../assets/social/user.png";
import close from "../../assets/social/close.png";

import shipping from "../../assets/social/shipping.png";
import firebase from "../../components/script/firebase";

import emailjs from "emailjs-com";
import "../../components/css/modal.css";

import "react-toastify/dist/ReactToastify.css";

const Checkout = () => {
  const [checkoutData, setCheckoutData] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [personNummer, setPersonummer] = useState("");
  const [address, setAddress] = useState("");
  const [ort, setOrt] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [postnummer, setPostNummer] = useState("");
  const [email, setEmail] = useState("");
  const [editedOrder, setEditedOrder] = useState(null);
  const [customerType, setCustomerType] = useState("private");
  const [type, setType] = useState("");

  const [showProcessingPopup, setShowProcessingPopup] = useState(false);
  const [shippingSelectedByProduct, setShippingSelectedByProduct] =
    useState(false);
  const [selectedShippingMethodId, setSelectedShippingMethodId] = useState("");
  const [shippingMethods, setShippingMethods] = useState([
    { id: "standard", name: "Standardfrakt", price: 49 },
    { id: "express", name: "Expressfrakt", price: 100 },
    { id: "Budbee", name: "Budbee", price: 29 },
  ]);
  const history = useNavigate();
  const hasProductsRequiringShipping = () => {
    return currentOrder.products.some(
      (product) => product.Type === "clothes" || product.Type === "gadgets"
    );
  };

  useEffect(() => {
    // Fetch the latest order data from Firebase-database
    const checkoutRef = fb.collection("checkout");
    checkoutRef
      .orderBy("timestamp", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setCurrentOrder({ id: doc.id, ...doc.data() });
        });
      });
  }, []);

  const calculateTotalPrice = (product) => {
    let total = 0;

    product.forEach((product) => {
      total += product.totalPriceWithMoms * product.quantity;
    });

    return total;
  };
  const calculateShippingCost = () => {
    const selectedShipping = shippingMethods.find(
      (method) => method.id === selectedShippingMethodId
    );
    return selectedShipping ? selectedShipping.price : 0;
  };

  const calculateTotalAmount = () => {
    const productSubtotal = calculateTotalPrice(currentOrder.products);
    const shippingCost = calculateShippingCost();
    return productSubtotal + shippingCost;
  };
  const handleConfirmOrder = () => {
    // Validering av personuppgifter och e-post
    if (customerType === "private") {
      if (!/^\d{5}$/.test(postnummer)) {
        toast.error("Postnummer måste bestå av 5 siffror.");
        return;
      }

      if (
        !/^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])-\d{4}$/.test(
          personNummer
        )
      ) {
        toast.error("Personnummer måste vara i formatet ååååmmdd-nnnn.");
        return;
      }
    } else if (customerType === "company") {
      if (!/^\d{6}-\d{4}$/.test(personNummer)) {
        toast.error("Organisationsnummer måste vara i formatet nnnnnn-nnnn.");
        return;
      }
    }

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      toast.error("Ange en giltig e-postadress.");
      return;
    }

    const batch = fb.batch(); // Skapa en batch för samlad uppdatering
    const gadgetsInCart = currentOrder.products.filter(
      (product) => product.Type === "gadgets"
    );

    gadgetsInCart.forEach((productInCart) => {
      const productRef = fb.collection("products").doc(productInCart.productId);
      const gadgetsQuantityInCart = productInCart.quantity;

      productRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const currentGadgetsStock = doc.data().GadgetsStock;
            const updatedGadgetsStock =
              currentGadgetsStock + gadgetsQuantityInCart;

            if (updatedGadgetsStock >= 0) {
              batch.update(productRef, { GadgetsStock: updatedGadgetsStock });
            } else {
              toast.error("Otillräckligt antal gadgets i lager.");
              return;
            }
          } else {
            console.log("Document not found");
          }
        })
        .catch((error) => {
          console.error(
            "Error updating GadgetsStock in products database:",
            error
          );
        });
    });

    batch
      .commit()
      .then(() => {
        console.log("GadgetsStock updated in products database");

        const hasClothesInCart = currentOrder.products.some(
          (product) => product.category === "clothes"
        );

        if (hasClothesInCart && !selectedShippingMethodId) {
          toast.error(
            "Du måste välja en fraktmetod för kläderna i din varukorg."
          );
          const hasGadgetsInCart = currentOrder.products.some(
            (product) => product.category === "gadgets"
          );

          if (hasGadgetsInCart && !selectedShippingMethodId) {
            toast.error(
              "Du måste välja en fraktmetod för gadgets i din varukorg."
            );
            return;
          }

          return;
        }

        fb.collection("orders")

          .add({
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            products: currentOrder.products,
            totalPrice: calculateTotalAmount(),
            // Använd nya fältvärden från input-fälten
            name: `${firstName} ${lastName}`,
            address: address,
            ort: ort,
            selectedSize: selectedSize,
            postnummer: postnummer,
            personNummer: personNummer,
            customerType: customerType,
            type: type,
            email: email,
          })
          .then((docRef) => {
            if (editedOrder) {
              const productSubtotal = calculateTotalPrice(editedOrder.products);
              const shippingCost = calculateShippingCost();
              const updatedTotalAmount = productSubtotal + shippingCost;

              editedOrder.totalPrice = productSubtotal;
              setSelectedShippingMethodId(""); // Remove selected shipping method

              setEditedOrder(editedOrder);
              setCurrentOrder(editedOrder); // Update currentOrder with editedOrder
            }
            const orderData = {
              order_id: docRef.id,
              products: currentOrder.products,
              total_price: calculateTotalAmount(),
            };

            const templateParams = {
              to_email: email,
              order_id: orderData.order_id,
              products: orderData.products
                .map((product) => {
                  let productInfo = `${product.title} (${product.quantity} st) - ${product.totalPriceWithMoms} kr`;

                  if (product.selectedSize) {
                    productInfo += ` -- ${product.selectedSize}`;
                  }
                  return productInfo;
                })
                .join("\n"),
              total_price: orderData.total_price,
              first_name: firstName,
              last_name: lastName,
              adress: address,
              postnummer: postnummer,
              ort: ort,
            };

            emailjs.send(
              "service_gqa8ew9",
              "template_zjeobrg",
              templateParams,
              "Q0z0jSCcaSg5TjA1w"
            );
            setShowProcessingPopup(true);

            setTimeout(() => {
              setShowProcessingPopup(false);
              history("/order-overview");
              window.location.reload();
            }, 3000);
          })
          .catch((error) => {
            console.error("Error adding order to Firestore:", error);
          });
      })
      .catch((error) => {
        console.error("Error committing batch update:", error);
      });
  };

  const handleDeleteProduct = (productIndex) => {
    const editedCopy = { ...currentOrder };
    const removedProduct = editedCopy.products[productIndex];

    editedCopy.products.splice(productIndex, 1);

    if (
      removedProduct.Type === "clothes" ||
      removedProduct.Type === "gadgets"
    ) {
      // Recalculate total amount without the removed product and updated shipping cost
      const productSubtotal = calculateTotalPrice(editedCopy.products);
      const updatedShippingCost = calculateShippingCost();
      const updatedTotalAmount = productSubtotal + updatedShippingCost;

      editedCopy.totalPrice = productSubtotal;
      setSelectedShippingMethodId("");

      setShowConfirmation(false);

      setEditedOrder(editedCopy);
    } else {
      setEditedOrder(editedCopy);
    }
  };
  const handleCancelOrder = () => {
    history("/products"); // Navigate back to products page when order is canceled
  };
  const handleSaveOrder = () => {
    if (editedOrder) {
      // Perform the update only if there are changes
      fb.collection("checkout")
        .doc(editedOrder.id)
        .update(editedOrder)
        .then(() => {
          toast.success("Din order är uppdaterad");
          setEditedOrder(null);
        })
        .catch((error) => {
          console.error("Error updating order:", error);
        });
    }
  };
  const handleDeleteOrder = () => {
    // Ta bort hela ordern
    fb.collection("checkout")
      .doc(currentOrder.id)
      .delete()
      .then(() => {
        toast.warn("Din order är bortagen");
        setCurrentOrder(null);
        history("/products");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error deleting order:", error);
      });
  };

  return (
    <div className="box">
      <div className="columns">
        <div className="column">
          <h2 className="title">Kassa</h2>
          {currentOrder && (
            <div>
              <div className="subtitle is-4" style={{ textAlign: "center" }}>
                <strong> Din beställning</strong>
              </div>
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Produkter</th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={currentOrder.id}>
                    <td>
                      {currentOrder.products && (
                        <ul>
                          {currentOrder.products.map((product, index) => (
                            <li key={index}>
                              {product.title} ({product.quantity} st) -{" "}
                              {product.totalPriceWithMoms} kr
                              {product.selectedSize &&
                                `(${product.selectedSize})`}
                              {currentOrder.products.length > 1 && (
                                <Link
                                  onClick={() => handleDeleteProduct(index)}
                                >
                                  <img src={close} alt="close" width="20px" />
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </td>

                    <td>
                      <Link
                        style={{
                          borderStyle: "solid",
                          backgroundColor: "#f4f4f4",
                          borderRadius: "10px",
                          border: "1px solid #ccc",
                          padding: "5px",

                          textAlign: "center",
                          color: "black",
                        }}
                        onClick={handleDeleteOrder}
                      >
                        <strong>Ta bort</strong>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Delsumma:</strong>{" "}
                      {calculateTotalPrice(currentOrder.products)} kr
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      {hasProductsRequiringShipping() && (
                        <div className="field">
                          <label className="label">
                            <img src={shipping} alt="shipping" />
                            Välj fraktmetod
                          </label>
                          <div className="control">
                            <select
                              className="select"
                              value={selectedShippingMethodId}
                              onChange={(e) =>
                                setSelectedShippingMethodId(e.target.value)
                              }
                            >
                              <option value="">Välj fraktmetod</option>
                              {shippingMethods.map((method) => (
                                <option key={method.id} value={method.id}>
                                  {method.name} - {method.price} kr
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                      {hasProductsRequiringShipping() &&
                        !selectedShippingMethodId && (
                          <p className="has-text-danger">
                            Du måste välja en fraktmetod för varorna i din
                            varukorg.
                          </p>
                        )}
                    </td>
                  </tr>
                  <td>
                    <strong>Totalsumma (inklusive frakt):</strong>{" "}
                    {calculateTotalAmount()} kr
                  </td>
                  <td></td>
                </tbody>
              </table>
              <div style={{ display: "flex", gap: "50%" }}>
                <div>
                  <button
                    className="button is-primary"
                    onClick={handleSaveOrder}
                    disabled={!editedOrder}
                  >
                    Spara Varukorg
                  </button>
                </div>
                <div>
                  <button
                    className="button is-secondary"
                    onClick={handleCancelOrder}
                  >
                    Ångra
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="column">
          <div className="delivery-info" style={{ marginTop: "10px" }}>
            <h2 className="title" style={{ textAlign: "center" }}>
              Lägg till leveransinformation:
            </h2>
            <div className="field">
              <label className="label">Kundtyp</label>
              <div className="control">
                <label className="radio">
                  <input
                    type="radio"
                    value="private"
                    checked={customerType === "private"}
                    onChange={() => setCustomerType("private")}
                  />
                  <img src={user} alt="user" />
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    value="company"
                    checked={customerType === "company"}
                    onChange={() => setCustomerType("company")}
                  />
                  <img src={company} alt="company" />
                </label>
              </div>
            </div>
            <div className="field">
              <label className="label">
                {customerType === "private" ? "Förnamn" : "Företagsnamn "}
              </label>
              <div className="control">
                <input
                  className={`input ${
                    customerType === "private" ? "is-focused" : ""
                  }`}
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  style={{ width: "500px" }}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">
                {customerType === "private" ? "Efternamn" : "Kontaktnamn"}
              </label>
              <div className="control">
                <input
                  className={`input ${
                    customerType === "private" ? "is-focused" : ""
                  }`}
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  style={{ width: "500px" }}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">
                {customerType === "private"
                  ? "Personnummer*(ååååmmdd-nnnn)"
                  : "Organisationsnummer*(nnnnnn-nnnn)"}
              </label>
              <div className="control">
                <input
                  className={`input ${
                    customerType === "private" ? "is-focused" : ""
                  }`}
                  type="text"
                  value={personNummer}
                  onChange={(e) => setPersonummer(e.target.value)}
                  style={{ width: "500px" }}
                  required
                />
              </div>
              *För att kunna fakutera krävs detta!
            </div>
            <div className="field">
              <label className="label">E-postadress</label>
              <div className="control">
                <input
                  className={`input ${email ? "is-focused" : ""}`}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: "500px" }}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Adress</label>
              <div className="control">
                <input
                  className={`input ${address ? "is-focused" : ""}`}
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  style={{ width: "500px" }}
                  required
                />
              </div>
            </div>
            <div className="post">
              <div className="field">
                <label className="label">Postnummer</label>
                <div className="control">
                  <input
                    className={`input ${postnummer ? "is-focused" : ""}`}
                    type="text"
                    value={postnummer}
                    onChange={(e) => setPostNummer(e.target.value)}
                    required
                    style={{ width: "250px" }}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Postort</label>
                <div className="control">
                  <input
                    className={`input ${ort ? "is-focused" : ""}`}
                    type="text"
                    value={ort}
                    onChange={(e) => setOrt(e.target.value)}
                    required
                    style={{ width: "250px" }}
                  />
                </div>
              </div>
            </div>

            <button
              className="button is-primary submit-button"
              onClick={() => {
                setShippingSelectedByProduct(true);
                handleConfirmOrder();
              }}
            >
              Betala din order
            </button>
          </div>
        </div>
        {showProcessingPopup && (
          <div className="processing-popup">
            <p>Vi behandlar din order nu...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkout;
