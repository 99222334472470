import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fb from "../../components/script/firebase";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../components/upload/upload";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";
import lank from "../../assets/social/link.png";
import seo from "../../assets/social/seo.png";
import forward from "../../assets/social/forward.png";
import grade from "../../assets/social/grade.png";
import "react-quill/dist/quill.snow.css";
import distrosData from "../../components/script/distros.json";
import desktopData from "../../components/script/desktop.json";
import ReactRatingStarsComponent from "react-rating-stars-component";

const db = fb.firestore();
const ReviewsCollection = db.collection("reviews");
const usersCollection = db.collection("users");

const CreateReviews = () => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState([]);
  const [desktopCategory, setDesktopCategory] = useState("");
  const [download, setDownload] = useState("");
  const [link, setLink] = useState("");
  const [availableDesktopCategories, setAvailableDesktopCategories] = useState(
    []
  );
  const [availableCategories, setAvailableCategories] = useState([]);
  const [body, setBody] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [meta, setMeta] = useState("");
  const [description, setDescription] = useState("");
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    setAvailableCategories(distrosData.distros);
    setAvailableDesktopCategories(desktopData.desktops);
  }, []);

  useEffect(() => {
    const currentUser = fb.auth().currentUser;
    if (currentUser) {
      usersCollection
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setAuthorName(doc.data().authorName);
          }
        })
        .catch((error) => {
          console.error("Error fetching author name:", error);
        });
    }
  }, []);

  const handleMetaChange = (e) => {
    setMeta(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const handleDownloadChange = (e) => {
    setDownload(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleDesktopCategoryChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setDesktopCategory(selectedOptions);

    if (selectedOptions.length > 0) {
      const newCategories = selectedOptions.filter(
        (option) => !availableDesktopCategories.includes(option)
      );
      setAvailableDesktopCategories((prevCategories) => [
        ...prevCategories,
        ...newCategories,
      ]);
    }
  };
  const handleCategoryChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setCategory(selectedOptions);

    if (!availableCategories.includes(category)) {
      setAvailableCategories((prevCategories) => [...prevCategories, category]);
    }
  };

  const handleEditorChange = (value) => {
    setBody(value);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const submit = (e) => {
    e.preventDefault();
    const currentUser = fb.auth().currentUser;
    if (currentUser) {
      const review = {
        Title: title,
        Category: category,
        DesktopCategory: desktopCategory,
        Body: body,
        Download: download,
        Link: link,
        File: fileUrl,
        createdAt: fb.firestore.Timestamp.fromDate(new Date()),
        createdBy: currentUser.uid,
        authorName: authorName,
        Description: description,
        meta: {
          value: meta,
        },
        Rating: rating, // Inkluderar betyget i recensionsobjektet
      };

      ReviewsCollection.add(review)
        .then(() => {
          toast.success("Recensionen är nu sparad!");
          navigate("/reviews");
        })
        .catch((error) => {
          console.error("Error saving review:", error);
        });
    } else {
      console.log("Ingen användare är inloggad");
    }
  };

  const handleFileUpload = (uploadedFileUrl) => {
    setFileUrl(uploadedFileUrl);
  };

  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half">
          <Link to="/reviews" className="subtitle is-5">
            <img src={forward} alt="back" style={{ width: "30px" }} />
          </Link>
          <label className="label">Ladda upp en bild</label>
          <p />
          <FileUpload handleFileUpload={handleFileUpload} multiple={true} />
        </div>
        <div className="column is-half">
          <label className="label">Titel på recensionen</label>
          <input
            type="text"
            className="input"
            placeholder="Title"
            style={{ width: "650px" }}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <label className="label">Recension</label>
          <ReactQuill
            value={body}
            onChange={handleEditorChange}
            placeholder="Skriv din text här"
          />
          <div className="container-post-ka">
            <label className="label" style={{ marginLeft: "10%" }}>
              <div style={{ marginRight: "10px" }}>Välj Kategorier</div>
            </label>
            <div className="select is-multiple">
              <select multiple value={category} onChange={handleCategoryChange}>
                {availableCategories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="container-post-ka">
            <label className="label" style={{ marginLeft: "10%" }}>
              <div style={{ marginRight: "10px" }}>Välj Skrivbord</div>
            </label>
            <div className="select is-multiple">
              <select
                multiple
                value={desktopCategory}
                onChange={handleDesktopCategoryChange}
              >
                {availableDesktopCategories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="container-post">
            <label>
              {" "}
              <img src={seo} alt="seo" />
            </label>
            <div>
              <label className="label">Meta tags</label>
              <input
                type="text"
                className="input"
                placeholder="Meta Value"
                onChange={handleMetaChange}
                required
              />
            </div>
            <div>
              <label className="label">Kort beskrivning</label>
              <input
                type="text"
                className="input"
                placeholder="Kort beskrivning"
                onChange={handleDescriptionChange}
                required
              />
            </div>
          </div>
          <div className="container-post">
            <label>
              {" "}
              <img src={lank} alt="link" />
            </label>
            <div>
              <label className="label">Länk till nerladdning</label>
              <input
                type="text"
                className="input"
                placeholder="https://"
                onChange={handleDownloadChange}
                required
              />
            </div>
            <div>
              <label className="label">Distro länk</label>
              <input
                type="text"
                className="input"
                placeholder="https://"
                onChange={handleLinkChange}
                required
              />
            </div>
          </div>
          <div className="container-post">
            <label>
              {" "}
              <img src={grade} alt="grade" />
            </label>
            <div>
              <label className="label">Betyg</label>

              <ReactRatingStarsComponent
                count={5}
                value={rating}
                onChange={handleRatingChange}
                size={24}
                activeColor="#ffd700"
              />
            </div>
          </div>
          <form onSubmit={submit}>
            <button className="button is-primary" type="submit">
              Posta Recension
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateReviews;
