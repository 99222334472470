import React, { createContext, useState, useEffect } from "react";
import { auth } from "./firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const logout = async () => {
    try {
      // Perform any additional logout logic here if needed
      // ...

      // Sign out the user using Firebase authentication
      await signOut(auth);

      setIsLoggedIn(false);
    } catch (error) {
      console.log("Logout error:", error);
    }
  };

  const authContextValue = {
    isLoggedIn,
    logout: logout,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
