import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import twitter from "../../assets/social/twitter.png";
import mastodon from "../../assets/social/mastodon.png";
import reddit from "../../assets/social/reddit.png";
import { CartContext } from "./CartContext";
import trash from "../../assets/social/trash.png";
import replay from "../../assets/social/replay.png";
import cal from "../../assets/social/cal.png";
import user from "../../assets/social/user.png";
import come from "../../assets/social/comments.png";
import forward from "../../assets/social/forward.png";
import category from "../../assets/social/category.png";
import art from "../../assets/social/artnumber.png";
import money from "../../assets/social/money.png";
import stock from "../../assets/social/stock.png";
import size from "../../assets/social/size.png";

import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { format } from "date-fns";

import fb from "../../components/script/firebase";

const db = fb.firestore();
const Products = db.collection("products");
const Comments = db.collection("comments");

const ProductView = () => {
  const { id } = useParams();
  const cartContext = useContext(CartContext);
  const { addToCart, cartItems, removeFromCart } = cartContext;
  const [product, setProduct] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [commentName, setCommentName] = useState("");
  const [commentEmail, setCommentEmail] = useState("");
  const [commentImg, setCommentImg] = useState("");
  const [isClothing, setIsClothing] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [replyCommentId, setReplyCommentId] = useState("");
  const [replyCommentName, setReplyCommentName] = useState("");
  const [replyCommentEmail, setReplyCommentEmail] = useState("");
  const [repalyCommentImg, setReplayCommentImg] = useState("");
  const [replyCommentText, setReplyCommentText] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedProductSize, setSelectedProductSize] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = fb.auth().currentUser;
      if (user) {
        const userDoc = await db.collection("users").doc(user.uid).get();
        const userData = userDoc.data();
        if (userData && userData.role === "Admin") {
          user.isAdmin = true;
        }
        user.authorName = userData && userData.authorName;
        user.FileUpload = userData && userData.FileUpload;
      }
      setCurrentUser(user);
    };

    fetchCurrentUser();
    // ...
  }, [id]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const snapshot = await Products.doc(id).get();
        if (snapshot.exists) {
          const productData = { ...snapshot.data(), id: snapshot.id };
          setProduct(productData);
          document.title = productData.Title;

          if (productData.Type && productData.Category.length > 0) {
            getRelatedProducts(productData.Category);

            // Kolla om produkten tillhör kategorin "Kläder" eller liknande
            setIsClothing(productData.Type.includes("clothes")); // Justera kategorin efter ditt behov
          }
        } else {
          console.log("Product not found");
        }
      } catch (error) {
        console.error("Error getting product: ", error);
      }
    };

    fetchProduct();
    getCommentsForPost(id);
  }, [id]);

  const getRelatedProducts = async (categories) => {
    try {
      const snapshot = await Products.where(
        "Category",
        "array-contains-any",
        categories
      )
        .limit(6)
        .get();

      const relatedProductsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setRelatedProducts(relatedProductsData);
    } catch (error) {
      console.error("Error getting related products: ", error);
    }
  };
  const getCommentsForPost = (blogId) => {
    Comments.where("postId", "==", blogId)
      .get()
      .then((querySnapshot) => {
        const comments = querySnapshot.docs.map(async (doc) => {
          const commentData = doc.data();
          const authorUid = commentData.authorUid;
          const authorSnapshot = await db
            .collection("users")
            .doc(authorUid)
            .get();
          const authorData = authorSnapshot.data();
          const commentWithAuthor = {
            id: doc.id,
            ...commentData,
            authorProfileImageUrl: authorData ? authorData.profileImageUrl : "",
          };
          return commentWithAuthor;
        });
        Promise.all(comments).then((resolvedComments) => {
          const sortedComments = resolvedComments.sort((a, b) => {
            const dateA = a.createdAt ? a.createdAt.toDate() : new Date(0);
            const dateB = b.createdAt ? b.createdAt.toDate() : new Date(0);
            return dateB - dateA;
          });
          setComments(sortedComments);
        });
      })
      .catch((error) => {
        console.error("Error getting comments: ", error);
      });
  };
  const handleShareMastodon = () => {
    const shareText = encodeURIComponent(product.Title);
    const desText = encodeURIComponent(product.Description);
    const shareUrl = encodeURIComponent(window.location.href);

    const shareMastodonUrl = `https://mastodon.social/share?text=$${shareText}%0A${desText}&url=${shareUrl}`;
    window.open(shareMastodonUrl, "_blank");
  };
  const handleShareReddit = () => {
    const shareText = encodeURIComponent(product.Title);
    const desText = encodeURIComponent(product.Description);
    const shareUrl = encodeURIComponent(window.location.href);

    const shareRedditUrl = `https://www.reddit.com/r/share?text=$${shareText}%0A${desText}&url=${shareUrl}`;
    window.open(shareRedditUrl, "_blank");
  };
  const handleShareTwitter = () => {
    const shareText = encodeURIComponent(product.Title);
    const desText = encodeURIComponent(product.Description);
    const shareUrl = encodeURIComponent(window.location.href);
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareText}%0A${desText}&url=${shareUrl}`;
    window.open(twitterShareUrl, "_blank");
  };
  const handleAddToCart = () => {
    if (isClothing && !selectedSize) {
      toast.success("Du måste välja en storlek för kläder");
      return;
    }
    addToCart(
      {
        id: product.id,
        title: product.Title,
        price: parseInt(product.myPrice),
        totalPriceWithMoms: product.PrisMedMoms,
        file: product.File,
        newSize: selectedSize,
        type: product.Type,
        totalPrice: parseInt(product.myPrice),
        quantity: 1,
      },
      selectedSize
    );

    const toastMessage = `Produkten "${product.Title}" har lagts till i varukorgen!`;
    toast.success(toastMessage);
    setSelectedSize("");
  };

  const handlePostComment = () => {
    if (isCaptchaVerified) {
      if (currentUser) {
        const commentData = {
          postId: id,
          comment: commentText,
          commentName: currentUser.authorName,
          commentEmail: currentUser.email,
          commentImg: currentUser.FileUpload,
          createdAt: fb.firestore.Timestamp.fromDate(new Date()),
          authorUid: currentUser.uid,
        };

        Comments.add(commentData)
          .then(() => {
            setCommentText("");
            getCommentsForPost(id);
          })
          .catch((error) => {
            console.error("Error posting comment: ", error);
          });
      } else {
        toast.info("Du måste logga in för att kunna kommentera.");
      }
    } else {
      toast.info("Vänligen verifiera dig själv med reCAPTCHA.");
    }
  };

  const handleCaptchaVerification = (response) => {
    setIsCaptchaVerified(true);
  };

  const handleDeleteComment = (commentId, authorUid) => {
    if (currentUser && (currentUser.uid === authorUid || currentUser.isAdmin)) {
      Comments.doc(commentId)
        .delete()
        .then(() => {
          getCommentsForPost(id);
          toast.info("Kommentar är bortagen!  ");
        })
        .catch((error) => {
          console.error("Error deleting comment: ", error);
        });
    } else {
      console.log("User is not authorized to delete this comment.");
    }
  };

  const handleReplyComment = (commentId, commentAuthorUid) => {
    setReplyCommentId(commentId);
    setReplyCommentName("");
    setReplyCommentEmail("");
    setReplyCommentText("");
    setReplayCommentImg("");
  };

  const handlePostReply = () => {
    if (isCaptchaVerified) {
      const parentComment = comments.find(
        (comment) => comment.id === replyCommentId
      );
      const parentCommentAuthorUid = parentComment
        ? parentComment.authorUid
        : "";

      const replyData = {
        postId: id,
        comment: replyCommentText,
        commentName: currentUser.authorName,
        commentEmail: currentUser.email,
        authorUid: currentUser.uid,
        commentImg: currentUser.FileUpload,
        createdAt: fb.firestore.Timestamp.fromDate(new Date()),
        parentCommentId: replyCommentId,
        parentCommentAuthorUid: parentCommentAuthorUid,
      };

      Comments.add(replyData)
        .then(() => {
          setReplyCommentId("");
          setReplyCommentName("");
          setReplyCommentEmail("");
          setReplyCommentText("");
          setReplayCommentImg("");
          getCommentsForPost(id);
        })
        .catch((error) => {
          console.error("Error posting reply: ", error);
        });
    } else {
      toast.info("Du måste verifiera dig med reCAPTCHA.");
    }
  };
  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <Helmet>
        <title>{product.Title}</title>
        <meta name="description" content={product.Description} />
        <meta property="og:title" content={product.Title} />
        <meta property="og:description" content={product.Description} />
        <meta name="keywords" content={product.meta} />
        <meta property="og:keywords" content={product.meta} />
      </Helmet>
      <div className="box">
        <div className="columns">
          <div className="column is-half">
            <div>
              <Link to="/products" className="subtitle is-5">
                <img src={forward} alt="back" style={{ width: "30px" }} />
              </Link>
            </div>
            <div className="img-front-full-products">
              <img
                src={product.File}
                alt="front"
                style={{
                  width: "70%",
                  borderRadius: "10px",
                  borderStyle: "solid",
                  border: "1px solid #ccc",

                  padding: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10%",
                }}
              />
            </div>

            <div className="subtitle is-6">
              <div className="cat-category" style={{ marginTop: "10px" }}>
                <div className="category">
                  <label className="label">
                    <img
                      src={category}
                      alt="category"
                      style={{ marginTop: "-4px" }}
                    />
                  </label>
                  <div>
                    {product.Category && Array.isArray(product.Category) ? (
                      product.Category.map((category, index) => (
                        <div
                          key={index}
                          style={{
                            marginRight: "5px",
                            marginBottom: "5px",
                            border: "1px solid #ccc",
                            backgroundColor: "white",
                            width: "120px",
                            textAlign: "center",
                            borderRadius: "10px",
                            padding: "5px",
                          }}
                        >
                          {category}
                        </div>
                      ))
                    ) : (
                      <div className="tag is-alert is-medium">
                        Inga kategorier tillagda
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="subtitle is-5" style={{ textAlign: "left" }}>
              <img src={money} alt="money" />{" "}
              <strong>{product.PrisMedMoms} kr</strong>
            </div>
            <div className="subtitle is-5" style={{ textAlign: "left" }}>
              <img src={art} alt="a" />
              <strong> {product.ArtNummer}</strong>
            </div>
            <div className="social" style={{ marginTop: "40px" }}>
              <div onClick={handleShareTwitter}>
                <img src={twitter} alt="twitter" className="socialIcons" />
              </div>
              <div onClick={handleShareMastodon}>
                <img src={mastodon} alt="mastodon" className="socialIcons" />
              </div>
              <div onClick={handleShareReddit}>
                <img src={reddit} alt="reddit" className="socialIcons" />
              </div>
            </div>
          </div>

          <div className="column is-half">
            <div className="subtitle is-2" style={{ textAlign: "center" }}>
              <strong>{product.Title}</strong>
            </div>
            <div
              className="subtitle is-5"
              dangerouslySetInnerHTML={{
                __html: product.Body,
              }}
            />
            {isClothing && product.Stock && product.Stock.length > 0 && (
              <div className="field">
                <div className="control">
                  <div id="stockInfo" className="box">
                    <div style={{ display: "flex" }}>
                      <label htmlFor="stockInfo" className="label">
                        <img
                          src={stock}
                          alt="stock"
                          style={{ marginTop: "-4px" }}
                        />
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "center", // Centrerar elementen horisontellt
                          gap: "5px",
                          textAlign: "center",
                        }}
                      >
                        <div className="tag-container">
                          {product.Stock.map((stockItem) => (
                            <div
                              key={stockItem.size}
                              className="subtitle is-6"
                              style={{
                                marginBottom: "10px",
                                border: "1px solid #ccc",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            >
                              <strong>{stockItem.size}:</strong>{" "}
                              {stockItem.stock === 0
                                ? "Finns ej i lager"
                                : `${stockItem.stock} st i lager`}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <label htmlFor="sizeSelect" className="label">
                        <img
                          src={size}
                          alt="size"
                          style={{ marginTop: "-3px" }}
                        />
                      </label>
                      <select
                        id="sizeSelect"
                        className="select is-normal"
                        style={{
                          marginLeft: "10px",
                          padding: "5px",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid",
                          borderRadius: "10px",
                        }}
                        value={selectedSize}
                        onChange={(e) => setSelectedSize(e.target.value)}
                      >
                        <option value="" style={{ textAlign: "center" }}>
                          Välj storlek
                        </option>

                        {product.Stock.map((stockItem) => (
                          <option
                            key={stockItem.size}
                            value={stockItem.size}
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {stockItem.size}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="field" style={{ textAlign: "right" }}>
                      <div className="control">
                        <button
                          className="button is-primary"
                          onClick={handleAddToCart}
                          disabled={
                            selectedSize === "" ||
                            (product &&
                              product.Stock.find(
                                (item) => item.size === selectedSize
                              )?.stock === 0)
                          }
                        >
                          Lägg i varukorgen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {product.Type &&
              product.Type.includes("gadgets") &&
              product.GadgetsStock &&
              product.GadgetsStock.length > 0 && (
                <div className="field">
                  <div className="control">
                    <div id="gadgetsStockInfo" className="box">
                      <div style={{ display: "flex" }}>
                        <label htmlFor="gadgetsStockInfo" className="label">
                          <img src={stock} alt="stock" />
                        </label>
                        <span
                          style={{
                            flex: "1 1 calc(33.33% - 10px)",
                            maxWidth: "calc(33.33% - 10px)",
                            marginBottom: "10px",
                            textAlign: "center",
                            border: "1px solid #ccc",
                            padding: "5px 20px 5px 20px",

                            borderRadius: "10px",
                          }}
                        >
                          {product.GadgetsStock > 0
                            ? `${product.GadgetsStock} st i lager`
                            : "Finns ej i lager"}
                        </span>
                      </div>
                      <div className="field" style={{ textAlign: "right" }}>
                        <div className="control">
                          {product.GadgetsStock > 0 && (
                            <button
                              className="button is-primary"
                              onClick={handleAddToCart}
                            >
                              <strong>Lägg i varukorg</strong>
                            </button>
                          )}
                          {console.log(product.GadgetsStock)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {product.Type && product.Type.includes("webdesign") && (
              <div className="field">
                <div className="control">
                  <button
                    className="button is-primary"
                    onClick={handleAddToCart}
                  >
                    <strong>Lägg i varukorg</strong>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="body-post">
          <div className="post-top-all">
            <div className="subtitle is-5">
              <span class="icon-text">
                <span class="icon">
                  <img src={user} alt="user" width={25} />
                </span>
                <span>
                  {" "}
                  <strong>{product.authorName}</strong>
                </span>
              </span>
            </div>

            <div className="subtitle is-5">
              <span class="icon-text">
                <span class="icon">
                  <img src={cal} alt="cal" width={25} />
                </span>
                <span>
                  <strong>
                    {product.createdAt &&
                      format(product.createdAt.toDate(), "yyyy-MM-dd")}
                  </strong>
                </span>
              </span>
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="subtitle is-5">
          <Link to="/products" className="subtitle is-5">
            <img src={forward} alt="back" style={{ width: "30px" }} />
          </Link>
        </div>
        <div className="related-blogs">
          <h3 className="related-blogs-title">Relaterade produkter</h3>
          {relatedProducts.map((relatedProducts) => (
            <div key={relatedProducts.id} className="related-blog">
              <Link
                to={`/products/show/${relatedProducts.id}`}
                className="related-blog-link"
              >
                <img
                  src={relatedProducts.File}
                  alt={relatedProducts.Title}
                  className="related-blog-image"
                />
                <div className="related-blog-info">
                  <div className="related-blog-title">
                    {relatedProducts.Title}
                  </div>
                  <div className="related-blog-category">
                    Kategori: {relatedProducts.Category.join(", ")}
                  </div>
                  <div className="related-product-price">
                    Pris: {relatedProducts.PrisMedMoms} kr
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="box">
        <div
          className="headComment"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <img src={come} alt="comments" width={35} />
          </div>
          <div className="title">
            {comments.length === 1
              ? "1 kommentar"
              : `${comments.length} kommentarer`}
          </div>
        </div>

        {currentUser && (
          <div>
            {showForm && (
              <>
                <div className="field">
                  <div>
                    {currentUser ? (
                      <input
                        className="input"
                        type="hidden"
                        placeholder="Ditt namn"
                        value={replyCommentName || currentUser.authorName}
                        onChange={(e) => setReplyCommentName(e.target.value)}
                        disabled
                      />
                    ) : (
                      <input
                        className="input"
                        type="hidden"
                        placeholder="Ditt namn"
                        value={replyCommentName}
                        onChange={(e) => setReplyCommentName(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    {currentUser ? (
                      <input
                        className="input"
                        type="hidden"
                        placeholder="Epost"
                        value={replyCommentEmail || currentUser.email}
                        onChange={(e) => setReplyCommentName(e.target.value)}
                        disabled
                      />
                    ) : (
                      <input
                        className="input"
                        type="hidden"
                        placeholder="E-post"
                        value={replyCommentEmail}
                        onChange={(e) => setReplyCommentEmail(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="field">
                  <div className="subtitle is-5">Kommentar</div>
                  <div className="control">
                    <textarea
                      className="textarea"
                      placeholder="Kommentar"
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <ReCAPTCHA
                      sitekey="6Leb4ZgmAAAAADdOBp0UzHFsOENE77LboGfYkyG1"
                      onChange={handleCaptchaVerification}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <button
                      className="button is-primary"
                      onClick={handlePostComment}
                    >
                      Posta Kommentar
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className="field">
              <div className="control">
                <button className="button" onClick={toggleFormVisibility}>
                  {showForm ? "Dölj formulär" : "Kommentera"}
                </button>
              </div>
            </div>
          </div>
        )}
        {!currentUser && (
          <div className="box" style={{ marginTop: "10px" }}>
            <div className="subtitle is-5" style={{ margin: "20px" }}>
              För att kommentera måste du vara inloggad. Klicka nedan för att
              logga in:
            </div>
            <div className="field">
              <div className="login-button">
                <Link to="/login" className="button is-primary">
                  Logga in
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="columns">
        <div className="column is-full">
          {comments
            .filter((comment) => !comment.parentCommentId)
            .sort((a, b) => {
              const dateA = a.createdAt ? a.createdAt.toDate() : new Date(0);
              const dateB = b.createdAt ? b.createdAt.toDate() : new Date(0);
              return dateB - dateA;
            })
            .map((comment) => (
              <div
                key={comment.id}
                className="box"
                style={{ backgroundColor: "#f4f4f4", marginBottom: "10px" }}
              >
                <div className="comment-mail-and-name">
                  <div>
                    <img src={comment.commentImg} alt="user" />
                  </div>
                  <div className="subtitle is-5">
                    <a href={`mailto:${comment.commentEmail}`}>
                      {comment.commentName}
                    </a>
                  </div>
                </div>
                <div className="comment-text">
                  <div className="comment-comment">
                    <div
                      className="subtitle is-5"
                      style={{
                        backgroundColor: "white",

                        borderRadius: "10px",
                        border: "1px solid #ccc",
                        padding: "5px 20px 5px 20px",
                      }}
                    >
                      {comment.comment}
                    </div>
                  </div>
                </div>
                <div className="subtitle is-5">
                  <span class="icon-text">
                    <span class="icon">
                      <img src={cal} alt="cal" width={30} />
                    </span>
                    <span>
                      {comment.createdAt &&
                        format(comment.createdAt.toDate(), "yyyy-MM-dd")}
                    </span>
                  </span>
                </div>
                <hr style={{ backgroundColor: "#1684ac" }} />{" "}
                <div className="replay">
                  {currentUser && currentUser.isAdmin && (
                    <Link
                      className="delete-comment-button"
                      onClick={() =>
                        handleDeleteComment(comment.id, comment.authorUid)
                      }
                    >
                      <img src={trash} alt="trash" width={30} />
                    </Link>
                  )}

                  {currentUser && (
                    <Link
                      className="reply-comment-button"
                      onClick={() =>
                        handleReplyComment(comment.id, comment.authorUid)
                      }
                    >
                      <img src={replay} alt="replay" width={30} />
                    </Link>
                  )}
                </div>
                {replyCommentId === comment.id && (
                  <div
                    className="reply-box"
                    style={{
                      backgroundColor: "#fff",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <div className="field">
                      <div className="control">
                        {currentUser ? (
                          <input
                            className="input"
                            type="hidden"
                            placeholder="Ditt namn"
                            value={replyCommentName || currentUser.authorName}
                            onChange={(e) =>
                              setReplyCommentName(e.target.value)
                            }
                            disabled
                          />
                        ) : (
                          <input
                            className="input"
                            type="hidden"
                            placeholder="Ditt namn"
                            value={replyCommentName}
                            onChange={(e) =>
                              setReplyCommentName(e.target.value)
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        {currentUser ? (
                          <input
                            className="input"
                            type="hidden"
                            placeholder="Epost"
                            value={replyCommentEmail || currentUser.email}
                            onChange={(e) =>
                              setReplyCommentName(e.target.value)
                            }
                            disabled
                          />
                        ) : (
                          <input
                            className="input"
                            type="hidden"
                            placeholder="E-post"
                            value={replyCommentEmail}
                            onChange={(e) =>
                              setReplyCommentEmail(e.target.value)
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Kommentar</label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="Kommentar"
                          value={replyCommentText}
                          onChange={(e) => setReplyCommentText(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="field">
                      <div className="g-recaptcha">
                        <ReCAPTCHA
                          sitekey="6Leb4ZgmAAAAADdOBp0UzHFsOENE77LboGfYkyG1"
                          onChange={handleCaptchaVerification}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <button
                          className="button is-primary"
                          onClick={handlePostReply}
                        >
                          Svara
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {comments
                  .filter((reply) => reply.parentCommentId === comment.id)
                  .map((reply) => (
                    <div
                      key={reply.id}
                      className="box"
                      style={{
                        backgroundColor: "#fff",
                        marginBottom: "10px",
                        marginLeft: "40px",
                      }}
                    >
                      <div className="comment-mail-and-name">
                        <div className="subtitle is-5">
                          <img src={reply.commentImg} alt="user" />
                        </div>
                        <div className="subtitle is-5">
                          <a href={`mailto:${reply.commentEmail}`}>
                            {reply.commentName}
                          </a>
                        </div>
                      </div>
                      <div className="comment-text">
                        <div className="comment-comment">
                          <div
                            className="subtitle is-5"
                            style={{
                              backgroundColor: "#f4f4f4",

                              borderRadius: "10px",
                              border: "1px solid #ccc",
                              padding: "5px 20px 5px 20px",
                            }}
                          >
                            {reply.comment}
                          </div>
                        </div>
                      </div>
                      <div className="subtitle is-5">
                        <span class="icon-text">
                          <span class="icon">
                            <img src={cal} alt="user" width={30} />
                          </span>
                          <span>
                            {reply.createdAt &&
                              format(reply.createdAt.toDate(), "yyyy-MM-dd")}
                          </span>
                        </span>
                      </div>
                      <hr style={{ backgroundColor: "#1684ac" }} />{" "}
                      <div className="replay">
                        {currentUser &&
                          (currentUser.isAdmin ||
                            currentUser.uid === reply.authorUid) && (
                            <Link
                              className="delete-comment-button"
                              onClick={() => handleDeleteComment(reply.id)}
                            >
                              <img src={trash} alt="trash" width={30} />
                            </Link>
                          )}
                      </div>
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export default ProductView;
