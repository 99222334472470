import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import twitter from "../../assets/social/twitter.png";
import mastodon from "../../assets/social/mastodon.png";
import reddit from "../../assets/social/reddit.png";
import trash from "../../assets/social/trash.png";
import replay from "../../assets/social/replay.png";
import cal from "../../assets/social/cal.png";
import user from "../../assets/social/user.png";
import come from "../../assets/social/comments.png";
import forward from "../../assets/social/forward.png";
import category from "../../assets/social/category.png";

import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { format } from "date-fns";

import fb from "../../components/script/firebase";

const db = fb.firestore();
const Blogs = db.collection("blogs");
const Comments = db.collection("comments");

const BlogView = () => {
  const { id } = useParams();

  const [blog, setBlog] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [commentName, setCommentName] = useState("");
  const [commentEmail, setCommentEmail] = useState("");
  const [commentImg, setCommentImg] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [replyCommentId, setReplyCommentId] = useState("");
  const [replyCommentName, setReplyCommentName] = useState("");
  const [replyCommentEmail, setReplyCommentEmail] = useState("");
  const [repalyCommentImg, setReplayCommentImg] = useState("");
  const [replyCommentText, setReplyCommentText] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = fb.auth().currentUser;
      if (user) {
        const userDoc = await db.collection("users").doc(user.uid).get();
        const userData = userDoc.data();
        if (userData && userData.role === "Admin") {
          user.isAdmin = true;
        }
        user.authorName = userData && userData.authorName;
        user.FileUpload = userData && userData.FileUpload;
      }
      setCurrentUser(user);
    };

    fetchCurrentUser();
    // ...
  }, [id]);

  useEffect(() => {
    Blogs.doc(id)
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        setBlog(data);
        document.title = data.Title;
        if (data.Category && data.Category.length > 0) {
          getRelatedBlogs(data.Category);
        } else {
          console.log("No categories found for this blog.");
        }
      });

    getCommentsForPost(id);
  }, [id]);
  const getRelatedBlogs = (categories) => {
    // Här kan du använda kategorilistan för att hämta relaterade inlägg från databasen
    // Till exempel genom att använda Firestore-frågor eller anropa en API

    // Exempel: Hämta inlägg med samma kategori
    Blogs.where("Category", "array-contains-any", categories)
      .limit(3) // Du kan justera antalet relaterade inlägg som visas
      .get()
      .then((querySnapshot) => {
        const relatedBlogs = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return { id: doc.id, ...data };
        });
        setRelatedBlogs(relatedBlogs);
      })
      .catch((error) => {
        console.error("Error getting related blogs: ", error);
      });
  };
  const getCommentsForPost = (blogId) => {
    Comments.where("postId", "==", blogId)
      .get()
      .then((querySnapshot) => {
        const comments = querySnapshot.docs.map(async (doc) => {
          const commentData = doc.data();
          const authorUid = commentData.authorUid;
          const authorSnapshot = await db
            .collection("users")
            .doc(authorUid)
            .get();
          const authorData = authorSnapshot.data();
          const commentWithAuthor = {
            id: doc.id,
            ...commentData,
            authorProfileImageUrl: authorData ? authorData.profileImageUrl : "",
          };
          return commentWithAuthor;
        });
        Promise.all(comments).then((resolvedComments) => {
          const sortedComments = resolvedComments.sort((a, b) => {
            const dateA = a.createdAt ? a.createdAt.toDate() : new Date(0);
            const dateB = b.createdAt ? b.createdAt.toDate() : new Date(0);
            return dateB - dateA;
          });
          setComments(sortedComments);
        });
      })
      .catch((error) => {
        console.error("Error getting comments: ", error);
      });
  };
  const handleShareMastodon = () => {
    const shareText = encodeURIComponent(blog.Title);
    const desText = encodeURIComponent(blog.Description);
    const shareUrl = encodeURIComponent(window.location.href);

    const shareMastodonUrl = `https://mastodon.social/share?text=$${shareText}%0A${desText}&url=${shareUrl}`;
    window.open(shareMastodonUrl, "_blank");
  };
  const handleShareReddit = () => {
    const shareText = encodeURIComponent(blog.Title);
    const desText = encodeURIComponent(blog.Description);
    const shareUrl = encodeURIComponent(window.location.href);

    const shareRedditUrl = `https://www.reddit.com/r/share?text=$${shareText}%0A${desText}&url=${shareUrl}`;
    window.open(shareRedditUrl, "_blank");
  };
  const handleShareTwitter = () => {
    const shareText = encodeURIComponent(blog.Title);
    const desText = encodeURIComponent(blog.Description);
    const shareUrl = encodeURIComponent(window.location.href);
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareText}%0A${desText}&url=${shareUrl}`;
    window.open(twitterShareUrl, "_blank");
  };

  const handlePostComment = () => {
    if (isCaptchaVerified) {
      if (currentUser) {
        const commentData = {
          postId: id,
          comment: commentText,
          commentName: currentUser.authorName,
          commentEmail: currentUser.email,
          commentImg: currentUser.FileUpload,
          createdAt: fb.firestore.Timestamp.fromDate(new Date()),
          authorUid: currentUser.uid,
        };

        Comments.add(commentData)
          .then(() => {
            setCommentText("");
            getCommentsForPost(id);
          })
          .catch((error) => {
            console.error("Error posting comment: ", error);
          });
      } else {
        toast.info("Du måste logga in för att kunna kommentera.");
      }
    } else {
      toast.info("Vänligen verifiera dig själv med reCAPTCHA.");
    }
  };

  const handleCaptchaVerification = (response) => {
    setIsCaptchaVerified(true);
  };

  const handleDeleteComment = (commentId, authorUid) => {
    if (currentUser && (currentUser.uid === authorUid || currentUser.isAdmin)) {
      Comments.doc(commentId)
        .delete()
        .then(() => {
          getCommentsForPost(id);
          toast.info("Kommentar är bortagen!  ");
        })
        .catch((error) => {
          console.error("Error deleting comment: ", error);
        });
    } else {
      console.log("User is not authorized to delete this comment.");
    }
  };

  const handleReplyComment = (commentId, commentAuthorUid) => {
    setReplyCommentId(commentId);
    setReplyCommentName("");
    setReplyCommentEmail("");
    setReplyCommentText("");
    setReplayCommentImg("");
  };

  const handlePostReply = () => {
    if (isCaptchaVerified) {
      const parentComment = comments.find(
        (comment) => comment.id === replyCommentId
      );
      const parentCommentAuthorUid = parentComment
        ? parentComment.authorUid
        : "";

      const replyData = {
        postId: id,
        comment: replyCommentText,
        commentName: currentUser.authorName,
        commentEmail: currentUser.email,
        authorUid: currentUser.uid,
        commentImg: currentUser.FileUpload,
        createdAt: fb.firestore.Timestamp.fromDate(new Date()),
        parentCommentId: replyCommentId,
        parentCommentAuthorUid: parentCommentAuthorUid,
      };

      Comments.add(replyData)
        .then(() => {
          setReplyCommentId("");
          setReplyCommentName("");
          setReplyCommentEmail("");
          setReplyCommentText("");
          setReplayCommentImg("");
          getCommentsForPost(id);
        })
        .catch((error) => {
          console.error("Error posting reply: ", error);
        });
    } else {
      toast.info("Du måste verifiera dig med reCAPTCHA.");
    }
  };
  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };
  return (
    <>
      <Helmet>
        <title>{blog.Title}</title>
        <meta name="description" content={blog.Description} />
        <meta property="og:title" content={blog.Title} />
        <meta property="og:description" content={blog.Description} />
        <meta name="keywords" content={blog.meta} />
        <meta property="og:keywords" content={blog.meta} />
      </Helmet>
      <div className="box">
        <div>
          <Link to="/article" className="subtitle is-5">
            <img src={forward} alt="back" style={{ width: "30px" }} />
          </Link>
        </div>

        <div
          className="title"
          style={{ textAlign: "center", fontSize: "40px" }}
        >
          {blog.Title}
        </div>
        <div className="columns">
          <div className="column is-half">
            <img
              src={blog.File}
              className="img-front-full-post"
              alt="front"
              style={{
                borderRadius: "10px",
                borderStyle: "solid",
                border: "1px solid #ccc",
                padding: "5px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4%",
              }}
            />

            <div className="subtitle is-6">
              <div className="cat-category" style={{ marginTop: "10px" }}>
                <div>
                  <img src={category} alt="category" />
                </div>
                <div className="category">
                  <div className="tag-container-front">
                    {blog.Category && Array.isArray(blog.Category) ? (
                      blog.Category.map((category, index) => (
                        <div
                          key={index}
                          style={{
                            marginRight: "5px",
                            marginBottom: "10px",
                            border: "1px solid #ccc",
                            backgroundColor: "white",
                            color: "black",
                            width: "100px",
                            textAlign: "center",
                            borderRadius: "10px",
                            padding: "5px",
                          }}
                        >
                          {category}
                        </div>
                      ))
                    ) : (
                      <div className="tag is-alert is-normal">
                        Inga kategorier tillagda
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="social" style={{ marginTop: "40px" }}>
              <div onClick={handleShareTwitter}>
                <img src={twitter} alt="twitter" className="socialIcons" />
              </div>
              <div onClick={handleShareMastodon}>
                <img src={mastodon} alt="mastodon" className="socialIcons" />
              </div>
              <div onClick={handleShareReddit}>
                <img src={reddit} alt="reddit" className="socialIcons" />
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div
              className="subtitle is-5"
              dangerouslySetInnerHTML={{
                __html: blog.Body,
              }}
            />
          </div>
        </div>
        <div className="body-post">
          <div className="post-top-all">
            <div className="subtitle is-5">
              <span class="icon-text">
                <span class="icon">
                  <img src={user} alt="user" width={25} />
                </span>
                <span>
                  {" "}
                  <strong>{blog.authorName}</strong>
                </span>
              </span>
            </div>

            <div className="subtitle is-5">
              <span class="icon-text">
                <span class="icon">
                  <img src={cal} alt="cal" width={25} />
                </span>
                <span>
                  <strong>
                    {blog.createdAt &&
                      format(blog.createdAt.toDate(), "yyyy-MM-dd")}
                  </strong>
                </span>
              </span>
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="subtitle is-5">
          <Link to="/article" className="subtitle is-5">
            <img src={forward} alt="back" style={{ width: "30px" }} />
          </Link>
        </div>
        <div className="related-blogs">
          <h3 className="related-blogs-title">Relaterade inlägg</h3>
          {relatedBlogs.map((relatedBlog) => (
            <div key={relatedBlog.id} className="related-blog">
              <Link
                to={`/show/${relatedBlog.id}`}
                className="related-blog-link"
              >
                <img
                  src={relatedBlog.File}
                  alt={relatedBlog.Title}
                  className="related-blog-image"
                />
                <div className="related-blog-info">
                  <div className="related-blog-title">{relatedBlog.Title}</div>
                  <div className="related-blog-category">
                    Kategori: {relatedBlog.Category.join(", ")}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="box">
        <div
          className="headComment"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <img src={come} alt="comments" width={35} />
          </div>
          <div className="title">
            {comments.length === 1
              ? "1 kommentar"
              : `${comments.length} kommentarer`}
          </div>
        </div>

        {currentUser && (
          <div>
            {showForm && (
              <>
                <div className="field">
                  <div>
                    {currentUser ? (
                      <input
                        className="input"
                        type="hidden"
                        placeholder="Ditt namn"
                        value={replyCommentName || currentUser.authorName}
                        onChange={(e) => setReplyCommentName(e.target.value)}
                        disabled
                      />
                    ) : (
                      <input
                        className="input"
                        type="hidden"
                        placeholder="Ditt namn"
                        value={replyCommentName}
                        onChange={(e) => setReplyCommentName(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    {currentUser ? (
                      <input
                        className="input"
                        type="hidden"
                        placeholder="Epost"
                        value={replyCommentEmail || currentUser.email}
                        onChange={(e) => setReplyCommentName(e.target.value)}
                        disabled
                      />
                    ) : (
                      <input
                        className="input"
                        type="hidden"
                        placeholder="E-post"
                        value={replyCommentEmail}
                        onChange={(e) => setReplyCommentEmail(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="field">
                  <div className="subtitle is-5">Kommentar</div>
                  <div className="control">
                    <textarea
                      className="textarea"
                      placeholder="Kommentar"
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <ReCAPTCHA
                      sitekey="6Leb4ZgmAAAAADdOBp0UzHFsOENE77LboGfYkyG1"
                      onChange={handleCaptchaVerification}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <button
                      className="button is-primary"
                      onClick={handlePostComment}
                    >
                      Posta Kommentar
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className="field">
              <div className="control">
                <button className="button" onClick={toggleFormVisibility}>
                  {showForm ? "Dölj formulär" : "Kommentera"}
                </button>
              </div>
            </div>
          </div>
        )}
        {!currentUser && (
          <div className="box" style={{ marginTop: "10px" }}>
            <div className="subtitle is-5" style={{ margin: "20px" }}>
              För att kommentera måste du vara inloggad. Klicka nedan för att
              logga in:
            </div>
            <div className="field">
              <div className="login-button">
                <Link to="/login" className="button is-primary">
                  Logga in
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="columns">
        <div className="column is-full">
          {comments
            .filter((comment) => !comment.parentCommentId)
            .sort((a, b) => {
              const dateA = a.createdAt ? a.createdAt.toDate() : new Date(0);
              const dateB = b.createdAt ? b.createdAt.toDate() : new Date(0);
              return dateB - dateA;
            })
            .map((comment) => (
              <div
                key={comment.id}
                className="box"
                style={{ backgroundColor: "#f4f4f4", marginBottom: "10px" }}
              >
                <div className="comment-mail-and-name">
                  <div>
                    <img src={comment.commentImg} alt="user" />
                  </div>
                  <div className="subtitle is-5">
                    <a href={`mailto:${comment.commentEmail}`}>
                      {comment.commentName}
                    </a>
                  </div>
                </div>
                <div className="comment-text">
                  <div className="comment-comment">
                    <div
                      className="subtitle is-5"
                      style={{
                        backgroundColor: "white",

                        borderRadius: "10px",
                        border: "1px solid #ccc",
                        padding: "5px 20px 5px 20px",
                      }}
                    >
                      {comment.comment}
                    </div>
                  </div>
                </div>
                <div className="subtitle is-5">
                  <span class="icon-text">
                    <span class="icon">
                      <img src={cal} alt="cal" width={30} />
                    </span>
                    <span>
                      {comment.createdAt &&
                        format(comment.createdAt.toDate(), "yyyy-MM-dd")}
                    </span>
                  </span>
                </div>
                <hr style={{ backgroundColor: "#1684ac" }} />{" "}
                <div className="replay">
                  {currentUser && currentUser.isAdmin && (
                    <Link
                      className="delete-comment-button"
                      onClick={() =>
                        handleDeleteComment(comment.id, comment.authorUid)
                      }
                    >
                      <img src={trash} alt="trash" width={30} />
                    </Link>
                  )}

                  {currentUser && (
                    <Link
                      className="reply-comment-button"
                      onClick={() =>
                        handleReplyComment(comment.id, comment.authorUid)
                      }
                    >
                      <img src={replay} alt="replay" width={30} />
                    </Link>
                  )}
                </div>
                {replyCommentId === comment.id && (
                  <div
                    className="reply-box"
                    style={{
                      backgroundColor: "#fff",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <div className="field">
                      <div className="control">
                        {currentUser ? (
                          <input
                            className="input"
                            type="hidden"
                            placeholder="Ditt namn"
                            value={replyCommentName || currentUser.authorName}
                            onChange={(e) =>
                              setReplyCommentName(e.target.value)
                            }
                            disabled
                          />
                        ) : (
                          <input
                            className="input"
                            type="hidden"
                            placeholder="Ditt namn"
                            value={replyCommentName}
                            onChange={(e) =>
                              setReplyCommentName(e.target.value)
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        {currentUser ? (
                          <input
                            className="input"
                            type="hidden"
                            placeholder="Epost"
                            value={replyCommentEmail || currentUser.email}
                            onChange={(e) =>
                              setReplyCommentName(e.target.value)
                            }
                            disabled
                          />
                        ) : (
                          <input
                            className="input"
                            type="hidden"
                            placeholder="E-post"
                            value={replyCommentEmail}
                            onChange={(e) =>
                              setReplyCommentEmail(e.target.value)
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Kommentar</label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="Kommentar"
                          value={replyCommentText}
                          onChange={(e) => setReplyCommentText(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="field">
                      <div className="g-recaptcha">
                        <ReCAPTCHA
                          sitekey="6Leb4ZgmAAAAADdOBp0UzHFsOENE77LboGfYkyG1"
                          onChange={handleCaptchaVerification}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <button
                          className="button is-primary"
                          onClick={handlePostReply}
                        >
                          Svara
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {comments
                  .filter((reply) => reply.parentCommentId === comment.id)
                  .map((reply) => (
                    <div
                      key={reply.id}
                      className="box"
                      style={{
                        backgroundColor: "#fff",
                        marginBottom: "10px",
                        marginLeft: "40px",
                      }}
                    >
                      <div className="comment-mail-and-name">
                        <div className="subtitle is-5">
                          <img src={reply.commentImg} alt="user" />
                        </div>
                        <div className="subtitle is-5">
                          <a href={`mailto:${reply.commentEmail}`}>
                            {reply.commentName}
                          </a>
                        </div>
                      </div>
                      <div className="comment-text">
                        <div className="comment-comment">
                          <div
                            className="subtitle is-5"
                            style={{
                              backgroundColor: "#f4f4f4",

                              borderRadius: "10px",
                              border: "1px solid #ccc",
                              padding: "5px 20px 5px 20px",
                            }}
                          >
                            {reply.comment}
                          </div>
                        </div>
                      </div>
                      <div className="subtitle is-5">
                        <span class="icon-text">
                          <span class="icon">
                            <img src={cal} alt="user" width={30} />
                          </span>
                          <span>
                            {reply.createdAt &&
                              format(reply.createdAt.toDate(), "yyyy-MM-dd")}
                          </span>
                        </span>
                      </div>
                      <hr style={{ backgroundColor: "#1684ac" }} />{" "}
                      <div className="replay">
                        {currentUser &&
                          (currentUser.isAdmin ||
                            currentUser.uid === reply.authorUid) && (
                            <Link
                              className="delete-comment-button"
                              onClick={() => handleDeleteComment(reply.id)}
                            >
                              <img src={trash} alt="trash" width={30} />
                            </Link>
                          )}
                      </div>
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export default BlogView;
