import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import arrowUp from "../../assets/social/sortupp.png";
import arrowDown from "../../assets/social/sortner.png";
import searchIcon from "../../assets/social/sok.png";
import back from "../../assets/social/back.png";
import forward from "../../assets/social/forward.png";
import close from "../../assets/social/close.png";
import rss from "../../assets/social/rss.png";
import cat from "../../assets/social/category.png";
import money from "../../assets/social/money.png";
import { CartContext } from "./CartContext";
import categoryData from "../../components/script/categoryProduct.json";
import { fragment } from "xmlbuilder2";
import fb from "../../components/script/firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Loader/loader";

const db = fb.firestore();
const Product = db.collection("products");

const IndexProducts = () => {
  const [ProductList, setProductList] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { addToCart } = useContext(CartContext);
  const [numberOfProducts, setNumberOfProducts] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  const handleSearchClose = () => {
    setShowSearch(false);
    setSearchQuery("");
  };

  const toggleSortOrder = () => {
    setSearchQuery(""); // Återställ söksträngen
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
  };

  const filterBlog = (product) => {
    return product.filter(
      (product) =>
        product.Title.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (selectedCategory === "" ||
          (product.Category &&
            selectedCategory
              .toLowerCase()
              .split(",")
              .map((category) => category.trim())
              .some((category) =>
                product.Category.map((productCategory) =>
                  productCategory.toLowerCase()
                ).includes(category)
              )))
    );
  };

  useEffect(() => {
    const unsubscribe = Product.orderBy("createdAt", sortOrder)
      .limit(100)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        const filteredData = filterBlog(data); // Filtrera inläggen baserat på söksträng och vald kategori
        setProductList(filteredData);

        const numberOfProducts = querySnapshot.size;
        setNumberOfProducts(numberOfProducts);
        setLoading(false);
      });

    // Detach listener
    return unsubscribe;
  }, [sortOrder, searchQuery, selectedCategory]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const articlePerPage = 8;
  const totalPages = Math.ceil(ProductList.length / articlePerPage);
  const startIndex = (currentPage - 1) * articlePerPage;
  const endIndex = startIndex + articlePerPage;
  const paginatedArticle = ProductList.slice(startIndex, endIndex);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const generateRssXml = () => {
    // Create an XML fragment using xmlbuilder2
    const rssFeed = fragment({
      rss: {
        _attributes: { version: "2.0" },
        channel: {
          title: "DevBlog produkt flöde",
          link: "https://www.devblog.se/products",
          description: "De senaste Recenionerna",
          language: "sv-se",
          item: ProductList.map((blog) => ({
            title: blog.Title,
            link: `https://www.devblog.se/products/show/${blog.id}`,
            description: { _cdata: blog.Body.substring(0, 1000) },
            category: blog.Category.join(","),
          })),
        },
      },
    });

    // Serialize the XML fragment to a string
    const rssXml = rssFeed.end({ prettyPrint: true });

    // Create a link and click on it to download the RSS file
    const blob = new Blob([rssXml], { type: "application/rss+xml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "rss_product_feed.xml";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const handleAddToCartClick = (product) => {
    addToCart({
      id: product.id,
      title: product.Title,
      price: parseInt(product.myPrice),
      totalPriceWithMoms: product.PrisMedMoms,
      file: product.File,
      type: product.Type,
      totalPrice: parseInt(product.myPrice),
      quantity: 1,
    });
    const toastMessage = `Produkten "${product.Title}" har lagts till i varukorgen!`;
    toast.success(toastMessage);
  };
  return (
      <>
      {loading ? (
            // Visa loader om loading är true
           <Loader/>
        ) : (
      <div className="box">
        <p />
        <h2 className="title">Alla Produkter</h2>

        <div className="right-aligned">
          <p className="review-count">Antal produkter: {numberOfProducts}</p>
        </div>

        <div className="search-container">
          <img src={rss} alt="rss" onClick={generateRssXml} width={30} />
          <img
            src={sortOrder === "asc" ? arrowUp : arrowDown}
            alt="Sort Order"
            width={30}
            onClick={toggleSortOrder}
          />
          {showSearch ? (
            <>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                className="input is-small"
                style={{
                  width: "430px",
                  borderRadius: "10px",
                  marginLeft: "10px",
                }}
                placeholder="Sök Produkt."
              />
              <img
                src={close}
                alt="Close"
                className="close-icon"
                onClick={handleSearchClose}
                width={25}
              />
            </>
          ) : (
            <img
              onClick={handleSearchIconClick}
              src={searchIcon}
              alt="Search"
              width={30}
            />
          )}
        </div>

        <div className="filter-container">
          <label htmlFor="category">Kategori:</label>
          <div className="select is-small">
            <select
              id="category"
              value={selectedCategory}
              onChange={handleCategoryChange}
              className="select"
              style={{
                width: "300px",
                borderRadius: "10px",
                marginLeft: "10px",
              }}
            >
              <option value="">Alla kategorier</option>
              {categoryData.category.map((category) => (
                <optgroup key={category.name} label={category.name}>
                  {category.subcategories.map((subcategory) => (
                    <option key={subcategory} value={subcategory}>
                      {subcategory}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>
        </div>

        <div className="product-container">
          {paginatedArticle.map((product) => (
            <div className="product" key={product.id}>
              <div className="title">{product.Title}</div>
              <Link to={`/products/show/${product.id}`}>
                {product.File && (
                  <img
                    style={{
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      border: "1px solid #ccc",

                      padding: "5px",
                    }}
                    src={product.File}
                    alt="Thumbnail"
                  />
                )}
              </Link>
              <div className="subtitle is-6" style={{ marginTop: "10px" }}>
                <div style={{ display: "flex" }}>
                  <strong>
                    <img src={cat} alt="category" style={{}} />
                  </strong>{" "}
                  {product.Category && Array.isArray(product.Category) ? (
                    product.Category.map((category, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: "10px",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          marginTop: "4px",
                          marginLeft: "10px",
                          width: "50%",
                          textAlign: "center",
                          borderRadius: "10px",
                          padding: "5px",
                        }}
                      >
                        {category}
                      </div>
                    ))
                  ) : (
                    <div className="tag is-alert is-small">
                      Inga kategorier tillagda
                    </div>
                  )}
                </div>
              </div>

              {/* Price Container */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: "right" }}>
                  <img src={money} alt="money" />{" "}
                  <strong>{product.PrisMedMoms} kr</strong>
                </div>
              </div>
              {product.Type && product.Type.includes("webdesign") ? (
                <div className="control" style={{ textAlign: "right" }}>
                  <Link
                    style={{
                      backgroundColor: "#f4f4f4",
                      color: "black",
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      padding: "5px 20px 5px 20px",
                    }}
                    onClick={() => handleAddToCartClick(product)}
                  >
                    <strong>Lägg i varukorg</strong>
                  </Link>
                </div>
              ) : (
                <div className="control" style={{ textAlign: "right" }}>
                  {product.Type && product.Type.includes("clothes") ? (
                    <p>
                      <strong>Finns i flera storlekar</strong>
                    </p>
                  ) : (
                    <>
                      {product.GadgetsStock > 0 ? (
                        <Link
                          style={{
                            backgroundColor: "#f4f4f4",
                            color: "black",
                            borderRadius: "10px",
                            border: "1px solid #ccc",
                            padding: "5px 20px 5px 20px",
                          }}
                          onClick={() => handleAddToCartClick(product)}
                        >
                          <strong>Lägg i varukorg</strong>
                        </Link>
                      ) : (
                        <p>
                          <strong>Slut i lager</strong>
                        </p>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="pagination">
          <button
            className="button is-white"
            onClick={previousPage}
            style={{ backgroundColor: "#f4f4f4" }}
            disabled={currentPage === 1}
          >
            <img src={forward} alt="next" style={{ width: "30px" }} />
          </button>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (page) => (
              <button
                key={page}
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  width: "25px",
                  height: "25px",
                }}
                className={`button is-small ${
                  page === currentPage ? "button is-info is-small" : ""
                }`}
                onClick={() => goToPage(page)}
                disabled={page === currentPage}
              >
                {page}
              </button>
            )
          )}
          <button
            className="button is-white"
            style={{ backgroundColor: "#f4f4f4" }}
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            <img src={back} alt="next" style={{ width: "30px" }} />
          </button>
        </div>
      </div>
        )}
</>
);
};

export default IndexProducts;
