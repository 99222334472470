import React, { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { storage } from "../script/firebase";
import undo from "../../assets/social/undo.png";
import upload from "../../assets/social/upload.png";

const FileUpload = ({ handleFileUpload }) => {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState(null);

  const onFileUpload = () => {
    if (!file) return;
    setIsLoading(true);
    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            setUrl(downloadURL);
            handleFileUpload(downloadURL); // Pass the download URL to the parent component
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    );
  };

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setUrl(URL.createObjectURL(selectedFile));
    }
  };

  const onDeleteImage = () => {
    if (file) {
      setFile(null);
      setUrl(null);
    }
  };

  return (
    <>
      <input type="file" onChange={onFileChange} className="button" />
      {file && <img src={undo} alt="undo" onClick={onDeleteImage} />}
      <img src={upload} alt="upload" onClick={onFileUpload} />

      <div className="space"></div>

      {isLoading && (
        <p>
          Din bild laddas{" "}
          <b>
            <progress value={progress}>{progress}%</progress>
          </b>
        </p>
      )}
      {url && (
        <>
          <div>
            <a href={url} target="_blank" rel="noreferrer">
              <img src={url} alt="img" width={350}></img>
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default FileUpload;
