import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import arrowUp from "../../assets/social/sortupp.png";
import arrowDown from "../../assets/social/sortner.png";
import searchIcon from "../../assets/social/sok.png";
import back from "../../assets/social/back.png";
import forward from "../../assets/social/forward.png";
import close from "../../assets/social/close.png";
import rss from "../../assets/social/rss.png";
import distro from "../../assets/social/distro.png";
import desktop from "../../assets/social/desktop.png";
import fb from "../../components/script/firebase";
import ReactRatingStarsComponent from "react-rating-stars-component";
import distroData from "../../components/script/distros.json";
import desktopData from "../../components/script/desktop.json";
import { fragment } from "xmlbuilder2";
import Loader from "../../components/Loader/loader";

const db = fb.firestore();
const Review = db.collection("reviews");

const IndexReview = () => {
  const [ReviewList, setReviewList] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDesktopCategory, setSelectedDesktopCategory] = useState("");
  const [numberOfReviews, setNumberOfReviews] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  const handleSearchClose = () => {
    setShowSearch(false);
    setSearchQuery("");
  };

  const toggleSortOrder = () => {
    setSearchQuery(""); // Reset search query
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
  };

  const filterReviews = (reviews) => {
    return reviews.filter((review) => {
      const matchesCategory =
        selectedCategory === "" || review.Category.includes(selectedCategory);
      const matchesDesktopCategory =
        selectedDesktopCategory === "" ||
        review.DesktopCategory.includes(selectedDesktopCategory);

      return (
        review.Title.toLowerCase().includes(searchQuery.toLowerCase()) &&
        matchesCategory &&
        matchesDesktopCategory
      );
    });
  };

  useEffect(() => {
    const unsubscribe = Review.orderBy("createdAt", sortOrder).onSnapshot(
      (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        const filteredData = filterReviews(data);
        setReviewList(filteredData);

        // Hämta antalet recensioner
        const numberOfReviews = querySnapshot.size;
        setNumberOfReviews(numberOfReviews);
        setLoading(false);
      }
    );

    // Detach listener
    return unsubscribe;
  }, [sortOrder, searchQuery, selectedCategory, selectedDesktopCategory]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleDesktopCategoryChange = (e) => {
    setSelectedDesktopCategory(e.target.value);
  };

  const reviewsPerPage = 10;
  const totalPages = Math.ceil(ReviewList.length / reviewsPerPage);
  const startIndex = (currentPage - 1) * reviewsPerPage;
  const endIndex = startIndex + reviewsPerPage;
  const paginatedReviews = ReviewList.slice(startIndex, endIndex);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const generateRssXml = () => {
    // Create an XML fragment using xmlbuilder2
    const rssFeed = fragment({
      rss: {
        _attributes: { version: "2.0" },
        channel: {
          title: "DevBlog Recensions flöde",
          link: "https://www.devblog.se/review",
          description: "De senaste Recenionerna",
          language: "sv-se",
          item: ReviewList.map((blog) => ({
            title: blog.Title,
            link: `https://www.devblog.se/reviews/show/${blog.id}`,
            description: { _cdata: blog.Body.substring(0, 1000) },
            category: blog.Category.join(","),
          })),
        },
      },
    });

    // Serialize the XML fragment to a string
    const rssXml = rssFeed.end({ prettyPrint: true });

    // Create a link and click on it to download the RSS file
    const blob = new Blob([rssXml], { type: "application/rss+xml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "rss_review_feed.xml";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  return (
      <>
      {loading ? (
            // Visa loader om loading är true
            <Loader/>
        ) : (
      <div className="box">
        <p />
        <h2 className="title">Alla Recensioner</h2>
        <div className="right-aligned">
          <p className="review-count">Antal recensioner: {numberOfReviews}</p>
        </div>
        <div className="search-container">
          <img src={rss} alt="rss" onClick={generateRssXml} width={30} />
          <img
            src={sortOrder === "asc" ? arrowUp : arrowDown}
            alt="Sort Order"
            width={30}
            onClick={toggleSortOrder}
          />
          {showSearch ? (
            <>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                style={{
                  width: "410px",
                  borderRadius: "10px",
                  marginLeft: "10px",
                }}
                className="input is-small"
                placeholder="Sök Recension."
              />
              <img
                src={close}
                alt="Close"
                className="close-icon"
                onClick={handleSearchClose}
                width={25}
              />
            </>
          ) : (
            <img
              onClick={handleSearchIconClick}
              src={searchIcon}
              alt="Search"
              width={30}
            />
          )}
        </div>

        <div className="filter-container">
          <label htmlFor="category" className="subtitle is-5">
            Distros:
          </label>
          <div className="select is-small">
            <select
              id="category"
              value={selectedCategory}
              onChange={handleCategoryChange}
              className="select"
              style={{
                width: "300px",
                borderRadius: "10px",
                marginLeft: "10px",
              }}
            >
              <option value="">Alla Distros</option>
              {distroData.distros.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <label htmlFor="desktopCategory" className="subtitle is-5">
            Skrivbord:
          </label>
          <div className="select is-small">
            <select
              id="desktopCategory"
              value={selectedDesktopCategory}
              onChange={handleDesktopCategoryChange}
              className="select"
              style={{
                width: "300px",
                borderRadius: "10px",
                marginLeft: "10px",
              }}
            >
              <option value="">Alla skrivbord</option>
              {desktopData.desktops.map((desktop) => (
                <option key={desktop} value={desktop}>
                  {desktop}
                </option>
              ))}
            </select>
          </div>
        </div>

        {paginatedReviews.map((review) => (
          <div key={review.id} className="columns">
            <div className="image-container">
              <div className="image-container">
                {review.File && (
                  <img
                    src={review.File}
                    alt="Thumbnail"
                    className="image"
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      padding: "5px",
                    }}
                  />
                )}
              </div>
            </div>

            <div className="column">
              <div
                className="title"
                style={{ textAlign: "center", fontSize: "40px" }}
              >
                {review.Title}
              </div>

              <div
                className="subtitle is-5"
                dangerouslySetInnerHTML={{
                  __html: review.Body.substring(0, review.Body.substring(0, 500).lastIndexOf('.') + 1),
                }}
              />
              <div className="subtitle is-5">
                <div style={{ display: "flex" }}>
                  <strong>
                    <img
                      src={distro}
                      alt="distro"
                      style={{ marginTop: "-4px" }}
                    />
                  </strong>{" "}
                  {review.Category && Array.isArray(review.Category) ? (
                    review.Category.map((category, index) => (
                      <div
                        key={index}
                        className="label"
                        style={{
                          flex: "1 1 calc(33.33% - 10px)", // Delar ut 1/3 av bredden på raden och tar hänsyn till gap
                          maxWidth: "calc(33.33% - 10px)", // Maximal bredd för varje element
                          marginBottom: "10px",
                          border: "1px solid #ccc",
                          textAlign: "center",
                          borderRadius: "10px",
                          padding: "5px",
                        }}
                      >
                        {category}
                      </div>
                    ))
                  ) : (
                    <div className="tag is-alert is-medium">
                      Inga kategorier tillagda
                    </div>
                  )}
                </div>
              </div>
              <div className="subtitle is-6">
                <div style={{ display: "flex" }}>
                  <strong>
                    <img
                      src={desktop}
                      alt="desktop"
                      style={{ marginTop: "-4px" }}
                    />
                  </strong>
                  <div className="tag-container">
                    {review.DesktopCategory &&
                    Array.isArray(review.DesktopCategory) ? (
                      review.DesktopCategory.map((desktopCategory, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            border: "1px solid #ccc",
                            backgroundColor: "white",
                            marginRight: "10px",
                            textAlign: "center",
                            borderRadius: "10px",
                            padding: "5px",
                          }}
                        >
                          {desktopCategory}
                        </div>
                      ))
                    ) : (
                      <div className="tag is-alert is-normal">
                        Inga kategorier tillagda
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="rating-container">
                <ReactRatingStarsComponent
                  value={review.Rating} // Replace with the actual rating value
                  size={24}
                  edit={false}
                  color1="#f0f0f0"
                  color2="#ffd700"
                />
              </div>
              <Link
                to={`/reviews/show/${review.id}`}
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  padding: "5px 20px 5px 20px",
                }}
              >
                <strong> Läs hela</strong>
              </Link>
            </div>
          </div>
        ))}

        <div className="pagination">
          <button
            className="button is-white"
            onClick={previousPage}
            style={{ backgroundColor: "#f4f4f4" }}
            disabled={currentPage === 1}
          >
            <img src={forward} alt="next" style={{ width: "30px" }} />
          </button>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (page) => (
              <button
                key={page}
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  width: "25px",
                  height: "25px",
                }}
                className={`button is-small ${
                  page === currentPage ? "button is-info is-small" : ""
                }`}
                onClick={() => goToPage(page)}
                disabled={page === currentPage}
              >
                {page}
              </button>
            )
          )}
          <button
            className="button is-white"
            style={{ backgroundColor: "#f4f4f4" }}
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            <img src={back} alt="next" style={{ width: "30px" }} />
          </button>
        </div>
      </div>
      )}
      </>
  );
};

export default IndexReview;
