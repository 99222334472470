import React from "react";
import "../components/css/messenger.css"; // Skapa en CSS-fil för stilen

function Message() {
  return (
    <>
      <div className="message-container">
        <strong>
          <h1>Butiken är inte igång!</h1>
        </strong>
        <h2>Köp som sker genom den kommer att annuleras!</h2>
      </div>
    </>
  );
}

export default Message;
