import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../components/upload/upload";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";
import lank from "../../assets/social/link.png";
import seo from "../../assets/social/seo.png";
import forward from "../../assets/social/forward.png";
import grade from "../../assets/social/grade.png";
import "react-quill/dist/quill.snow.css";
import fb from "../../components/script/firebase";
import ReactRatingStarsComponent from "react-rating-stars-component";
import distrosData from "../../components/script/distros.json";
import desktopData from "../../components/script/desktop.json";

const db = fb.firestore();
const Review = db.collection("reviews");
const usersCollection = db.collection("users");

const ReviewEdit = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [download, setDownload] = useState("");
  const [link, setLink] = useState("");
  const [desktopCategory, setDesktopCategory] = useState("");
  const [availableDesktopCategories, setAvailableDesktopCategories] = useState(
    []
  );
  const [body, setBody] = useState("");
  const [category, setCategory] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [meta, setMeta] = useState("");
  const [description, setDescription] = useState("");
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setAvailableCategories(distrosData.distros);
    setAvailableDesktopCategories(desktopData.desktops);
  }, []);
  useEffect(() => {
    const currentUser = fb.auth().currentUser;
    if (currentUser) {
      usersCollection
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setAuthorName(doc.data().authorName);
          }
        })
        .catch((error) => {
          console.error("Error fetching author name:", error);
        });
    }
  }, []);
  const handleDownloadChange = (e) => {
    setDownload(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const handleMetaChange = (e) => {
    setMeta(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleCategoryChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setCategory(selectedOptions);

    if (!availableCategories.includes(category)) {
      setAvailableCategories((prevCategories) => [...prevCategories, category]);
    }
  };

  useEffect(() => {
    Review.doc(id)
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        setTitle(data.Title);
        setBody(data.Body);
        setCategory(data.Category);
        setFileUrl(data.File);
        setDownload(data.Download);
        setLink(data.Link);
        setDescription(data.Description);
        setMeta(data.meta);
        setRating(data.rating);
      });
  }, [id]);

  const submit = (e) => {
    e.preventDefault();

    const updatedData = {
      Title: title,
      Category: category,
      Body: body,
      File: fileUrl,
      Download: download,
      Link: link,
      DesktopCategory: desktopCategory,
      Description: description,
      meta: {
        value: meta,
      },
      Rating: rating,
    };

    Review.doc(id)
      .update(updatedData)
      .then(() => {
        toast.success("Recensionen är nu uppdaterad!");
        navigate("/reviews");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDesktopCategoryChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setDesktopCategory(selectedOptions);

    if (!availableDesktopCategories.includes(category)) {
      setAvailableDesktopCategories((prevCategories) => [
        ...prevCategories,
        category,
      ]);
    }
  };
  const handleFileUpload = (uploadedFileUrl) => {
    setFileUrl(uploadedFileUrl);
  };

  const handleEditorChange = (value) => {
    setBody(value);
  };
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  const deleteImage = () => {
    setFileUrl("");
  };
  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half">
          <Link to="/reviews" className="subtitle is-5">
            <img src={forward} alt="back" style={{ width: "30px" }} />
          </Link>
          <label className="label">Ladda upp en bild</label>
          <p />

          {fileUrl && (
            <div>
              <img
                src={fileUrl}
                alt="Current"
                style={{ marginBottom: "10px" }}
              />
              <button
                className="button is-danger is-small"
                onClick={deleteImage}
              >
                Ta bort bild
              </button>
            </div>
          )}
          <FileUpload handleFileUpload={handleFileUpload} />
        </div>
        <div className="column is-half">
          <label className="label">Titel på Recensionen</label>
          <input
            type="text"
            value={title}
            className="input"
            placeholder="Title"
            onChange={(e) => setTitle(e.target.value)}
            required
            style={{ width: "650px" }}
          />
          <label className="label">Inlägg</label>
          <ReactQuill
            value={body}
            onChange={handleEditorChange}
            placeholder="Skriv din text här"
          />
          <div className="container-post-ka">
            <label className="label" style={{ marginLeft: "10%" }}>
              <div style={{ marginRight: "10px" }}>Välj Kategorier</div>
            </label>
            <div className="select is-multiple">
              <select multiple value={category} onChange={handleCategoryChange}>
                {availableCategories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="container-post-ka">
            <label className="label" style={{ marginLeft: "10%" }}>
              <div style={{ marginRight: "10px" }}>Välj Skrivbord</div>
            </label>
            <div className="select is-multiple">
              <select
                multiple
                value={desktopCategory}
                onChange={handleDesktopCategoryChange}
              >
                {availableDesktopCategories.map((catDesk) => (
                  <option key={catDesk} value={catDesk}>
                    {catDesk}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="container-post">
            <label className="label ">
              {" "}
              <img src={seo} alt="SEO" />
            </label>
            <div>
              <label className="label">Meta tags</label>
              <input
                type="text"
                className="input"
                value={meta.value}
                placeholder="Meta Value"
                onChange={handleMetaChange}
                required
              />
            </div>
            <div>
              <label className="label">Kort beskrivning</label>
              <input
                type="text"
                className="input"
                value={description}
                placeholder=" Kort beskriving"
                onChange={handleDescriptionChange}
                required
              />
            </div>
          </div>
          <div className="container-post">
            <label className="label ">
              {" "}
              <img src={lank} alt="Link" />
            </label>
            <div>
              <label className="label">Länk för nerladdning</label>
              <input
                type="text"
                className="input"
                value={download}
                placeholder="https://"
                onChange={handleDownloadChange}
                required
              />
            </div>
            <div>
              <label className="label">Distro länk</label>
              <input
                type="text"
                className="input"
                value={link}
                placeholder="https://"
                onChange={handleLinkChange}
                required
              />
            </div>
          </div>
          <div className="container-post">
            <label>
              {" "}
              <img src={grade} alt="grade" />
            </label>
            <div>
              <label className="label">Betyg</label>

              <ReactRatingStarsComponent
                count={5}
                value={rating}
                onChange={handleRatingChange}
                size={24}
                activeColor="#ffd700"
              />
            </div>
          </div>
          <form onSubmit={submit}>
            <button className="button is-primary" type="submit">
              Posta Recension
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReviewEdit;
