import React, { useEffect, useState } from "react";
import { fb } from "../../components/script/firebase"; // Importera din firebase-konfiguration
import "../../components/css/order-confirmation.css"; // Importera din CSS för bekräftelsesidan
import complete from "../../assets/complete.png";
import Loader from "../../components/Loader/loader";
const OrderOverview = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status

  useEffect(() => {
    const ordersRef = fb.collection("orders");
    ordersRef
      .orderBy("timestamp", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        const latestOrder = querySnapshot.docs[0];
        if (latestOrder) {
          setOrders([{ id: latestOrder.id, ...latestOrder.data() }]);
        }
        setLoading(false); // Set loading to false when data is fetched
      });
  }, []);

  return (
    <div className="box">
      <div className="order-confirmation">
        <h2 className="confirmation-title">Tack för din beställning!</h2>
        {loading ? (
          <Loader />
        ) : (
          <>
            <p className="confirmation-message">
              <img src={complete} alt="complete" />
              <br></br>
              Din beställning har skickats in och vi arbetar på att behandla
              den. Här är en överblick över din beställning:
            </p>
            <ul className="confirmation-list">
              {orders.map((order) => (
                <li key={order.id} className="order-item">
                  <div className="subtitle">Beställningsnummer: {order.id}</div>
                  <ul className="product-list">
                    {order.products.map((product, index) => (
                      <li key={index} className="product-item">
                        <div className="product-info">
                          <span className="product-title">{product.title}</span>
                          <span className="product-quantity">
                            {product.selectedSize && (
                              <span className="product-quantity">
                                Antal: {product.quantity} (
                                {product.selectedSize})
                              </span>
                            )}
                          </span>
                        </div>
                        <span className="product-price">
                          Pris: {product.totalPriceWithMoms} kr
                        </span>
                      </li>
                    ))}
                  </ul>
                  <div className="order-details">
                    <div
                      className="subtitle is-
                5"
                      style={{ textAlign: "center" }}
                    >
                      {order.name} <br />
                      {order.address}
                      <br />
                      {order.postnummer} {order.ort}
                    </div>

                    <div className="subtitle">E-post: {order.email}</div>
                  </div>
                  <div className="order-total">
                    Totalt pris: {order.totalPrice} kr
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderOverview;
