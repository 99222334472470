import firebase from "firebase/compat/app";

import "firebase/compat/firestore";
import "firebase/compat/auth";

import { getStorage } from "firebase/storage";

firebase.initializeApp({
  apiKey: "AIzaSyAzPdyo2ym-BjSP-OkR6t0SysLjtFepRLk",
  authDomain: "blogg-a2a12.firebaseapp.com",
  projectId: "blogg-a2a12",
  storageBucket: "blogg-a2a12.appspot.com",
  messagingSenderId: "542277916093",
  appId: "1:542277916093:web:4e9ed389a93e34921eaed4",
  databaseURL:
    "https://blogg-a2a12-default-rtdb.europe-west1.firebasedatabase.app/",
});

const auth = firebase.auth();
const fb = firebase.firestore();
const storage = getStorage();

export { auth, fb, storage };
export default firebase;
