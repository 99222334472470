import React, { useContext, useState } from "react";
import { CartContext } from "./CartContext";
import CartView from "./CartView";
import close from "../../assets/social/close.png";
import "../../components/css/modal.css";

const CartModal = ({ onClose }) => {
  const cartContext = useContext(CartContext);
  const { cartItems } = cartContext;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          <img className="closebutton" src={close} alt="close" />
        </span>
        <CartView cartItems={cartItems} onClose={onClose} />
        {/* You can add other components and elements inside the modal as needed */}
      </div>
    </div>
  );
};

export default CartModal;
