import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import arrowUp from "../../assets/social/sortupp.png";
import arrowDown from "../../assets/social/sortner.png";
import searchIcon from "../../assets/social/sok.png";
import back from "../../assets/social/back.png";
import forward from "../../assets/social/forward.png";
import close from "../../assets/social/close.png";
import rss from "../../assets/social/rss.png";
import category from "../../assets/social/category.png";
import { Helmet } from "react-helmet";
import categoryData from "../../components/script/category.json";
import { fragment } from "xmlbuilder2";
import fb from "../../components/script/firebase";
import Loader from "../../components/Loader/loader";
const db = fb.firestore();
const Blogs = db.collection("blogs");

const IndexBlogs = () => {
  const [BlogList, setBlogList] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfArt, setNumberOfArt] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  const handleSearchClose = () => {
    setShowSearch(false);
    setSearchQuery("");
  };

  const toggleSortOrder = () => {
    setSearchQuery(""); // Återställ söksträngen
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
  };

  const filterBlog = (blogs) => {
    return blogs.filter(
      (blog) =>
        blog.Title.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (selectedCategory === "" ||
          (blog.Category &&
            selectedCategory
              .toLowerCase()
              .split(",")
              .map((category) => category.trim())
              .some((category) =>
                blog.Category.map((blogCategory) =>
                  blogCategory.toLowerCase()
                ).includes(category)
              )))
    );
  };

  useEffect(() => {
    const unsubscribe = Blogs.orderBy("createdAt", sortOrder)
      .limit(100)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        const filteredData = filterBlog(data); // Filtrera inläggen baserat på söksträng och vald kategori
        setBlogList(filteredData);
        const numberOfArt = querySnapshot.size;
        setNumberOfArt(numberOfArt);
        setLoading(false);
      });

    // Detach listener
    return unsubscribe;
  }, [sortOrder, searchQuery, selectedCategory]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const articlePerPage = 10;
  const totalPages = Math.ceil(BlogList.length / articlePerPage);
  const startIndex = (currentPage - 1) * articlePerPage;
  const endIndex = startIndex + articlePerPage;
  const paginatedArticle = BlogList.slice(startIndex, endIndex);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const generateRssXml = () => {
    // Create an XML fragment using xmlbuilder2
    const rssFeed = fragment({
      rss: {
        _attributes: { version: "2.0" },
        channel: {
          title: "DevBlog Artikel flöde",
          link: "https://www.devblog.se/article",
          description: "De senaste Recenionerna",
          language: "sv-se",
          item: BlogList.map((blog) => ({
            title: blog.Title,
            link: `https://www.devblog.se/show/${blog.id}`,
            description: { _cdata: blog.Body.substring(0, 1000) },
            category: blog.Category.join(","),
          })),
        },
      },
    });

    // Serialize the XML fragment to a string
    const rssXml = rssFeed.end({ prettyPrint: true });

    // Create a link and click on it to download the RSS file
    const blob = new Blob([rssXml], { type: "application/rss+xml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "rss_article_feed.xml";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  return (
    <>
      {loading ? (
        // Visa loader om loading är true
        <Loader />
      ) : (
        <div className="box">
          <p />
          <h2 className="title">Alla Artiklar</h2>
          <div className="right-aligned">
            <p className="review-count">Antal artiklar: {numberOfArt}</p>
          </div>
          <div className="search-container">
            <img src={rss} alt="rss" onClick={generateRssXml} width={30} />
            <img
              src={sortOrder === "asc" ? arrowUp : arrowDown}
              alt="Sort Order"
              width={30}
              onClick={toggleSortOrder}
            />
            {showSearch ? (
              <>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  className="input is-small"
                  style={{
                    width: "440px",
                    borderRadius: "10px",
                    marginLeft: "10px",
                  }}
                  placeholder="Sök Artikel."
                />
                <img
                  src={close}
                  alt="Close"
                  className="close-icon"
                  onClick={handleSearchClose}
                  width={25}
                />
              </>
            ) : (
              <img
                onClick={handleSearchIconClick}
                src={searchIcon}
                alt="Search"
                width={30}
              />
            )}
          </div>

          <div className="filter-container">
            <label htmlFor="category">Kategori:</label>
            <div className="select is-small">
              <select
                id="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="select"
                style={{
                  width: "300px",
                  borderRadius: "10px",
                  marginLeft: "10px",
                }}
              >
                <option value="">Alla kategorier</option>
                {categoryData.category.map((category) => (
                  <optgroup key={category.name} label={category.name}>
                    {category.subcategories.map((subcategory) => (
                      <option key={subcategory} value={subcategory}>
                        {subcategory}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </div>
          </div>

          {paginatedArticle.map((blog) => (
            <div key={blog.id} className="columns">
              <div
                className="column is-two-fifths"
                style={{
                  borderRadius: "8px",
                  marginTop: "50px",
                }}
              >
                {blog.File && (
                  <img
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      padding: "5px",
                    }}
                    src={blog.File}
                    alt="Thumbnail"
                  />
                )}
              </div>
              <Helmet>
                <title>Artiklar - DevBlog</title>
              </Helmet>
              <div className="column">
                <div
                  className="title"
                  style={{ textAlign: "center", fontSize: "30px" }}
                >
                  {blog.Title}
                </div>
                <div
                  className="subtitle is-5"
                  dangerouslySetInnerHTML={{
                    __html: blog.Body.substring(
                      0,
                      blog.Body.substring(0, 500).lastIndexOf(".") + 1
                    ),
                  }}
                />
                <div className="subtitle is-6">
                  <div style={{ display: "flex" }}>
                    <strong>
                      <img
                        src={category}
                        alt="category"
                        style={{ marginTop: "-4px" }}
                      />
                    </strong>{" "}
                    <div className="tag-container-front">
                      {blog.Category && Array.isArray(blog.Category) ? (
                        blog.Category.map((category, index) => (
                          <div
                            key={index}
                            style={{
                              flex: "1 2 calc(5.33% - 10px)", // Delar ut 1/3 av bredden på raden och tar hänsyn till gap
                              maxWidth: "calc(100.33% - 10px)", // Maximal bredd för varje element
                              marginBottom: "10px",
                              border: "1px solid #ccc",
                              backgroundColor: "white",
                              marginRight: "10px",
                              textAlign: "center",
                              borderRadius: "10px",
                              padding: "5px",
                            }}
                          >
                            {category}
                          </div>
                        ))
                      ) : (
                        <div className="tag is-alert is-medium">
                          Inga kategorier tillagda
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Link
                  to={`/show/${blog.id}`}
                  style={{
                    backgroundColor: "#f4f4f4",
                    color: "black",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    padding: "5px 20px 5px 20px",
                  }}
                >
                  <strong>Läs hela</strong>
                </Link>
              </div>
            </div>
          ))}
          <div className="pagination">
            <button
              className="button is-white"
              onClick={previousPage}
              style={{ backgroundColor: "#f4f4f4" }}
              disabled={currentPage === 1}
            >
              <img src={forward} alt="next" style={{ width: "30px" }} />
            </button>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (page) => (
                <button
                  key={page}
                  style={{
                    backgroundColor: "#f4f4f4",
                    color: "black",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    width: "25px",
                    height: "25px",
                  }}
                  className={`button is-small ${
                    page === currentPage ? "button is-info is-small" : ""
                  }`}
                  onClick={() => goToPage(page)}
                  disabled={page === currentPage}
                >
                  {page}
                </button>
              )
            )}
            <button
              className="button is-white"
              style={{ backgroundColor: "#f4f4f4" }}
              onClick={nextPage}
              disabled={currentPage === totalPages}
            >
              <img src={back} alt="next" style={{ width: "30px" }} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default IndexBlogs;
