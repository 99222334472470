import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import edit from "../../assets/social/edit.png";
import trash from "../../assets/social/trash.png";
import arrowUp from "../../assets/social/sortupp.png";
import arrowDown from "../../assets/social/sortner.png";
import searchIcon from "../../assets/social/sok.png";
import skapa from "../../assets/social/skapa.png";
import close from "../../assets/social/close.png";
import back from "../../assets/social/back.png";
import forward from "../../assets/social/forward.png";

import { toast } from "react-toastify";

import fb from "../../components/script/firebase";
const db = fb.firestore();
const Products = db.collection("products");

const AllProducts = () => {
  const [ProductList, setProductList] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const deleteBlog = (id) => {
    Products.doc(id)
      .delete()
      .then(() => {
        toast.info("Du har tagit bort produkt!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };
  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  const handleSearchClose = () => {
    setShowSearch(false);
    setSearchQuery("");
  };

  const toggleSortOrder = () => {
    setSearchQuery(""); // Återställ söksträngen
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
  };

  const filterProducts = (product) => {
    return product.filter((product) =>
      product.Title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  useEffect(() => {
    const unsubscribe = Products.orderBy("createdAt", sortOrder)
      .limit(100)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        const filteredData = filterProducts(data); // Filtrera inläggen baserat på söksträngen
        setProductList(filteredData);
      });

    // Detach listener
    return unsubscribe;
  }, [sortOrder, searchQuery]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const reviewsPerPage = 10;
  const totalPages = Math.ceil(ProductList.length / reviewsPerPage);
  const startIndex = (currentPage - 1) * reviewsPerPage;
  const endIndex = startIndex + reviewsPerPage;
  const paginatedReviews = ProductList.slice(startIndex, endIndex);
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <>
      <div className="box">
        <p />
        <h2 className="title">Alla Produkter</h2>
        <div className="search-container">
          <img
            src={sortOrder === "asc" ? arrowUp : arrowDown}
            alt="Sort Order"
            width={30}
            onClick={toggleSortOrder}
          />
          {showSearch ? (
            <>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                className="input is-small "
                style={{ width: "650px" }}
                placeholder="Sök Artikel..."
              />
              <img
                src={close}
                alt="Close"
                className="close-icon"
                onClick={handleSearchClose}
                width={25}
              />
            </>
          ) : (
            <img
              onClick={handleSearchIconClick}
              src={searchIcon}
              alt="Search"
              width={30}
            />
          )}

          <Link
            to="/products/create"
            style={{ position: "absolute", right: "0" }}
          >
            <img src={skapa} alt="skapa" width={30} />
          </Link>
        </div>{" "}
        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th className="subtitle is-5">
                  <strong>Produkt</strong>
                </th>

                <th className="subtitle is-5">Kategori</th>
                <th className="subtitle is-5">Artnummer</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedReviews.map((product) => (
                <tr key={product.id}>
                  <td className="mobile-column">{product.Title}</td>
                  <td className="mobile-column">{product.Category}</td>
                  <td className="mobile-column">{product.ArtNummer}</td>

                  <td>
                    <Link to={`/products/edit/${product.id}`}>
                      <img src={edit} alt="trash" width={30} />
                    </Link>
                  </td>
                  <td>
                    <Link
                      onClick={() => {
                        deleteBlog(product.id);
                      }}
                    >
                      <img src={trash} alt="trash" width={30} />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button
            className="button is-white"
            onClick={previousPage}
            style={{ backgroundColor: "#f4f4f4" }}
            disabled={currentPage === 1}
          >
            <img src={forward} alt="next" style={{ width: "30px" }} />
          </button>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (page) => (
              <button
                key={page}
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  width: "25px",
                  height: "25px",
                }}
                className={`button is-small ${
                  page === currentPage ? "button is-info is-small" : ""
                }`}
                onClick={() => goToPage(page)}
                disabled={page === currentPage}
              >
                {page}
              </button>
            )
          )}
          <button
            className="button is-white"
            style={{ backgroundColor: "#f4f4f4" }}
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            <img src={back} alt="next" style={{ width: "30px" }} />
          </button>
        </div>
      </div>
    </>
  );
};

export default AllProducts;
