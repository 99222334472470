import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import fb from "../components/script/firebase";
import gitlabpage from "../assets/social/gitlabpage.png";
import github from "../assets/social/github.png";
import www from "../assets/social/www.png";
import mail from "../assets/social/mail.png";
import bitbucket from "../assets/social/bitbucket.png";
import forward from "../assets/social/forward.png";

const db = fb.firestore();
const Users = db.collection("users");

const UserProfile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDoc = await Users.doc(userId).get();
        if (userDoc.exists) {
          setUser(userDoc.data());
        } else {
          console.log("Användaren hittades inte.");
        }
      } catch (error) {
        console.error("Fel vid hämtning av användarinformation:", error);
      }
    };

    fetchUser();
  }, [userId]);

  if (!user) {
    return <p>Laddar användarinformation...</p>;
  }
  const getRoleImage = (role) => {
    if (role === "Admin") {
      return "../assets/admin.png";
    } else if (role === "Drone") {
      return "../assets/drone.png";
    } else {
      return "../assets/default.png";
    }
  };

  const roleImage = getRoleImage(user.userRole);
  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half">
          <h2 className="title">Användare</h2>
          <div className="profil-img">
            <img
              className="profil-fulpage"
              src={user.FileUpload}
              alt="profil"
            />
          </div>
        </div>
        <div className="column is-half">
          <div
            className="card custom-card"
            style={{
              backgroundColor: "#f4f4f4",
              color: "black",
              padding: "4%",
            }}
          >
            <div className="profil">
              <h3 className="title">{user.authorName}</h3>
              {/* Rollvisning */}
              <img
                src={roleImage}
                alt="roll"
                width="40px"
                className="account"
              />{" "}
            </div>
            <div className="space-profil">
              <div className="box">
                <div className="subtitle is-5">
                  <div className="profil-info">
                    <a href={`mailto:${user.email}`}>
                      <img src={mail} alt={user.email} />
                    </a>
                    {user.link && (
                      <a href={user.link}>
                        <img src={www} alt={user.linkTitle} />
                      </a>
                    )}
                    {user.git && (
                      <a href={user.git}>
                        <img src={github} alt={user.gitTitle} />
                      </a>
                    )}
                    {user.lab && (
                      <a href={user.lab}>
                        <img src={gitlabpage} alt={user.labTitle} />
                      </a>
                    )}
                    {user.bucket && (
                      <a href={user.bucket}>
                        <img src={bitbucket} alt={user.bucketTitle} />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <h3 className="subtitle is-5">
                <p /> {user.info}
              </h3>
              <Link to="/users">
                <img src={forward} alt="tillbaka" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
