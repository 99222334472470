import React, { useState, useEffect } from "react";
import ClearIcon from "../../assets/weater/sun.png"; // Adjust the import paths
import RainIcon from "../../assets/weater/rain.png";
import CloudsIcon from "../../assets/weater/cloud.png";
import SnowIcon from "../../assets/weater/snow.png";
import ThunderstormIcon from "../../assets/weater/thunderstorm.png";
import ScatterCloudIcon from "../../assets/weater/ScatterdCloud.png";
import FogIcon from "../../assets/weater/fog.png";
import FewCloudsIcon from "../../assets/weater/fewCloud.png";

import ShowerRainIcon from "../../assets/weater/showerRain.png";
import BrokenCloudsIcon from "../../assets/weater/brokenCloud.png";
import "../../components/css/vadret.css";
const translateWeatherDescription = (description) => {
  const lowerCaseDescription = description.toLowerCase();

  if (lowerCaseDescription.includes("klar")) {
    return "Klart";
  } else if (lowerCaseDescription.includes("regn")) {
    return "Regn";
  } else if (lowerCaseDescription.includes("moln")) {
    if (lowerCaseDescription.includes("spridda")) {
      return "Spridda moln";
    } else if (lowerCaseDescription.includes("några")) {
      return "Några moln";
    } else if (lowerCaseDescription.includes("brutna")) {
      return "Brutna moln";
    } else {
      return "Molnigt";
    }
  } else if (lowerCaseDescription.includes("snö")) {
    return "Snö";
  } else if (lowerCaseDescription.includes("åska")) {
    return "Åska";
  } else if (lowerCaseDescription.includes("dimma")) {
    return "Dimma";
  } else if (lowerCaseDescription.includes("duggregn")) {
    return "Duggregn";
  } else {
    return description; // Använd originalbeskrivningen om ingen översättning hittades
  }
};

const Weather = () => {
  const [weather, setWeather] = useState(null);

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            const response = await fetch(
              `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&lang=sv&appid=6bcc79beef2ce5c6949ecac400f60582`
            );

            if (response.ok) {
              const data = await response.json();
              setWeather(data);
            } else {
              console.error("Error fetching weather:", response.statusText);
            }
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } catch (error) {
        console.error("Error fetching weather:", error);
      }
    };

    fetchWeather();
  }, []);

  let weatherIcon;
  let translatedDescription;
  let temperature;
  let precipitation;

  if (weather) {
    translatedDescription = translateWeatherDescription(
      weather.weather[0].description
    );
    temperature = `${Math.round(weather.main.temp)}°C`;

    // Ta reda på om nederbörd finns
    if (weather.rain) {
      precipitation = `Nederbörd: ${weather.rain["1h"]} mm`;
    } else if (weather.snow) {
      precipitation = `Nederbörd: ${weather.snow["1h"]} mm`;
    } else {
      precipitation = "Ingen nederbörd";
    }

    if (translatedDescription.includes("Klart")) {
      weatherIcon = ClearIcon;
    } else if (translatedDescription.includes("Regn")) {
      weatherIcon = RainIcon;
    } else if (translatedDescription.includes("Moln")) {
      if (translatedDescription.includes("Spridda")) {
        weatherIcon = ScatterCloudIcon;
      } else if (translatedDescription.includes("Några")) {
        weatherIcon = FewCloudsIcon;
      } else if (translatedDescription.includes("Brutna")) {
        weatherIcon = BrokenCloudsIcon;
      } else {
        weatherIcon = CloudsIcon;
      }
    } else if (translatedDescription.includes("Snö")) {
      weatherIcon = SnowIcon;
    } else if (translatedDescription.includes("Åska")) {
      weatherIcon = ThunderstormIcon;
    } else if (translatedDescription.includes("Dimma")) {
      weatherIcon = FogIcon;
    } else if (translatedDescription.includes("Duggregn")) {
      weatherIcon = ShowerRainIcon;
    } else {
      weatherIcon = null; // Ingen matchande ikon
    }
  }

  return (
    <div className="weather-padding">
      <div className="weather-container">
        {weather && (
          <div className="weather-info">
            {weatherIcon && (
              <img
                src={weatherIcon}
                alt="Weather Icon"
                title={translatedDescription}
              />
            )}
            <div className="weather-text">
              <div>{translatedDescription}</div>
              <div>Temperatur: {temperature}</div>
              <div>{precipitation}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Weather;
