import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../components/script/firebase";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [emailError, setEmailError] = useState("");

  const [passwordError, setPasswordError] = useState("");

  const onLogin = (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate("/profil");
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode === "auth/user-not-found") {
          setEmailError("Mailadressen finns inte!.");
        } else if (errorCode === "auth/wrong-password") {
          setPasswordError("Fel lösenord.");
        }

        console.log(errorCode, errorMessage);
      });
  };

  const onForgotPassword = (e) => {
    e.preventDefault();
    setResetEmailSent(false);

    sendPasswordResetEmail(auth, forgotPasswordEmail)
      .then(() => {
        console.log("Password reset email sent successfully!");
        setResetEmailSent(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <div className="box">
      <div className="container has-text-centered mt-4">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="card">
              <div className="card-content">
                <div className="title">Logga in</div>
                <form>
                  {/* Email field */}
                  <div className="field">
                    <label className="label" htmlFor="email-address">
                      E-Post
                    </label>
                    <div className="control">
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        className="input"
                        style={{ width: "300px" }}
                        required
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {emailError && (
                      <p className="help is-danger">{emailError}</p>
                    )}
                  </div>

                  {/* Password field */}
                  <div className="field">
                    <label className="label" htmlFor="password">
                      Lösenord
                    </label>
                    <div className="control">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        className="input"
                        style={{ width: "300px" }}
                        required
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    {passwordError && (
                      <p className="help is-danger">{passwordError}</p>
                    )}
                  </div>

                  {/* Login button */}
                  <div className="field">
                    <div className="control">
                      <button className="button is-primary" onClick={onLogin}>
                        Logga in
                      </button>
                    </div>
                    <div className="control">
                      <Link to="/signup" className="button is-text">
                        Inget konto, registrera ett!
                      </Link>
                    </div>
                    <div className="control"></div>
                  </div>
                </form>

                {/* Forgot password */}
                <div className="field">
                  <label className="label" htmlFor="forgot-password-email">
                    Glömt lösenord?
                  </label>
                  <div className="control">
                    <input
                      id="forgot-password-email"
                      name="forgotPasswordEmail"
                      type="email"
                      className="input"
                      style={{ width: "300px" }}
                      required
                      placeholder="Ange din e-postadress"
                      onChange={(e) => setForgotPasswordEmail(e.target.value)}
                    />
                  </div>
                  {resetEmailSent && (
                    <p className="forgot-password-error">
                      Återställningsmejl skickat!
                    </p>
                  )}
                  {resetEmailSent === false && emailError && (
                    <p className="forgot-password-error">{emailError}</p>
                  )}
                </div>
                <div className="field">
                  <div className="control">
                    <button
                      className="button is-warning"
                      onClick={onForgotPassword}
                      disabled={resetEmailSent}
                    >
                      Skicka återställningsmejl
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
