import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import trash from "../../assets/social/trash.png";
import arrowUp from "../../assets/social/sortupp.png";
import arrowDown from "../../assets/social/sortner.png";
import searchIcon from "../../assets/social/sok.png";
import "../../components/css/order.css";
import close from "../../assets/social/close.png";
import back from "../../assets/social/back.png";
import forward from "../../assets/social/forward.png";

import { toast } from "react-toastify";

import fb from "../../components/script/firebase";
const db = fb.firestore();
const orders = db.collection("orders");

const AllOrder = () => {
  const [OrderList, setOrderList] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const deleteBlog = (id) => {
    orders
      .doc(id)
      .delete()
      .then(() => {
        toast.info("Du har tagit bort Ordern!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };
  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  const handleSearchClose = () => {
    setShowSearch(false);
    setSearchQuery("");
  };
  console.log(OrderList);
  const toggleSortOrder = () => {
    setSearchQuery(""); // Återställ söksträngen
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
  };

  const filterProducts = (orders) => {
    return orders.filter((orders) =>
      orders.id.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  useEffect(() => {
    const unsubscribe = orders
      .orderBy("timestamp", sortOrder)
      .limit(100)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log("Data from Firestore:", data); // Lägg till denna rad

        const filteredData = filterProducts(data);
        console.log("Filtered Data:", filteredData); // Lägg till denna rad
        setOrderList(filteredData);
      });

    // Detach listener
    return unsubscribe;
  }, [sortOrder, searchQuery]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const reviewsPerPage = 10;
  const totalPages = Math.ceil(OrderList.length / reviewsPerPage);
  const startIndex = (currentPage - 1) * reviewsPerPage;
  const endIndex = startIndex + reviewsPerPage;
  const paginatedReviews = OrderList.slice(startIndex, endIndex);
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <>
      <div className="box">
        <p />
        <h2 className="title">Alla Ordrar</h2>
        <div className="search-container">
          <img
            src={sortOrder === "asc" ? arrowUp : arrowDown}
            alt="Sort Order"
            width={30}
            onClick={toggleSortOrder}
          />
          {showSearch ? (
            <>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                className="input"
                placeholder="Sök Recension."
              />
              <img
                src={close}
                alt="Close"
                className="close-icon"
                onClick={handleSearchClose}
                width={30}
              />
            </>
          ) : (
            <img
              onClick={handleSearchIconClick}
              src={searchIcon}
              alt="Search"
              width={30}
            />
          )}
        </div>

        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th className="subtitle is-5">
                  <strong>Order</strong>
                </th>
                <th className="subtitle is-5">Namn</th>
                <th className="subtitle is-5">Adress</th>
                <th className="subtitle is-5">Produkter</th>
                <th className="subtitle is-5">Pris</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedReviews.map((order) => (
                <tr key={order.id}>
                  <td>
                    {order.id}
                    <br />
                  </td>
                  <td>
                    {order.name}
                    <br />
                    {order.personNummer}
                    <br />
                  </td>
                  <td>
                    {order.address}
                    <br />
                    {order.postnummer} {order.ort}
                    <br />
                    {order.email}
                  </td>
                  <td>
                    <ul>
                      {order.products.map((product) => (
                        <li key={product.id}>
                          {product.title} ({product.quantity} st){" "}
                          {product.selectedSize && (
                            <span>({product.selectedSize})</span>
                          )}{" "}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>{order.totalPrice} kr</td>
                  <td>
                    <Link
                      onClick={() => {
                        deleteBlog(order.id);
                      }}
                    >
                      <img src={trash} alt="trash" width={30} />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button
            className="button is-white"
            onClick={previousPage}
            style={{ backgroundColor: "#f4f4f4" }}
            disabled={currentPage === 1}
          >
            <img src={forward} alt="next" style={{ width: "30px" }} />
          </button>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (page) => (
              <button
                key={page}
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  width: "25px",
                  height: "25px",
                }}
                className={`button is-small ${
                  page === currentPage ? "button is-info is-small" : ""
                }`}
                onClick={() => goToPage(page)}
                disabled={page === currentPage}
              >
                {page}
              </button>
            )
          )}
          <button
            className="button is-white"
            style={{ backgroundColor: "#f4f4f4" }}
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            <img src={back} alt="next" style={{ width: "30px" }} />
          </button>
        </div>
      </div>
    </>
  );
};

export default AllOrder;
