import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fb from "../../components/script/firebase";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../components/upload/upload";
import { Link } from "react-router-dom";
import seo from "../../assets/social/seo.png";
import forward from "../../assets/social/forward.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import categoriesData from "../../components/script/category.json";

const db = fb.firestore();
const Blogslist = db.collection("blogs");
const usersCollection = db.collection("users");

const CreateBlog = () => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState([]);
  const [body, setBody] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [meta, setMeta] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = fb.auth().currentUser;
    if (currentUser) {
      usersCollection
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setAuthorName(doc.data().authorName);
          }
        })
        .catch((error) => {
          console.error("Error fetching author name:", error);
        });
    }
  }, []);

  const handleMetaChange = (e) => {
    setMeta(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCategoryChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setCategory(selectedOptions);
  };

  const handleEditorChange = (value) => {
    setBody(value);
  };

  const submit = (e) => {
    e.preventDefault();
    const currentUser = fb.auth().currentUser;
    if (currentUser) {
      const blogPost = {
        Title: title,
        Category: category,
        Body: body,
        File: fileUrl,
        createdAt: fb.firestore.Timestamp.fromDate(new Date()),
        createdBy: currentUser.uid,
        authorName: authorName,
        Description: description,
        meta: {
          value: meta,
        },
      };

      Blogslist.add(blogPost)
        .then((docRef) => {
          toast.success("Inlägget är nu sparat!");
          navigate("/dashboard");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log("Ingen användare är inloggad");
    }
  };

  const handleFileUpload = (uploadedFileUrl) => {
    setFileUrl(uploadedFileUrl);
  };

  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half">
          <Link to="/dashboard" className="subtitle is-5">
            <img src={forward} alt="back" style={{ width: "30px" }} />
          </Link>
          <label className="label">Ladda upp en bild</label>
          <p />
          <FileUpload handleFileUpload={handleFileUpload} multiple={true} />
        </div>
        <div className="column is-half">
          <label className="label">Titel på inlägget</label>
          <input
            type="text"
            className="input"
            placeholder="Title"
            style={{ width: "650px" }}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <label className="label">Inlägg</label>
          <ReactQuill
            value={body}
            onChange={handleEditorChange}
            placeholder="Skriv din text här"
          />
          <div className="container-post">
            <label className="label">Välj Kategorier</label>
            <div className="select is-multiple">
              <select multiple value={category} onChange={handleCategoryChange}>
                {categoriesData.category.map((cat) => (
                  <optgroup key={cat.name} label={cat.name}>
                    {cat.subcategories.map((subcat) => (
                      <option key={subcat} value={subcat}>
                        {subcat}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </div>
          </div>
          <div className="container-post">
            <label className="label ">
              <img src={seo} alt="SEO" />
            </label>
            <div>
              <label className="label">Meta tags</label>
              <input
                type="text"
                className="input"
                placeholder="Meta Value"
                onChange={handleMetaChange}
                required
              />
            </div>
            <div>
              <label className="label">Kort beskrivning</label>
              <input
                type="text"
                className="input"
                placeholder=" Kort beskriving"
                onChange={handleDescriptionChange}
                required
              />
            </div>
          </div>
          <form onSubmit={submit}>
            <div style={{ marginTop: "10px", textAlign: "left" }}>
              <button className="button is-primary" type="submit">
                Posta Inlägget
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateBlog;
