import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import edit from "../assets/social/edit.png";
import trash from "../assets/social/trash.png";
import arrowUp from "../assets/social/sortupp.png";
import arrowDown from "../assets/social/sortner.png";
import searchIcon from "../assets/social/sok.png";
import skapa from "../assets/social/skapa.png";
import close from "../assets/social/close.png";
import back from "../assets/social/back.png";
import forward from "../assets/social/forward.png";
import { toast } from "react-toastify";

import fb from "../components/script/firebase";
import "../components/css/article.css";
const db = fb.firestore();
const Blogs = db.collection("blogs");

const Home = () => {
  const [blogsList, setBlogsList] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(false);

  const deleteBlog = (id) => {
    Blogs.doc(id)
      .delete()
      .then(() => {
        toast.info("Du har tagit bort inlägget!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };

  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  const handleSearchClose = () => {
    setShowSearch(false);
    setSearchQuery("");
  };

  const toggleSortOrder = () => {
    setSearchQuery(""); // Återställ söksträngen
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
  };

  const filterBlogs = (blogs) => {
    return blogs.filter((blog) =>
      blog.Title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 767);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Kontrollera storleken när komponenten renderas

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = Blogs.orderBy("createdAt", sortOrder).onSnapshot(
      (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        const filteredData = filterBlogs(data); // Filtrera inläggen baserat på söksträngen

        setBlogsList(filteredData);
      }
    );

    // Detach listener
    return unsubscribe;
  }, [sortOrder, searchQuery]);

  const blogsPerPage = 10;
  const totalPages = Math.ceil(blogsList.length / blogsPerPage);
  const startIndex = (currentPage - 1) * blogsPerPage;
  const endIndex = startIndex + blogsPerPage;
  const paginatedblogs = blogsList.slice(startIndex, endIndex);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className="box">
        <p />
        <h2 className="title">Alla Artiklar</h2>
        <div className="search-container">
          <img
            src={sortOrder === "asc" ? arrowUp : arrowDown}
            alt="Sort Order"
            width={30}
            onClick={toggleSortOrder}
          />
          {showSearch ? (
            <>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                className="input is-small "
                style={{ width: "650px" }}
                placeholder="Sök Artikel..."
              />
              <img
                src={close}
                alt="Close"
                className="close-icon"
                onClick={handleSearchClose}
                width={25}
              />
            </>
          ) : (
            <img
              onClick={handleSearchIconClick}
              src={searchIcon}
              alt="Search"
              width={30}
            />
          )}{" "}
          <Link to="/create" style={{ position: "absolute", right: "0" }}>
            <img src={skapa} alt="skapa" width={30} />
          </Link>
        </div>

        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th className="subtitle is-5">
                  <strong>Title</strong>
                </th>
                <th className="subtitle is-5"></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedblogs.map((blog) => (
                <tr key={blog.id}>
                  <td>{blog.Title}</td>
                  <td></td>
                  <td></td>
                  <td>
                    <Link to={`/edit/${blog.id}`}>
                      <img src={edit} alt="trash" width={30} />
                    </Link>
                  </td>
                  <td>
                    <Link
                      onClick={() => {
                        deleteBlog(blog.id);
                      }}
                    >
                      <img src={trash} alt="trash" width={30} />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <button
            className="button is-white"
            onClick={previousPage}
            style={{ backgroundColor: "#f4f4f4" }}
            disabled={currentPage === 1}
          >
            <img src={forward} alt="next" style={{ width: "30px" }} />
          </button>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (page) => (
              <button
                key={page}
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  width: "25px",
                  height: "25px",
                }}
                className={`button is-small ${
                  page === currentPage ? "button is-info is-small" : ""
                }`}
                onClick={() => goToPage(page)}
                disabled={page === currentPage}
              >
                {page}
              </button>
            )
          )}
          <button
            className="button is-white"
            style={{ backgroundColor: "#f4f4f4" }}
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            <img src={back} alt="next" style={{ width: "30px" }} />
          </button>
        </div>
      </div>
    </>
  );
};

export default Home;
